import {Component, Input, OnInit} from '@angular/core';

export interface AvatarDetails {
  imgUrl: string;
  email: string;
  tel: string;
}

@Component({
  selector: 'sp-lib-avatar-card',
  templateUrl: './avatar-card.component.html',
  styleUrls: ['./avatar-card.component.scss']
})
export class AvatarCardComponent implements OnInit {
  @Input() avatarDetails: AvatarDetails;

  constructor() { }

  ngOnInit() {
  }

}
