<sp-onboard-page-content-wrapper
  [footerConfig]="footerConfig"
  [formId]="form"
  [showStepper]="true"
  [stepperConfig]="{currentStep: stepper, onboardingType: (onboardingType$ | async)}">
<p class="introduction">Hoi {{name}}, waar woon je?</p>

<form [formGroup]="addressForm" #form='ngForm'>
  <div class="streetAndNumber">
    <div>
      <sp-input-wrapper
        [label]="'Straat'"
        [error]="form.submitted ? this.addressForm.get('street').errors : null">
        <input formControlName="street" type="text" >
      </sp-input-wrapper>
    </div>
    <div>
      <sp-input-wrapper
        [label]="'nr.'"
        [error]="form.submitted ? this.addressForm.get('number').errors : null">
        <input formControlName="number" type="text" >
      </sp-input-wrapper>
    </div>
  </div>
  <sp-input-wrapper
    [label]="'Postcode'"
    [error]="form.submitted ? this.addressForm.get('postalCode').errors : null">
    <input formControlName="postalCode" type="text" spTextTransform="toUpperCase">
  </sp-input-wrapper>

  <sp-input-wrapper
    [label]="'Woonplaats'"
    [error]="form.submitted ? this.addressForm.get('city').errors : null">
    <input formControlName="city" type="text">
  </sp-input-wrapper>
</form>
</sp-onboard-page-content-wrapper>
