export enum AccordionThemes {
  WORK_SATISFACTION = "Werktevredenheid",
  PERFORMANCE = "Jouw presteren nu",
  PERFORMANCE_FUTURE = "Jouw presteren in de toekomst",
  LEADERSHIP = "Persoonlijk leiderschap",
  WORK_LIFE_BALANCE = "Werk-privé balans",
  EXPERIENCE = "Weten en kunnen",
  HEALTH = "Gezondheid",
  OBSTACLES = "Obstakels in het werk",
}

export type ZZPTheme = AccordionThemes;

export interface SelfTestGroupScores {
  group:   ZZPTheme;
  average: string;
}


export interface themeInfo {
  group: ZZPTheme,
  badScoreText: string;
  scores?: SelfTestGroupScores
  goodScoreText: string;
}


export const themeRecord: Record<ZZPTheme, themeInfo> = {
  [AccordionThemes.WORK_SATISFACTION]: {
    group: AccordionThemes.WORK_SATISFACTION,
    badScoreText: `<p>Uitdagingen zijn onvermijdelijk in het leven van een zelfstandige. Of je nu onzeker bent over de toekomst, financiële zorgen hebt of moeilijke situaties ervaart in je werk, het is belangrijk om op tijd hulp te zoeken. Zo behoud je je werkplezier en kun je sommige zorgen beter loslaten. Wil je hiermee aan de slag? Bekijk onze pagina over <a href="https://www.sharepeople.nl/gezond-ondernemen-werkplezier/"> werktevredenheid. </a></p>`,
    goodScoreText: `<p>Jij hebt plezier in je werk en hebt een positieve en ondersteunende werkomgeving. Mooi nieuws! Blijf vooral genieten van je werk en blijf streven naar groei en succes.</p>`
  },
  [AccordionThemes.PERFORMANCE]: {
    group: AccordionThemes.PERFORMANCE,
    badScoreText: `<p>
Het lijkt erop dat je moeite hebt om te presteren tijdens het werk, bijvoorbeeld omdat je het mentaal of fysiek lastig afgaat. Of misschien speelt er iets anders waardoor het werk je zwaarder valt. Heb je hier veel last van? Bekijk onze pagina over <a href="https://www.sharepeople.nl/gezond-ondernemen-jouw-presteren/"> Jouw presteren. </a>
</p>`,
    goodScoreText: `<p>Je scoort uitstekend op het gebied van presteren tijdens het werk, waarbij je zelf aangeeft dat je effectief bent in het behalen van je doelen, ondanks eventuele uitdagingen zoals afleiding door persoonlijke zaken of fysieke ongemakken. Blijf zo doorgaan met je indrukwekkende prestaties en blijf aandacht besteden aan je welzijn!</p>`
  },
  [AccordionThemes.PERFORMANCE_FUTURE]: {
    group: AccordionThemes.PERFORMANCE_FUTURE,
    badScoreText: `<p>
Uit je score blijkt dat jouw toekomst als ondernemer wat onzeker is. Het kan verstandig zijn om de mogelijkheden voor bijscholingen te onderzoeken. Zijn er nog dingen die je interesant vindt waar je nu nog niets mee doet? Neem <a href="https://www.sharepeople.nl/gezond-ondernemen/"> hier </a> een kijkje voor inspiratie.
</p>`,
    goodScoreText: `<p>Mooi nieuws! Jouw toekomst op werkvlak ziet er zonnig uit.</p>`
  },
  [AccordionThemes.LEADERSHIP]: {
    group: AccordionThemes.LEADERSHIP,
    badScoreText: `<p>
Persoonlijk leiderschap (o.a. veerkracht op het werk en eigen regie nemen) is cruciaal voor je professionele groei en succes. Het lijkt erop dat je tegen dingen aanloopt. Investeer zeker in je leiderschapsvaardigheden. Daarmee vergroot je je werkplezier én verbeter je je positie als ondernemer. Bekijk onze trainingen en coaching over <a href="https://www.sharepeople.nl/gezond-ondernemen-persoonlijk-leiderschap/"> persoonlijk leiderschap </a> die je hierbij kunnen helpen.
</p>`,
    goodScoreText: `<p>Jij bent goed bezig! Blijf vooral proactief werken aan je inzetbaarheid. Daarmee stimuleer je je professionele groei en succes. En je hebt er positieve impact mee op anderen.</p>`
  },
  [AccordionThemes.WORK_LIFE_BALANCE]: {
    group: AccordionThemes.WORK_LIFE_BALANCE,
    badScoreText: `<p>
Het lijkt erop dat jij moeite hebt om werk en privé in balans te houden. Die balans is wel belangrijk voor je welzijn én voor je prestaties als ondernemer. Maak er werk van om deze balans te verbeteren. Zo zorg je voor een gezondere werkomgeving en betere prestaties. Meer tips en tools? Bekijk onze pagina over de  <a href="https://www.sharepeople.nl/gezond-ondernemen-werk-prive-balans/"> balans tussen werk en privé. </a>
</p>`,
    goodScoreText: `<p>Het blijkt dat je jouw werk en privé zo kan inrichten dat je een fijne balans hebt. Blijf zo doorgaan! Mocht je echter ooit ondersteuning nodig hebben bij het verfijnen ervan, dan staan we altijd voor je klaar.</p>`
  },
  [AccordionThemes.EXPERIENCE]: {
    group: AccordionThemes.EXPERIENCE,
    badScoreText: `<p>
        Altijd blijven leren is als ondernemer extra belangrijk. Jij bent tenslotte je enige werknemer en wilt expertise uitstralen. Maak daarom tijd vrij om, naast je dagelijkse werk, nieuwe dingen te leren. Dat is goed voor je professionele vaardigheden én voor zakelijke groei. Zet nu alvast de stap! In de pagina over <a href="https://www.sharepeople.nl/gezond-ondernemen-weten-en-kunnen/"> weten en kunnen </a> vind je volop tools om nieuwe vaardigheden en kennis op te doen.
    </p>`,
    goodScoreText: `
    <p>
    Dat doe je goed! Jij probeert regelmatig nieuwe vaardigheden te leren. Zo kun je continu groeien en jezelf ontwikkelen. Blijf zeker open staan voor nieuwe kansen. Er blijft altijd wat te leren, zeker in het dynamische vak dat ondernemen heet.
</p>
    `
  },
  [AccordionThemes.HEALTH]: {
    group: AccordionThemes.HEALTH,
    badScoreText: `<p>
Als je regelmatig fysieke klachten hebt, moet je zeker op tijd in actie komen. Hoe je dat doet? Bekijk onze pagina over <a href="https://www.sharepeople.nl/gezond-ondernemen-gezondheid/"> gezondheid </a> om te zien hoe je jouw gezondheid kunt verbeteren. Hier vind je tips voor lichaamsbeweging, stressmanagement, een ergonomische werkplek en meer. Het is nooit te laat voor positieve verandering richting een gezonder en gelukkiger werkleven. Dus waar wacht je nog op?!
</p>`,
    goodScoreText: `<p>
Mooi om te zien dat je zo goed scoort qua gezondheid! Daar worden we bij SharePeople blij van: ondernemers die goed voor zichzelf zorgen en in balans zijn. Blijf vooral doorgaan met je gezonde leefstijl. Maar vergeet niet dat we er ook voor je zijn als je toch hulp of advies nodig hebt.
</p>`
  },
  [AccordionThemes.OBSTACLES]: {
    group: AccordionThemes.OBSTACLES,
    badScoreText: `<p>
Je geeft aan dat er meerdere factoren zijn die jouw werk moeilijk maken. Kijk eens kritisch hoe je jouw situatie kunt verbeteren. Ons advies: laat iemand met je meekijken. Soms zijn er meer opties dan je denkt. Plan gerust een gesprek met onze <a href="https://sharepeople.typeform.com/to/lOoXSSC6#zzp_id=xxxxx&fname=xxxxx&lname=xxxxx&bdate=xxxxx&mail=xxxxx&tel=xxxxx"> Adviseurs Werkvermogen. </a> Zij helpen je graag.
</p>`,
    goodScoreText: `<p>
Je geeft aan dat er weinig tot geen factoren zijn die jouw werk moeilijk maken. Je hebt het goed voor elkaar!
</p>`
  }
}


export const themeTexts = [
  {
    group: AccordionThemes.WORK_SATISFACTION,
    badScoreText: `<p>Uitdagingen zijn onvermijdelijk in het leven van een zelfstandige. Of je nu onzeker bent over de toekomst, financiële zorgen hebt of moeilijke situaties ervaart in je werk, het is belangrijk om op tijd hulp te zoeken. Zo behoud je je werkplezier en kun je sommige zorgen beter loslaten. Wil je hiermee aan de slag? Bekijk onze pagina over <a href="https://www.sharepeople.nl/gezond-ondernemen-werkplezier/"> werktevredenheid. </a></p>`,
    goodScoreText: `<p>Jij hebt plezier in je werk en hebt een positieve en ondersteunende werkomgeving. Mooi nieuws! Blijf vooral genieten van je werk en blijf streven naar groei en succes.</p>`
  },
  {
    group: AccordionThemes.PERFORMANCE,
    badScoreText: `<p>
Het lijkt erop dat je moeite hebt om te presteren tijdens het werk, bijvoorbeeld omdat je het mentaal of fysiek lastig afgaat. Of misschien speelt er iets anders waardoor het werk je zwaarder valt. Heb je hier veel last van? Bekijk onze pagina over <a href="https://www.sharepeople.nl/gezond-ondernemen-jouw-presteren/"> Jouw presteren. </a>
</p>`,
    goodScoreText: `<p>Je scoort uitstekend op het gebied van presteren tijdens het werk, waarbij je zelf aangeeft dat je effectief bent in het behalen van je doelen, ondanks eventuele uitdagingen zoals afleiding door persoonlijke zaken of fysieke ongemakken. Blijf zo doorgaan met je indrukwekkende prestaties en blijf aandacht besteden aan je welzijn!</p>`
  },
  {
    group: AccordionThemes.PERFORMANCE_FUTURE,
    badScoreText: `<p>
Uit je score blijkt dat jouw toekomst als ondernemer wat onzeker is. Het kan verstandig zijn om de mogelijkheden voor bijscholingen te onderzoeken. Zijn er nog dingen die je interesant vindt waar je nu nog niets mee doet? Neem <a href="https://www.sharepeople.nl/gezond-ondernemen/"> hier </a> een kijkje voor inspiratie.
</p>`,
    goodScoreText: `<p>Mooi nieuws! Jouw toekomst op werkvlak ziet er zonnig uit.</p>`
  },
  {
    group: AccordionThemes.LEADERSHIP,
    badScoreText: `<p>
Persoonlijk leiderschap (o.a. veerkracht op het werk en eigen regie nemen) is cruciaal voor je professionele groei en succes. Het lijkt erop dat je tegen dingen aanloopt. Investeer zeker in je leiderschapsvaardigheden. Daarmee vergroot je je werkplezier én verbeter je je positie als ondernemer. Bekijk onze trainingen en coaching over <a href="https://www.sharepeople.nl/gezond-ondernemen-persoonlijk-leiderschap/"> persoonlijk leiderschap </a> die je hierbij kunnen helpen.
</p>`,
    goodScoreText: `<p>Jij bent goed bezig! Blijf vooral proactief werken aan je inzetbaarheid. Daarmee stimuleer je je professionele groei en succes. En je hebt er positieve impact mee op anderen.</p>`
  },
  {
    group: AccordionThemes.WORK_LIFE_BALANCE,
    badScoreText: `<p>
Het lijkt erop dat jij moeite hebt om werk en privé in balans te houden. Die balans is wel belangrijk voor je welzijn én voor je prestaties als ondernemer. Maak er werk van om deze balans te verbeteren. Zo zorg je voor een gezondere werkomgeving en betere prestaties. Meer tips en tools? Bekijk onze pagina over de  <a href="https://www.sharepeople.nl/gezond-ondernemen-werk-prive-balans/"> balans tussen werk en privé. </a>
</p>`,
    goodScoreText: `<p>Het blijkt dat je jouw werk en privé zo kan inrichten dat je een fijne balans hebt. Blijf zo doorgaan! Mocht je echter ooit ondersteuning nodig hebben bij het verfijnen ervan, dan staan we altijd voor je klaar.</p>`
  },
  {
    group: AccordionThemes.EXPERIENCE,
    badScoreText: `<p>
        Altijd blijven leren is als ondernemer extra belangrijk. Jij bent tenslotte je enige werknemer en wilt expertise uitstralen. Maak daarom tijd vrij om, naast je dagelijkse werk, nieuwe dingen te leren. Dat is goed voor je professionele vaardigheden én voor zakelijke groei. Zet nu alvast de stap! In de pagina over <a href="https://www.sharepeople.nl/gezond-ondernemen-weten-en-kunnen/"> weten en kunnen </a> vind je volop tools om nieuwe vaardigheden en kennis op te doen.
    </p>`,
    goodScoreText: `
    <p>
    Dat doe je goed! Jij probeert regelmatig nieuwe vaardigheden te leren. Zo kun je continu groeien en jezelf ontwikkelen. Blijf zeker open staan voor nieuwe kansen. Er blijft altijd wat te leren, zeker in het dynamische vak dat ondernemen heet.
</p>
    `
  },
  {
    group: AccordionThemes.HEALTH,
    badScoreText: `<p>
Als je regelmatig fysieke klachten hebt, moet je zeker op tijd in actie komen. Hoe je dat doet? Bekijk onze pagina over <a href="https://www.sharepeople.nl/gezond-ondernemen-gezondheid/"> gezondheid </a> om te zien hoe je jouw gezondheid kunt verbeteren. Hier vind je tips voor lichaamsbeweging, stressmanagement, een ergonomische werkplek en meer. Het is nooit te laat voor positieve verandering richting een gezonder en gelukkiger werkleven. Dus waar wacht je nog op?!
</p>`,
    goodScoreText: `<p>
Mooi om te zien dat je zo goed scoort qua gezondheid! Daar worden we bij SharePeople blij van: ondernemers die goed voor zichzelf zorgen en in balans zijn. Blijf vooral doorgaan met je gezonde leefstijl. Maar vergeet niet dat we er ook voor je zijn als je toch hulp of advies nodig hebt.
</p>`
  },
  {
    group: AccordionThemes.OBSTACLES,
    badScoreText: `<p>
Je geeft aan dat er meerdere factoren zijn die jouw werk moeilijk maken. Kijk eens kritisch hoe je jouw situatie kunt verbeteren. Ons advies: laat iemand met je meekijken. Soms zijn er meer opties dan je denkt. Plan gerust een gesprek met onze <a href="https://sharepeople.typeform.com/to/lOoXSSC6#zzp_id=xxxxx&fname=xxxxx&lname=xxxxx&bdate=xxxxx&mail=xxxxx&tel=xxxxx"> Adviseurs Werkvermogen. </a> Zij helpen je graag.
</p>`,
    goodScoreText: `<p>
Je geeft aan dat er weinig tot geen factoren zijn die jouw werk moeilijk maken. Je hebt het goed voor elkaar!
</p>`
  }

]
