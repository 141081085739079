import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[gktFormsAllowedChars]'
})
export class AllowedCharsDirective implements OnInit {

  /*
  This directive requires a regular expression to filter the characters.
  Regex examples:
    - only numbers: /\d/
    - only letters: /[a-zA-Z]/
    - only symbols: /\W/
    - only letters and numbers: /\w/
    - only letters and symbols: /\d/
    - only numbers and symbols: /[\d\W]/
    - only numbers and date divisors (- and /): [\d\/-]
    see regex101.com
   */

  @Input('gktFormsAllowedChars') regexStr: string;
  allowRegex: RegExp;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    const trimmedString = this.isInputRegex(this.regexStr);
    this.allowRegex = new RegExp(trimmedString);
  }

  @HostListener('keyup') checkAllowedChars() {
    const hostElem = this.elementRef.nativeElement;
    if (hostElem.value) {
      const newValue = this.removeIllegalChars(hostElem.value, this.allowRegex);
      if (newValue.newString !== hostElem.value) {
        hostElem.value = newValue.newString;
        hostElem.setSelectionRange(newValue.cursorIndex, newValue.cursorIndex);
      }
    }
  }

  removeIllegalChars(input: string, regex: RegExp) {
    let lastIndex = 0;
    const newValue = input
      .split('')
      .filter((char, ind) => {
        const isValid = new RegExp(regex).test(char);
        if (!isValid) {
          lastIndex = ind;
          return false;
        } else {
          return true;
        }
      })
      .join('');
    return {
      newString: newValue,
      cursorIndex: lastIndex
    };

  }

  isInputRegex(regexLiteral): string {
    if (!regexLiteral) {
      throw new Error(`The gktFormsAllowedChars requires a regex literal input. Got: ${this.regexStr}`);
    }
    const splitStr = regexLiteral.split('');
    if (splitStr[0] === '/' && splitStr[-0] === '/') {
      return splitStr.slice(1, -1).join('');
    } else {
      throw new Error(`The gktFormsAllowedChars requires a regex literal input. Got: ${this.regexStr}`);
    }
  }
}
