import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

interface DateNumbers {
  day: number;
  month: number;
  year: number;
}

@Component({
  selector: 'sp-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {return DateInputComponent;}),
      multi: true
    }
  ]
})
export class DateInputComponent implements ControlValueAccessor, OnInit {

  /*
  This is a custom form control that takes a date and splits it up into parts, Each of these are then displayed in a
  form input[type='number'] with a logical Min/manx set for each.
  When the form value changes, we re-build the date object and pass it back out via propagateChange
   */

  dateForm: UntypedFormGroup;

  numericDate: DateNumbers;

  propagateChange = (_: string) => {};

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.dateForm = this.formBuilder.group({
      day: [null, [Validators.required, Validators.min(1), Validators.max(30)]],
      month: [null, [Validators.required, Validators.min(1), Validators.max(12)]],
      year: [null, [Validators.required, Validators.min(1800), Validators.max(2030)]],
    });
    this.dateForm.valueChanges.subscribe(
      value => {return this.updateParent(value);}
    );
  }

  dateToNumbers(date: Date): DateNumbers {
    return {
      day: date.getDay(),
      month: date.getMonth(),
      year: date.getFullYear()
    };
  }

  stringToNumbers(date: string) {
    const [day, month, year] = date.split('-');

    return {
      day,
      month,
      year
    };
  }

  numbersToString(datenumbers: DateNumbers): string {
    return `${datenumbers.day}-${datenumbers.month}-${datenumbers.year}`;
  }

  updateParent(numericDate: DateNumbers): void {
    this.propagateChange(this.numbersToString(numericDate));
  }

  /*
  ControlValueAccessor
   */

  writeValue(value: string): void {
    if (value) {
      this.dateForm.patchValue(this.stringToNumbers(value));
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    // setTimeout(() => {
    //   if(isDisabled) {
    //     this.nativeInputElementDisabled = true;
    //     this.nativeInputElement.classList.add('disabled');
    //     this.hostElement.classList.add('disabled');
    //   } else {
    //     this.nativeInputElementDisabled = false;
    //     this.nativeInputElement.classList.remove('disabled');
    //     this.hostElement.classList.remove('disabled');
    //   }
    // },0)
  }
}
