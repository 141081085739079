<sp-onboard-page-content-wrapper
  [footerConfig]="footerConfig"
  [showStepper]="true"
  [stepperConfig]="{currentStep: 2, onboardingType: (onboardingType$ | async)}">
  <br/><br/>
  <section class="main">
    <ng-container>
      <h4>Kan iedereen meedoen met SharePeople Lang?</h4>
      <div class="form-container">
        <br/>
        <p>Nee, helaas kan dat niet.<br/> Om SharePeople Lang gezond te houden, kunnen we niet ruimhartiger zijn dan
          verzekeraars.</p><br/>
      </div>
    </ng-container>
  </section>
</sp-onboard-page-content-wrapper>
