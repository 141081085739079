import { Injectable,Inject } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import {MyAreaService} from '../../../../../my-portal/src/app/my-area-module/my-area.service';
import {OnboardingService} from '../../../../../onboarding/src/app/onboarding-module/onboarding.service';

export interface Theme {
  partnerName: string; // eg "Knab"
  subdomainName: string|null; // eg knab (knab.onboarding.sharepeople.nl)
  className: string; // eg "theme-knab"
  media: {
    logo: string; // eg "asset name"
    alt: string; // Logo alt
    favicon: string;
  },
  redirectUrl?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  availableThemes: Theme[] = [
    {
      partnerName: 'SharePeople',
      subdomainName: null,
      className: 'theme-sp',
      media: {
        logo: 'assets/images/logo-mobile.png',
        alt: 'sharePeople',
        favicon: 'assets/icons/favicon.ico',
      }
    }
  ];

  _currentTheme: BehaviorSubject<Theme> = new BehaviorSubject(null);
  currentTheme$: Observable<Theme> = this._currentTheme.asObservable().pipe(distinctUntilChanged());

  _runningProject: BehaviorSubject<('portal' | 'onboarding')> = new BehaviorSubject(null);
  runningProject$: Observable<('portal' | 'onboarding')> = this._runningProject.asObservable().pipe(distinctUntilChanged());

  _onboardingStyle: BehaviorSubject<string> = new BehaviorSubject(null);
  onboardingStyle$: Observable<string> = this._onboardingStyle.asObservable().pipe(distinctUntilChanged());

  themeEl = document.body;

  constructor(
    @Inject(DOCUMENT)
    private _document: HTMLDocument,
    private myAreaService: MyAreaService,
  ) { }

  /**
   * Called on first load. Sets the theme based on subdomain in the url.
   */
  init() {
    const theme$ = this.getCurrentThemeFromUrl();

    theme$.subscribe(
      theme => {return this.setCurrentTheme(theme);}
    );
    this.runningProject$.subscribe(runningProject => {
        const defaultTheme = this.availableThemes[0];
        this._currentTheme.next(defaultTheme);
    });
  }


  public getCurrentThemeFromUrl(): Observable<Theme> {
    const subdomain = this.getSubdomainFromCurrentUrl();
    const defaultTheme = this.availableThemes[0];
    this._currentTheme.next(defaultTheme);
    return this.currentTheme$;
  }

  public getSubdomainFromCurrentUrl(): string|null {
    const domain = window.location.hostname;
    // const domain = 'knab.inschrijven.sharepeople';
    let subdomain;
    // we need to check for the the default staging URL first, since it already has one subdomain: "staginginschrijven.geckoapps.nl"
    if (domain.includes('geckoapps') && domain.split('.').length === 3) {
      subdomain = null;
    } else if (domain.indexOf('.') < 0) {
      subdomain = null;
    } else {
      subdomain = domain.split('.')[0];
    }
    return subdomain;
  }

  public setCurrentTheme(theme: Theme): void {
    this.availableThemes.forEach(
      theme => {
        this.themeEl.classList.remove(theme.className);
      }
    );
    this.themeEl.classList.add(theme.className);
    this.setFavicon(theme.media.favicon);
  }

  public getLogo(): Observable<object> {
    return this.currentTheme$
      .pipe(
        map(theme => {return theme.media;})
      );
  }

  public getCurrentTheme(): Observable<Theme> {
    return this.currentTheme$;
  }

  public setFavicon(path): void {
    this._document.getElementById('appFavicon').setAttribute('href', path);
  }
}
