<ng-container *ngIf="(profile$ | async) as profileData">
  <ng-container *ngIf="(memberData$ | async) as memberData">
    <sp-portal-page-wrapper title="Mijn gegevens">
      <form [formGroup]="editingForm" title="" #form='ngForm'>
        <div class="list-container">

          <div class="list-container--header">
            <h3>Persoonlijk</h3>
            <span class="action" (click)="openEdit('personalPopupActive')"><span
              class="pencil"></span>&nbsp; Wijzigen</span>
          </div>

          <div class="item-container">
            <label>Naam*</label>
            <span>{{profileData.firstName}} {{ profileData.lastName}}</span>
          </div>

          <div class="item-container">
            <label>Deelnemer nummer</label>
            <span>{{memberData.memberCode}}</span>
          </div>

          <div class="item-container">
            <label>Adres</label>
            <div class="input-wrapper">
              <span>{{profileData.streetAndNumber}}</span>
            </div>
          </div>

          <div class="item-container">
            <label>Woonplaats</label>
            <div class="input-wrapper">
              <span>{{profileData.postalCode}} {{profileData.city}}</span>
            </div>
          </div>

          <div class="item-container">
            <label>Geboortedatum</label>
            <div class="input-wrapper">
              <span>{{this.dateTimeService.convertIsoToReadable(profileData.dob)}}</span>
            </div>
          </div>
        </div>

        <div class="list-container">
          <div class="list-container--header">
            <h3>Contact</h3>
            <span class="action" (click)="openEdit('contactPopupActive')"><span
              class="pencil"></span>&nbsp; Wijzigen</span>
          </div>

          <div class="item-container">
            <label>Email</label>
            <div class="input-wrapper">
              <span>{{profileData.email}}</span>
            </div>
          </div>

          <div class="item-container">
            <label>Mobiel nummer</label>
            <div class="input-wrapper">
              <span>{{profileData.telephone}}</span>
            </div>
          </div>
        </div>

        <div class="list-container">
          <div class="list-container--header">
            <h3>Bedrijf</h3>
            <span class="action" (click)="openEdit('companyPopupActive')"><span
              class="pencil"></span>&nbsp; Wijzigen</span>
          </div>

          <div class="item-container">
            <label>Beroep</label>
            <div class="input-wrapper">
              <span>{{occupationLabel}}</span>
            </div>
          </div>

          <div class="item-container">
            <label>Bedrijfsnaam*</label>
            <span>{{profileData.company}}</span>
          </div>

          <div class="item-container">
            <label>KVK nummer*</label>
            <span>{{profileData.kvk}}</span>
          </div>

          <div class="item-container">
            <div class="label-wrapper">
              <label>Betalen per</label>
            </div>
            <span>{{profileData.incasso ? 'Incasso' : 'Factuur'}}</span>
          </div>

          <div class="item-container">
            <label>Periode</label>
            <span>{{profileData.monthlyPayment ? 'Maandelijks' : 'Jaarlijks'}}</span>
          </div>

          <div class="item-container" *ngIf="profileData.incasso">
            <label>Incasso IBAN</label>
            <span>{{profileData.iban}}</span>
          </div>

          <p class="send-email-note">* Lukt het wijzigen van je gegevens niet? Stuur dan een e-mail naar <a
            href="mailto:contact&#64;sharepeople.nl">contact&#64;sharepeople.nl</a></p>
          <p class="cancel-subscription-note">
            <button sp-button class="endparticipation-button" (click)="endParticipation('https://www.sharepeople.nl/8d2kf9f5fgg3h/?spid='+memberData.memberCode+'&voornaam='+profileData.firstName+'&achternaam='+profileData.lastName+'&email='+profileData.email)">Deelname beëindigen</button>
            <br/>Klik hier om je deelname te beëindigen</p>

        </div>

        <div class="list-container" *ngIf="participantPromiseInfo">
          <h3>Verklaring en beloften</h3>
          <div class="item-container">
            <label>Mijn verklaring en beloften</label>
            <span>{{participantPromiseInfo?.dateStamp | date}}</span>
            <button sp-button class="download-btn" (click)="downloadPromise()">Download</button>
          </div>
        </div>

        <button sp-button (click)="logout()" class="uitloggen-btn">Uitloggen</button>
      </form>

      <div title="">
        <sp-portal-personal-popup [popupActive]="personalPopupActive" [formGroup]="personalFormGroup"
                                  (onFormGroupChange)="updateFormPersonal($event)"
                                  (onClosePopup)="closePopup($event)"></sp-portal-personal-popup>
        <sp-portal-contact-popup [popupActive]="contactPopupActive" [formGroup]="contactFormGroup"
                                 (onFormGroupChange)="updateFormContact($event)"
                                 (onClosePopup)="closePopup($event)"></sp-portal-contact-popup>
        <sp-portal-company-popup [popupActive]="companyPopupActive" [formGroup]="companyFormGroup"
                                 [profile]="profileData" [occupations]="occupations"
                                 (onFormGroupChange)="updateFormCompany($event)"
                                 (onClosePopup)="closePopup($event)"></sp-portal-company-popup>
      </div>

    </sp-portal-page-wrapper>
  </ng-container>
</ng-container>

<div class="popup-background" [class.active]="confirmPopup">
  <div class="popup-container">
    <p>Veranderingen opgeslagen</p>
    <div class="btn-wrapper">
      <button [sp-button] (click)="closeConfirm()" class="save-btn">sluiten</button>
    </div>
  </div>
</div>
