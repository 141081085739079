import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {OverlayConfig} from '../models/models';

@Injectable({
  providedIn: 'root'
})
export class TcOverlayService {

  _overlay = new BehaviorSubject<OverlayConfig>(null);

  public overlay$ = this._overlay.asObservable();

  constructor() { }

  public open(overlay: OverlayConfig) {
    this._overlay.next(overlay);
  }

  public close() {
    this._overlay.next(null);
  }


}
