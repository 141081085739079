import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[spPortalCopyText]',
})
export class CopyTextDirective {
  // @Input()
  @Input() public copyText = '';

  constructor() {}

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    console.warn(event);
    event.preventDefault();
    if (!this.copyText) {
      return;
    }

    navigator.clipboard.writeText(this.copyText.toString());
  }

}

