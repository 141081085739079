<ng-container [ngSwitch]="accountState">

  <ng-template ngSwitchCase="inactive">
    <section class="introduction">
      <h3>
        activerende...
      </h3>
    </section>
  </ng-template>

  <!--Deprecated – we're now redirected from the email activation link straight to the new-password component and then we 'soft log in' directly from there-->

<!--  <ng-template ngSwitchCase="activated">-->
<!--    <section class="introduction">-->
<!--      <h3>-->
<!--        Je account bij SharePeople is nu actief-->
<!--      </h3>-->
<!--      <p>Je emailadres is geverifieerd. Je kan nu je MijnOmgeving in. </p>-->
<!--    </section>-->
<!--    &lt;!&ndash;<section class="identification">&ndash;&gt;-->
<!--      &lt;!&ndash;<h4>Identificatie</h4>&ndash;&gt;-->
<!--      &lt;!&ndash;<p>&ndash;&gt;-->
<!--        &lt;!&ndash;Om uitbetalingen te doen, moeten we volgens wettelijke regeling jou identificeren met een paspoort of ID document.&ndash;&gt;-->
<!--        &lt;!&ndash;Doe dit in Mijn SharePeople op een moment dat jou schikt.&ndash;&gt;-->
<!--      &lt;!&ndash;</p>&ndash;&gt;-->
<!--    &lt;!&ndash;</section>&ndash;&gt;-->
<!--    <footer>-->
<!--      <button [sp-button] routerLink="../new-password">naar Mijn SharePeople</button>-->
<!--    </footer>-->
<!--  </ng-template>-->


  <ng-template ngSwitchCase="code-expired">
    <section class="introduction">
      <h3>
        The code has expired.
      </h3>
    </section>
  </ng-template>

  <ng-template ngSwitchCase="code-invalid">
    <section class="introduction">
      <h3>
        Deze activatiecode is niet geldig.
      </h3>
    </section>
  </ng-template>

</ng-container>

