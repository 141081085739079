import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {CoverageLevel, CoverageLevelOptions, OnboardingRestService} from './onboarding.rest.service';
import {StorageService} from '../core-module/services/storage.service';
import {Observable, throwError} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {StepNavigationService} from './step-navigation.service';
import {OnboardingData} from './onboarding.model';
import {
  Address,
  ContactDetails,
  KvkDetails,
  MembershipPayment,
  PaymentStatus, RegCode,
  SpPaymentRequest
} from '../shared-module/models/models';
import {ThemeService} from '../../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {SpLongRestService} from '../sp-long-module/services/rest-service/sp-long-rest.service';
import {SpLongInvitationCode} from '../sp-long-module/models/sp-long.models';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  restartCounter = 0;

  constructor(
    private restService: OnboardingRestService,
    private longRestService: SpLongRestService,
    private storageService: StorageService,
    private router: Router,
    private stepNavigationService: StepNavigationService,
    private themeService: ThemeService
  ) {
  }

  checkQueries(spLongInvitationCode: string) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('code')) {
      spLongInvitationCode = ((urlParams.get('code')).replace('"', '')).replace('"', '');
      localStorage.setItem('spLongInvitationCode', spLongInvitationCode);
    }
  }

  setRegCode(regCode) {
    if (regCode) {
      this.restService.regCodeFallback = regCode;
    }
  }

  getItem(itemKey) {
    return this.storageService.getOnboardingData()[itemKey];
  }

  setCurrentStep(url?: string) {
    this.stepNavigationService.setCurrentStep(url);
  }

  storeDiscountCode(onboardingCode: string) {
    const code = onboardingCode ? onboardingCode : null;
    this.storageService.updateOnboardingData({discountCode: code});
  }

  retrieveDiscountCode(): string {
    const onboardingData = this.storageService.getOnboardingData();
    return onboardingData ? onboardingData.discountCode : null;
  }

  storeQueries(externalId, encryptedUserId, hashedEmail): void {
    this.storageService.updateOnboardingData({
      externalId: externalId,
      encryptedUserId: encryptedUserId,
      hashedEmail: hashedEmail
    });
  }

  getQueries(): any {
    const onboardingData = this.storageService.getOnboardingData();
    return {
      externalId: onboardingData?.externalId,
      encryptedUserId: onboardingData?.encryptedUserId,
      hashedEmail: onboardingData?.hashedEmail,
      occupation: onboardingData?.occupation
    };
  }

  getAndStoreCoverageLevelOptionsAndUSPs(externalId, encryptedUserId, hashedEmail, occupation, splong): Observable<CoverageLevelOptions> {
    const style = this.themeService.getSubdomainFromCurrentUrl();

    return this.restService.getCoverageLevelOptions(this.retrieveDiscountCode(), externalId, encryptedUserId, hashedEmail, style, occupation, splong)
      .pipe(
        tap((returnedData) => {
          this.storageService.updateOnboardingData({
            coverageLevelOptions: returnedData.coverageLevels,
            usps: returnedData.usps,
            maxAge: returnedData.maxAge,
            partnerName: returnedData.partnerName,
            productName: returnedData?.productName,
            externalId: externalId,
            encryptedUserId: returnedData.encryptedUserId,
            hashedEmail: returnedData.hashedEmail
          });
          this.themeService._onboardingStyle.next(returnedData.style);
        })
      );
  }

  retrieveCoverageLevelsArray(): CoverageLevel[] {
    const onboardingData = this.storageService.getOnboardingData();
    return onboardingData.coverageLevelOptions ? onboardingData.coverageLevelOptions : [];
  }

  retrieveExtraLevelsArray(): CoverageLevel[] {
    const onboardingData = this.storageService.getOnboardingData();
    return onboardingData.extraLevelOptions ? onboardingData.extraLevelOptions : [];
  }

  retrieveUSPs() {
    const onboardingData: OnboardingData = this.storageService.getOnboardingData();
    return onboardingData.usps;
  }

  retrieveProductMaxAge(): number {
    const onboardingData = this.storageService.getOnboardingData();
    if (onboardingData && onboardingData.maxAge) {
      return onboardingData.maxAge;
    } else {
      return null;
    }
  }

  storeChosenCoverageType(value): void {
    this.storageService.updateOnboardingData({spLong: value == 'true'});
  }

  retrieveChosenCoverageType(): boolean {
    const onboardingData: OnboardingData = this.storageService.getOnboardingData();
    return onboardingData?.spLong;
  }

  storeChosenOccupation(id): void {
    this.storageService.updateOnboardingData({occupation: id});
  }

  retrieveChosenOccupation(): string {
    const onboardingData = this.storageService.getOnboardingData();
    return onboardingData.occupation;
  }

  storeChosenCoverageLevel(coverage: CoverageLevel): void {
    this.storageService.updateOnboardingData({selectedCoverageLevel: {...coverage}});
  }

  updateCoverageLevelOptions(options): void {
    this.storageService.updateOnboardingData({
      extraLevelOptions: options.map((op) => {
        return {
          amount: op.coverage,
          averageMonthlyCost: op.cost
        };
      })
    });
  }

  retrieveSelectedCoverageLevel(): CoverageLevel {
    const onboardingData = this.storageService.getOnboardingData();
    return onboardingData.selectedCoverageLevel;
  }

  storeChosenSpLongCoverageLevel(value): void {
    this.storageService.updateOnboardingData({chosenSpLongCoverageLevel: value});
  }

  retreiveChosenSpLongCoverageLevel(): number {
    const onboardingData = this.storageService.getOnboardingData();
    return onboardingData.chosenSpLongCoverageLevel;
  }

  storeknowledgeCheck(value): void {
    this.storageService.updateOnboardingData({hasUnderstood: value});
  }

  retrieveknowledgeCheck(): boolean {
    const onboardingData: OnboardingData = this.storageService.getOnboardingData();
    return onboardingData.hasUnderstood;
  }

  retrieveContactDetails(): ContactDetails {
    const onboardingData = this.storageService.getOnboardingData();
    return {
      firstName: onboardingData.firstName,
      lastName: onboardingData.lastName,
      email: onboardingData.email,
      telephone: onboardingData.telephone,
      dob: onboardingData.dob,
      discountCode: onboardingData.discountCode
    };
  }

  setAndStoreContactDetails(contactForm): Observable<RegCode> {
    const coverageLevel = this.retrieveSelectedCoverageLevel();
    const longCoverageLevel = this.retreiveChosenSpLongCoverageLevel();
    const onboardingData = this.storageService.getOnboardingData();
    const discountCode = onboardingData.discountCode;
    const contactDetails: ContactDetails = {
      ...contactForm,
      discountCode,
      coverageLevel: coverageLevel.amount,
      longCoverageLevel: longCoverageLevel
    };
    const externalId = onboardingData.externalId;
    const encryptedUserId = onboardingData.encryptedUserId;
    const hashedEmail = onboardingData.hashedEmail;
    const hasUnderstood = this.retrieveknowledgeCheck();
    return this.restService.postNameAndContact(contactDetails, hasUnderstood, encryptedUserId, hashedEmail, externalId)
      .pipe(
        tap(() => {
          this.storeContactDetails(contactForm);
        })
      );
  }

  storeContactDetails(contactDetails: ContactDetails) {
    this.storageService.updateOnboardingData(contactDetails);
  }

  storeDOB(date: string){
    this.storageService.updateOnboardingData({'dob': date});
  }

  storeRegCode(regCode: string) {
    this.storageService.updateOnboardingData({registrationCode: regCode});
  }

  retrieveRegCode() {
    const onboardingData = this.storageService.getOnboardingData();
    if (!onboardingData || !onboardingData.registrationCode) {
      if (this.restService.regCodeFallback) {
        console.log('using fallbackRegCode:', this.restService.regCodeFallback);
        return this.restService.regCodeFallback;
      } else {
        this.throwSentryRegCodeError();
      }
    } else {
      return onboardingData.registrationCode;
    }
  }

  throwSentryRegCodeError() {
    const onboardingData = window.localStorage.getItem('onboardingData');
    Sentry.setContext('localstorage_attributes', {
      type: 'Failed to get regCode from LS',
      onboardingData: onboardingData,
    });
    Sentry.captureException(new Error('Failed to get regCode from LS'));
  }

  setAndStoreAddress(address: Address, registrationCode, encryptedUserId, hashedEmail) {
    return this.restService.postAddress(address, registrationCode, encryptedUserId, hashedEmail)
      .pipe(
        tap(() => {return this.storeAddress(address);})
      );
  }

  storeAddress(address: Address) {
    this.storageService.updateOnboardingData({
      street: address.street,
      number: address.number,
      postalCode: address.postalCode,
      city: address.city
    });
  }

  retrieveAddress(): Address {
    const onboardingData = this.storageService.getOnboardingData();
    return {
      street: onboardingData.street,
      number: onboardingData.number,
      postalCode: onboardingData.postalCode,
      city: onboardingData.city
    };
  }

  retrieveCompany(): KvkDetails {
    const onboardingData = this.storageService.getOnboardingData();
    return {
      company: onboardingData.company,
      kvk: onboardingData.kvk
    };
  }

  checkKvk(kvkNumber, encryptedUserId, hashedEmail): Observable<KvkDetails> {
    return this.restService.checkKvk(kvkNumber, this.retrieveRegCode(), encryptedUserId, hashedEmail);
  }

  setAndStoreCompanyDetails(companyDetails: KvkDetails) {
    return this.restService.postCompany(companyDetails.kvk, companyDetails.company, this.retrieveRegCode())
      .pipe(
        tap((companyDeets) => {return this.storeCompany(companyDeets);})
      );
  }

  storeCompany(companyDetails: KvkDetails) {
    return this.storageService.updateOnboardingData({
      company: companyDetails.company,
      kvk: companyDetails.kvk
    });
  }

  retrievePromise(): any {
    const onboardingData = this.storageService.getOnboardingData();
    return onboardingData.promiseAccepted;
  }

  getAndStorePromise(promiseAccepted: boolean): Observable<boolean> {
    return this.restService.postPromiseAcceptance(promiseAccepted, this.retrieveRegCode())
      .pipe(tap((promise) => {return this.storePromise(promise);}));
  }

  getAndStoreLongPromise(criteriaAccepted: {acceptPromise: boolean, acceptPrivacy: boolean}): Observable<boolean> {
    return this.restService.postPromiseProspectsAcceptance(criteriaAccepted, this.retrieveRegCode())
      .pipe(tap((promise) => {return this.storePromise(promise);}));
  }

  storePromise(promise: boolean) {
    this.storageService.updateOnboardingData({promiseAccepted: promise});
  }

  getNewAndUpdatePaymentRequest(incassoAgreement) {
    return this.restService.requestNewPayment(this.retrieveRegCode(), incassoAgreement)
      .pipe(tap((paymentRequest: SpPaymentRequest) => {
        this.storePaymentRequest(paymentRequest);
      }));
  }

  storePaymentRequest(paymentRequest: SpPaymentRequest) {
    this.storageService.updateOnboardingData({paymentRequest});
  }

  retrievePaymentRequest() {
    const onboardingData = this.storageService.getOnboardingData();
    if (!onboardingData.paymentRequest) {
      throw new Error('no Payment Request data in storage');
    }
    return onboardingData.paymentRequest;
  }

  retrieveIsBrightPensioenMember() {
    const onboardingData = this.storageService.getOnboardingData();
    return onboardingData.isBrightPensioenMember;
  }

  pollPaymentStatus(isBrightPensioenMember): Observable<PaymentStatus> {
    let regCode = this.retrieveRegCode();
    if (!regCode) {
      console.log('Could not retrieve Registration code from storage or from query parameter, looking for code directly in URL.');

      const query = window.location.search.substring(1);
      if (query.split('=')[0] === 'registrationCode') {
        regCode = query.split('=')[1];
        console.info('Retrieved code successfully from url.');
        this.setRegCode(regCode);
      }

      if (!regCode) {
        return throwError('No Reg code');
      }
    }
    return this.restService.postPaymentStatus(regCode, isBrightPensioenMember);
  }

  setMembershipPayment(membershipPayment: MembershipPayment) {
    const regCode = this.retrieveRegCode();
    return this.restService.postMembershipPayment({...membershipPayment, registrationCode: regCode});
  }

  revertAllLocalDataAndRestart() {
    this.restartCounter++;
    if (this.restartCounter > 5) {
      const resp = window.confirm('are you sure');
      if (resp) {
        this.revertAllDataNow();
      }
      this.restartCounter = 0;
    }
  }

  revertAllDataNow() {
    this.storageService.clearAllCredentials();
    this.router.navigate(['/']).then(
      () => {
        console.log('this was the old LS clear call');
      }
    );
  }

  getPromiseHTML(isLong?: boolean) {
    return isLong ? this.longRestService.getPromiseOverlayHTML(this.retrieveRegCode()) : this.restService.getOverlayHTML(this.retrieveRegCode());
  }

  mgmInvitedBy(nameOrEmail: string) {
    return this.restService.postMgmInvitedBy(this.retrieveRegCode(), nameOrEmail);
  }

  returnUserToStartPage() {
    console.info('Returning user to start page');
    if (this.router.url.includes('processing-payment')) {
      return;
    } else {
      this.revertAllDataNow();
    }
  }

  getMemberCode() : Observable<string> {
    let registrationCode: string = this.storageService.getOnboardingData()?.registrationCode
    let invitationCode: string = localStorage.getItem(SpLongInvitationCode.SPLONGINVITATIONCODE);

    return this.restService.getMemberCode(registrationCode, invitationCode);
  }

  getDiscountCodeRedirectUrl(): null | Observable<string | null> {
    let discountCode: string = this.storageService.getOnboardingData()?.discountCode;
    if (discountCode) {
      return this.restService.getDiscountCodeRedirectUrl(discountCode);
    }
    return null;
  }
}
