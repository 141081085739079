import {Component, OnInit, ViewChild} from '@angular/core';
import {MyAreaRestService} from '../my-area.rest.service';
import {RegistrationService} from '../../registration-module/registration.service';
import {BehaviorSubject, EMPTY, Observable, Subject, throwError, timer} from 'rxjs';
import {Router} from '@angular/router';
import {MyAreaService} from '../my-area.service';
import {catchError, retry, share, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {UserProfile} from '../profile/profile.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {environment} from '../../../environments/environment';
import {AOVTermsPopupComponent} from './aov-terms-popup/aov-terms-popup.component';
import {LongWarningPopupComponent} from './long-warning-popup/long-warning-popup.component';
import {ConnectedPosition, Overlay} from "@angular/cdk/overlay";
import {CdkPortal} from "@angular/cdk/portal";

export interface MemberData {
  group: string;
  coverage: number;
  utilisation: string;
  memberCode: string;
  spLongInvitationCode?: string;
  eligibleForSpLong?: boolean;
  spLongCoverage?: number;
  spLongJoined?: boolean;
  spLongRejected?: boolean;
  startDate: string;
  reportedSick: string;
  eyecons: string;
  lastSelfTestDate: string;
  lastSelfTestMonths?: number;
  contribution: {
    contributionAmount: number;
    contributionToken: string;
    payeeName: string;
  };
  mgmCode?: string;
  medicalAssessment?: {
    status: string,
    code: string
  }
  aov: {
    eligibleForAovFirstPayment: boolean;
    eligibleForAovSubscription: boolean;
    firstFee: number;
    paymentUrl: string;
    monthlyFee: number;
    subscription?: boolean;
  };
  occupation: {
    risk: number;
    name: string;
  };

  contributionHistory: {
    average: string;
    history: [
      {
        description: string;
        amount: number;
        period: string;
        status: {
          name: string;
          enum: string;
        }
      }
    ],
    hasMissedContributions?: boolean;
  };
  canApplyForAov?: boolean;
  utilisationInfo:Utilisation;
  registrationCode?: string;
  dateofbirth?: string;
}

export interface Utilisation {
  result:  string;
  spLong:  string;
  spShort: string;
}


export interface Score {
  group:   string;
  average: string;
  expanded?: boolean;
  showButton?: boolean;
  buttonText?: string;
  buttonLink?: string;
}


export interface ISelfTest {
  code: string;
  averageGroupScore: string;
  totalScore: string;
  scores: Score[];
  completedDate : string;
  groupScore: string;
}

export interface ContentBlockData {
  title: string;
  description: string;
  link: string;
}

@Component({
  selector: 'sp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(CdkPortal) portal!: CdkPortal;

  yourAccountInfo: MemberData;
  isMedical = true;
  memberData$: BehaviorSubject<MemberData | null> = new BehaviorSubject<MemberData | null>(null);
  profileData$: Observable<UserProfile>;
  customContentBlockData: (ContentBlockData | null) = null;
  eyevestorPopupActive = false;
  popupText = 'Je krijgt een email van Eyevestor om je account te activeren.';
  popupLoading = true;
  aovTermsPopup = false;
  longWarningPopup = false;
  challengeCode: string;
  showCopyAlert = false;
  memberInviteForm: UntypedFormGroup;
  memberInvited = false;
  displayMemberInviteError = false;
  extendedCoverageOptions;
  memberInvitationCode: number;
  spLongInvitationCode: string;
  linkToSpLong: string;
  private stopPolling = new Subject();
  destroy$ = new Subject();
  tooltipPositions: ConnectedPosition[] = [
    {originX: 'center', originY: 'center', overlayX: 'center', overlayY: 'bottom', offsetY: -30, offsetX: 0},
  ];

  showRiskModalCutOff = 5;
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  updatedHistory$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  lastTestMonths: number
  isRejected = false;

  occupation?: string;
  dob?: string;
  regCode?: string;
  currentDate = new Date();
  protected shareIconsOpen = false;

  constructor(
    private restService: MyAreaRestService,
    private registrationService: RegistrationService,
    private router: Router,
    private myAreaService: MyAreaService,
    private formBuilder: UntypedFormBuilder,
    private overlay: Overlay
  ) {
  }

  ngOnInit() {
    this.createMemberInviteForm();
    this.myAreaService.init();

    this.myAreaService.memberData$.pipe(takeUntil(this.destroy$)).subscribe((memberData: MemberData) => {
      this.regCode = memberData.registrationCode;
      this.occupation = memberData?.occupation?.name;
      this.dob = memberData.dateofbirth;

      console.log(this.lastTestMonths);

      this.lastTestMonths = this.monthDiff(new Date(memberData.lastSelfTestDate), new Date())

      const updated = [];
      let lastDate;
      memberData.contributionHistory.history?.forEach((donation)=>{
        // If average object
        if(!donation.period) {
          updated.push(donation);
          return;
        }

        const year = parseInt(donation.period.substr(4));
        const month = this.months.indexOf(donation.period.substr(0,3));
        const date = new Date(year, month);
        let shouldBe = lastDate?new Date(lastDate.setMonth(lastDate.getMonth()-1)):date;
        let count = 0;
        // while donation date is not equal to shouldBe date push "Geen donatie" en bump shouldBe down 1 month
        while (date.getMonth() != shouldBe.getMonth() && count<10) {
          if(shouldBe) {
            updated.push({
              period: this.months[shouldBe.getMonth()] + ' ' + shouldBe.getFullYear(),
              description: 'Geen Donatie',
              amount: '0,00',
              status: 'no-donation'
            });
            shouldBe = new Date(shouldBe.setMonth(shouldBe.getMonth()-1));
            count++;
          }
        }
        // If donation date is equal to shouldBe date, push donation to array and set as last(used)Date
        if(date.getMonth() == shouldBe.getMonth() || !shouldBe) {
          lastDate = date;
          updated.push(donation);
        }
      });

      this.updatedHistory$.next(updated);
      this.memberData$.next(memberData);
    });
    this.profileData$ = this.myAreaService.profile$;
    this.myAreaService.memberData$.pipe(take(1)).subscribe(memberData => {
      this.spLongInvitationCode = memberData.spLongInvitationCode;
      this.linkToSpLong = `${environment.sp_long_url}?code=${this.spLongInvitationCode}`;
    });

    this.getCustomContentBlockData();
    this.generateChallengeCode();
    this.pollMemberData();
  }

  ngOnDestroy() {
    this.stopPolling.next();
  }

  monthDiff(dateFrom, dateTo) {
    console.log(dateFrom);
    console.log(dateTo);

    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }

  navigateMedicalPage(url: string) {
    window.location.href=url
  }

  protected openModal() {
    this.shareIconsOpen = true;
  }

  pollMemberData() {
    timer(1, 5000).pipe(
      switchMap(() => {return this.myAreaService.pollMemberData();}),
      retry(),
      tap((memberData) => {return this.callStopPolling(memberData);}),
      catchError((origError) :any => {
        console.log(origError);
      // if(origError.status==404) this.callStopPolling
      }),
      share(),
      takeUntil(this.stopPolling)
    ).subscribe((resp) => {
    });
  }

  // iterate = 0;
  callStopPolling(memberData: MemberData) {
    this.memberData$.next(memberData);

    if(memberData.aov && !memberData.aov.eligibleForAovFirstPayment) {
      this.stopPolling.next();
    }
  }


  createMemberInviteForm() {
    this.memberInviteForm = this.formBuilder.group({
      name: [null, [Validators.required, CustomValidators.fullname] ],
      email: [null, [Validators.required, CustomValidators.email]]
    });
  }

  sendMemberInvite(): void {
    if (this.memberInviteForm.valid) {
      const name = this.memberInviteForm.get('name').value.trim();
      const email = this.memberInviteForm.get('email').value;
      this.restService.sendMemberInvite(email, name)
        .pipe(
          catchError((origError) => {
            this.displayMemberInviteError = true;
            this.memberInvited = false;
            return throwError(origError);
          })
        )
        .subscribe(() => {
          this.memberInvited = true;
          this.displayMemberInviteError = false;
          this.memberInviteForm.clearValidators();
          this.memberInviteForm.updateValueAndValidity();
          this.memberInviteForm.reset();
        });
    } else {
      this.memberInvited = false;
      this.memberInviteForm.markAllAsTouched();
    }
  }

  getCustomContentBlockData() {
    this.restService.getCustomContentData()
      .subscribe(
        customData => {
          this.customContentBlockData = customData ? customData : null;
        }
      );
  }

  checkMonthsDate(date: Date) {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() -6);
    return new Date(date) < sixMonthsAgo;
  }

  gotoCustomContentLink() {
    // this.router.navigateByUrl(this.customContentBlockData.link);
    window.open(this.customContentBlockData.link);
  }

  openAOVPayment() {
    this.aovTermsPopup  = true;
  }

  openLink(link: string) {
    window.open(link, "_self");
  }

  navigateToSelfTestResults() {
    this.router.navigateByUrl('/dashboard/self-test');

  }

  startSelfTest() {
    setTimeout(() => {
      this.myAreaService.startSelfTest().subscribe((value) => {
        window.open(value.formUrl, "_self");
      })
    }, 500)

  }

  openLongWarning() {
    this.longWarningPopup = true;
  }

  startAovOnboarding() {
    const base_url = `${environment.donation2aov_url}`;
    const url = base_url + '?type=donation2aov&dob=' + this.dob + '&occupation=' + this.occupation + '&registrationCode=' + this.regCode;
    console.log(url);
    window.open(url, '_self');
  }

  openPopup(){
    this.eyevestorPopupActive = true;
    this.myAreaService.postEyevestorSignUp()
      .pipe(
        catchError((origErr) => {
          if (origErr.status === 403) {
            this.popupText = 'Je hebt al een account bij Eyevestor.';
            this.popupLoading = false;
            return EMPTY;
          } else {
            this.popupLoading = false;
            return throwError(origErr);
          }
        })
      )
      .subscribe(() => {
        this.popupLoading = false;
      });
  }

  generateChallengeCode() {
    this.profileData$.subscribe(profileData => {
      const firstName = profileData.firstName;
      const dob = new Date (profileData.dob);
      let day = dob.getDate().toString();
      let month = (dob.getMonth() + 1).toString();
      if (month.length === 1) {
        month = `0${month}`;
      }
      if (day.length === 1) {
        day = `0${day}`;
      }
      this.challengeCode = `${firstName}${day}${month}`.toUpperCase();
    });
    return this.challengeCode;
  }

  navigateTo(url: string) {
    this.router.navigateByUrl('dashboard/' + url);
  }

  triggerConfirmationCopyAlert() {
    window.alert('De link staat op je klembord. Deel ‘m en pak de korting.');
  }

  getExtendedCoverageOptions() {
    this.myAreaService.getExtendedCoverageOptions().subscribe(options => {
      this.extendedCoverageOptions = options;
    });
  }

  async copyText() {
    try {
     await  navigator.clipboard.writeText(`https://www.sharepeople.nl/mgm-lp/?mgm=${this.memberData$.getValue()?.mgmCode}`)
      this.triggerConfirmationCopyAlert()
    }
    catch(error: any) {
      console.error(error);
    }
  }

  navigateToWhatsapp() {
    let whatsapptext = `Hoi!%0A%0AIk%20ben%20echt%20blij%20met%20SharePeople%2C%20een%20eerlijk%20en%20slim%20vangnet%20bij%20arbeidsongeschiktheid%20voor%20ondernemers.%20Geen%20verzekeraar%2C%20maar%20we%20helpen%20elkaar.%0A%0AGun%20ik%20jou%20ook!%20Aanmelden%20is%20makkelijk%3A%20https%3A%2F%2Fwww.sharepeople.nl%2Fmgm-lp%2F%3Fmgm=${this.memberData$.getValue()?.mgmCode}%20je%20betaalt%20zelfs%203%20maanden%20geen%20deelnamekosten%20(en%20ik%20krijg%20dat%20ook!)%20bovenop%20de%20lopende%20korting.%20%0A%0AAls%20je%20vragen%20hebt%2C%20laat%20maar%20weten`;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(navigator.userAgent) ) {
      window.open(`whatsapp://send?text=${whatsapptext}`);
    }
    else {
      window.open(`https://web.whatsapp.com/send?text=${whatsapptext}`);
    }
  }

  openMail() {
    let subject = `Ik%20denk%20dat%20SharePeople%20wat%20voor%20jou%20is`
    let mailText = `Hoi!%0A%0AIk%20ben%20echt%20blij%20met%20SharePeople%2C%20een%20eerlijk%20en%20slim%20vangnet%20bij%20arbeidsongeschiktheid.%20Geen%20verzekeraar%2C%20maar%20we%20helpen%20elkaar.%0AGun%20ik%20jou%20ook!%0A%0AAanmelden%20is%20makkelijk%3A%20https%3A%2F%2Fwww.sharepeople.nl%2Fmgm-lp%2F%3Fmgm%3D${this.memberData$.getValue().mgmCode}%20Via%20deze%20link%20betaal%20je%20zelfs%203%20maanden%20geen%20deelnamekosten%20(en%20ik%20krijg%20dat%20ook!)%20bovenop%20de%20lopende%20korting.%0A%0AAls%20je%20vragen%20hebt%2C%20laat%20maar%20weten!%0A%0AGroet%2C`
    let emailstring = `mailto:?subject=${subject} &body=${mailText}`
    window.location.href = emailstring;
  }

  protected readonly AOVTermsPopupComponent = AOVTermsPopupComponent;
  protected readonly LongWarningPopupComponent = LongWarningPopupComponent;
  protected readonly environment = environment;

}
