import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup} from '@angular/forms';

@Component({
  selector: 'sp-portal-personal-popup',
  templateUrl: './personal-popup.component.html',
  styleUrls: ['./personal-popup.component.scss']
})
export class PersonalPopupComponent {
  @Input() popupActive: boolean;
  @Input() formGroup: FormGroup;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  @Output() private onClosePopup = new EventEmitter<string>();
  formHasChanged: boolean;

  constructor(){}

  public saveChanges(){
    let postalCode = this.formGroup.get('postalCode');
    postalCode.setValue(postalCode.value.toUpperCase());
    if (this.formGroup.valid) {
      this.onFormGroupChange.emit(this.formGroup.value);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  close(){
    this.onClosePopup.emit('personalPopupActive');
  }
}
