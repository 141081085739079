<sp-onboard-page-content-wrapper
  [footerConfig]="footerConfig"
  [displayFooter]="!!confirmedKvk"
  [showStepper]="true"
  [stepperConfig]="{currentStep: stepper, onboardingType: (onboardingType$ | async)}">
  <p class="introduction">
    Wat is je KVK-nummer?
  </p>
  <form [formGroup]="companyForm" #form='ngForm'>
    <sp-input-wrapper
      class="kvkInput"
      [label]="'KVK-nummer'"
      [error]="form.submitted ? this.companyForm.get('kvk').errors : null">
      <input class="kvkInput" formControlName="kvk" type="text" pattern="[0-9]*">
    </sp-input-wrapper>
    <div class="loading-container" *ngIf="awaitingServerResponse">
      <p>Opvragen...</p>
    </div>
    <div class="no-company-container" *ngIf="showNoCompanyFound">
      <p>Dit lijkt geen geldig KVK-nummer. Probeer het nog een keer.</p>
    </div>
    <button (click)="submitKvk()" [disabled]="confirmedKvk || awaitingServerResponse" [sp-button] class="checkButton">
      Zoeken
    </button>
    <ng-container *ngIf="confirmedKvk">
      <section class="company-details">
        <sp-input-wrapper
          class="company-name"
          [error]="form.submitted ? this.companyForm.get('companyName').errors : null">
          <input formControlName="companyName" type="text" placeholder="Voer de bedrijfsnaam in">
        </sp-input-wrapper>
      </section>
    </ng-container>
  </form>
</sp-onboard-page-content-wrapper>



