<div class="popup-background" [class.active]="occupationPopupActive">
  <div class="popup-container">
      <div class="flex-container">
        <div class="info">
          <h2>Wat is je beroep?</h2>
        </div>
        <form [formGroup]="occupationForm">
          <div class="form-options">
            <div class="dropdown">
              <sp-input-wrapper>
                <input
                  type="text"
                  placeholder="Selecteer beroep"
                  formControlName="occupation"
                  [matAutocomplete]="auto">
              </sp-input-wrapper>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOccupationTitle">
                <mat-option *ngFor="let occupation of filteredOccupations | async" [value]="occupation">
                  {{occupation.name}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div class="buttons-container">
            <button type="button" [sp-button] (click)="skipPopUp()">Ik doe dit later</button>
            <button type="button" [sp-button] (click)="submitOccupation()" [disabled]="disableSubmitBtn">Doorgaan</button>
          </div>
        </form>
      </div>
  </div>
</div>

