<sp-portal-page-wrapper [title]="kycSectionKey | prettyKey">
  <ng-container *ngIf="kycSectionForm">
    <form [formGroup]="kycSectionForm" #form='ngForm'>
      <ng-container *ngFor="let item of kycSectionData$ | async | keyvalue">
        <sp-input-wrapper
          [label]="item.key | prettyKey"
          [error]="form.submitted ? this.kycSectionForm.get(item.key).errors : null">
          <input [formControlName]="item.key" type="text" >
        </sp-input-wrapper>
      </ng-container>
      <button (click)="submit()" [sp-button]>Doorgaan</button>
    </form>
  </ng-container>

</sp-portal-page-wrapper>
