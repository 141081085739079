import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DonationService} from '../donation.service';

export interface Contribution {
  payeeName: string;
  contributionAmount: number; // cent
  contributionToken: string;
}

@Component({
  selector: 'sp-portal-make-donation',
  templateUrl: './make-contribution.component.html',
  styleUrls: ['./make-contribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MakeContributionComponent {

  @Input() contributionDetails: Contribution;
  @Input() hasMissedContributions: boolean;

  constructor(
    private donationService: DonationService
  ) {}

  gotoPayment() {
    if (!this.contributionDetails) {
      throw new Error('No donation details available');
    }
    this.donationService.makeContribution(this.contributionDetails);

  }
}
