import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
  {
    path: 'registration',
    loadChildren: () => {return import('./registration-module/registration.module').then(m => {return m.RegistrationModule;});},
  },
  {
    path: 'dashboard',
    loadChildren: () => {return import('./my-area-module/my-area.module').then(m => {return m.MyAreaModule;});},
  },
  {
    path: 'donation',
    loadChildren: () => {return import('./donation/donation.module').then(m => {return m.DonationModule;});},
  },
  {
    path: 'kyc',
    loadChildren: () => {return import('./kyc/kyc.module').then(m => {return m.KycModule;});},
  },
  {
    path: 'camera',
    loadChildren: () => {return import('./camera/camera.module').then(m => {return m.CameraModule;});},
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    // { enableTracing: true }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
