import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentBlockData, ISelfTest, MemberData} from './dashboard/dashboard.component';
import {environment} from '../../environments/environment';
import {ISelfTestResponse, UserProfile} from './profile/profile.component';
import {Occupation} from './dashboard/occupation-popup/occupation-popup.component';

@Injectable({
  providedIn: 'root'
})
export class MyAreaRestService {

  constructor(
    private http: HttpClient
  ) { }

  getMemberData(): Observable<MemberData> {
    return this.http.get<MemberData>(`${environment.api_url}/member/memberData`);
  }

  getContributions(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/member/contributions`);
  }

  sendMemberInvite(email, name) {
    return this.http.post(`${environment.api_url}/member/inviteFriend`, {email, invitedName: name});
  }

  getMemberProfile() {
    return this.http.get<UserProfile>(`${environment.api_url}/member/profileData`);
  }

  getSelfTestLists() {
    return this.http.get<ISelfTest[]>(`${environment.api_url}/selftest/list`);
  }

  calculateResultTrigger() {
    return this.http.get<any>(`${environment.api_url}/selftest/calculate`);
  }

  getProductCoverages() {
    return this.http.get<any>(`${environment.api_url}/member/productCoverages`);
  }

  // FIXME: This data should be included in getProductCoverages (spLongInvitationCode can be removed)
  getCoverageAndCost(spLongInvitationCode: string): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/signuplong/calcPrice/${spLongInvitationCode ? spLongInvitationCode:''}`);
  }

  postUpdatedCoverageLevel(amount: number, longAmount: number) {
    return this.http.post<number>(`${environment.api_url}/member/updateCoverage`, {coverage: amount, longCoverage:longAmount });
  }

  updateProfile(newSettings) {
    return this.http.put(`${environment.api_url}/member/updateProfile`, newSettings);
  }

  postEyevestorSignUp() {
    return this.http.post(`${environment.api_url}/member/eyevestorSignUp`, {});
  }

  startSelfTest() {
    return this.http.get<ISelfTestResponse>(`${environment.api_url}/selftest/start`);
  }

  getCustomContentData(): Observable<ContentBlockData> {
    return this.http.get<any>(`${environment.api_url}/member/customContentData`);
  }

  getPromiseInfo() {
    return this.http.get(`${environment.api_url}/member/getPromiseInfo`);
  }

  downloadPromise() {
    const headers = new HttpHeaders({
      'Content-Type':  'application/pdf',
      responseType : 'blob',
      Accept : 'application/pdf',
      observe : 'response'
    });

    return this.http.get(`${environment.api_url}/member/downloadPromise`, {headers: headers, responseType: 'blob'});
  }

  acceptNewPromise() {
    return this.http.post(`${environment.api_url}/member/acceptNewPromise`, {});
  }

  getPromiseHTML(stopLong) {
    return this.http.get(`${environment.api_url}/member/getNewPromise/${stopLong ? 'stopLong':' '}`);
  }

  getOccupations() {
    return this.http.get<Occupation[]>(`${environment.api_url}/member/occupations`);
  }

  sendOccupation(occupationId) {
    return this.http.post(`${environment.api_url}/member/occupation`, {occupationId});
  }

  reportSickness(form) {
    return this.http.post(`${environment.api_url}/member/sickness-report`,form);
  }

  getExtendedCoverageOptions() {
    return this.http.get(`${environment.api_url}/member/extendedCoverage`);
  }

  selectExtendedCoverage(optionId) {
    return this.http.post(`${environment.api_url}/member/extendedCoverage`, {optionId});
  }
}
