<sp-onboard-page-content-wrapper
  [footerConfig]="footerConfig"
  [btnDisabled]="!promiseAccepted || !TCAccepted"
  [showStepper]="true"
  [stepperConfig]="{currentStep: 3, onboardingType: 'PROSPECTS'}">

  <section class="introduction">
    <h4>Dit beloof je</h4>
    <p>
      Houd je niet van contracten met kleine lettertjes? Wij ook niet. Wel moet je een aantal dingen beloven om deel te
      nemen aan SharePeople Lang. Dit zijn onder andere de volgende punten:
    </p>
  </section>

  <section class="list">
    <ul>
      <li>Ik heb de vragen in deze onboarding naar waarheid ingevuld.</li>
      <li>Ik ben op dit moment 100% in staat mijn huidige beroep uit te oefenen en ik verwacht niet arbeidsongeschikt te worden.</li>
      <li>Mocht ik blijvend arbeidsongeschikt worden voor mijn huidige beroep, dan ga ik er alles aan doen om in een
        ander beroep te werken en/of te ondernemen, zodat ik in mijn eigen inkomen kan voorzien.
      </li>
      <li>Mocht ik bij blijvende arbeidsongeschiktheid op een andere manier in mijn inkomen kunnen voorzien
        (bijvoorbeeld door een uitkering van een inkomens- of schadeverzekering, sociale verzekering of bijstand) dan
        zal ik (deels) afzien van donaties.
      </li>
    </ul>
  </section>

  <section class="acceptance">
    <div class="term">
      <sp-input-wrapper>
        <input type="checkbox" #accepted [(ngModel)]="promiseAccepted" id="promise">
      </sp-input-wrapper>
      <label class="agreement" for="promise">
        Ik accepteer de volledige
        <a (click)="getPromiseOverlay($event)">
          Verklaring & Beloftes
        </a>
      </label>
    </div>
    <div class="term">
      <sp-input-wrapper>
        <input type="checkbox" #accepted [(ngModel)]="TCAccepted" id="tac">
      </sp-input-wrapper>
      <label class="agreement" for="tac">
        Ik accepteer het
        <a (click)="getPrivacyOverlay($event)">privacybeleid</a>
        en de
        <a (click)="getTermsOverlay($event)">algemene voorwaarden</a>
      </label>
    </div>
  </section>

<!--  <footer>-->
<!--    <button sp-button [disabled]="!promiseAccepted || !TCAccepted" (click)="submit()">Ga verder</button>-->
<!--  </footer>-->
  <sp-tc-overlay></sp-tc-overlay>
</sp-onboard-page-content-wrapper>
