<ng-container>
  <section class="contribution-header">
    <h1>Ziekmelding</h1>

<!--    <sp-shared-lib-rounded-container shadow class="donation-tile-month average-donation" *ngIf="donationHistory?.average !== null">-->
<!--      <div>-->
<!--        <p class="amount">&#8364; {{donationHistory.average}}</p>-->
<!--        <p class="month">Gemiddeld per maand (sinds {{donationHistory.registrationCompletedDate}}) </p>-->
<!--      </div>-->
<!--    </sp-shared-lib-rounded-container>-->

  </section>
  <p *ngIf="!submitSuccessful && !submitFail">Na het invullen van dit formulier zal Capability de begeleiding opstarten.
    Een medewerker van Capability zal binnenkort contact opnemen met als doel de benodigde re-integratie activiteiten door te spreken en uit te gaan voeren.
    Mochten er in de tussentijd vragen en/of opmerkingen zijn, verzoeken wij je, na het invullen en verzenden van dit formulier, contact op te nemen met het serviceteam van Capability via telefoonnummer 088-0450100.
    Voor algemene vragen die geen verband houden met je ziekte kun je contact opnemen met SharePeople.</p>
  <br/>
  <sp-shared-lib-rounded-container class="member-data">
  <ul class="address-info">
    <li>{{fullName}}</li>
    <li><span class="label">Date of birth:</span> {{dob | date}}</li>
    <li><span class="label">mail:</span> {{email}}</li>
    <li><span class="label">SharePeople deelnemernummer:</span> {{memberCode}}</li>
  </ul>
  </sp-shared-lib-rounded-container>
  <br/>
  <form [formGroup]="reportSicknessForm" #form="ngForm" *ngIf="!submitSuccessful && !submitFail" (ngSubmit)="submitReport()">
    <sp-input-wrapper
      label="BSN *"
      [error]="form.submitted ? reportSicknessForm.get('bsn').errors : null" >
      <input type="text" formControlName="bsn">
    </sp-input-wrapper>
    <sp-input-wrapper
      class="sp-date-select"
      label="Datum 1e ziektedag *"
      [error]="form.submitted ? reportSicknessForm.get('startDate').errors : null" >
      <input type="date" formControlName="startDate">
    </sp-input-wrapper>
    <sp-input-wrapper
      label="Hoeveel uur per week werk je in jouw onderneming? *"
      [error]="form.submitted ? reportSicknessForm.get('hoursPerWeek').errors : null" >
      <input type="number" formControlName="hoursPerWeek" max="40">
    </sp-input-wrapper>
    <sp-input-wrapper
      label="Hoeveel uur per week werk je nog op dit moment? *"
      [error]="form.submitted ? reportSicknessForm.get('hoursCurrentPerWeek').errors : null" >
      <input type="number" formControlName="hoursCurrentPerWeek" max="40">
    </sp-input-wrapper>
    <sp-input-wrapper
      label="Wat is de verwachte verzuimduur (in maanden)?"
      [error]="form.submitted ? reportSicknessForm.get('absenceExpected').errors : null" >
      <input type="number" formControlName="absenceExpected">
    </sp-input-wrapper>

    <label class="label" [ngClass]="form.submitted && reportSicknessForm.get('industrialAccident').errors? 'error' : null">Is er sprake van een bedrijfsongeval? *</label>
    <sp-input-wrapper label="Ja">
      <input type="radio" formControlName="industrialAccident" value="true">
    </sp-input-wrapper>
    <sp-input-wrapper label="Nee">
      <input type="radio" formControlName="industrialAccident" value="false">
    </sp-input-wrapper>
    <br/>
    <label class="label" [ngClass]="form.submitted && reportSicknessForm.get('stressRelated').errors? 'error' : null">Kan er mogelijk sprake zijn van stressgerelateerde klachten of een burn-out? *</label>
    <sp-input-wrapper label="Ja">
      <input type="radio" formControlName="stressRelated" value="true">
    </sp-input-wrapper>
    <sp-input-wrapper label="Nee">
      <input type="radio" formControlName="stressRelated" value="false">No
    </sp-input-wrapper>
    <br/>
    <label class="label" [ngClass]="form.submitted && reportSicknessForm.get('aov').errors? 'error' : null">Heb je een AOV (naast SharePeople)?</label>
    <sp-input-wrapper label="Ja">
      <input type="radio" formControlName="aov" value="true">
    </sp-input-wrapper>
    <sp-input-wrapper label="Nee">
      <input type="radio" formControlName="aov" value="false">No
    </sp-input-wrapper>
    <br/>

    <sp-input-wrapper
      label="Zo ja, wat is de wachttijd?"
      [error]="form.submitted ? reportSicknessForm.get('aovWaitingTime').errors : null" >
      <input type="number" formControlName="aovWaitingTime">
    </sp-input-wrapper>

    <ng-container formGroupName="contact">

      <sp-input-wrapper
        [error]="form.submitted ? reportSicknessForm.get(['contact','person']).errors : null"
        label="Contactpersoon in noodgevallen">
        <input type="text" formControlName="person">
      </sp-input-wrapper>

      <sp-input-wrapper
        [error]="form.submitted ? reportSicknessForm.get(['contact','phone']).errors : null"
        label="Telefoonnummer contactpersoon">
        <input type="text" formControlName="phone">
      </sp-input-wrapper>

      <sp-input-wrapper
        [error]="form.submitted ? reportSicknessForm.get(['contact','email']).errors : null"
        label="Email adres contactpersoon">
        <input type="text" formControlName="email">
      </sp-input-wrapper>
    </ng-container>

<!--    <p class="error-message" *ngIf="displayMemberInviteError">Probeer het a.u.b. opnieuw.</p>-->
    <button [sp-button] type="submit">Versturen</button>
  </form>
    <sp-shared-lib-rounded-container *ngIf="submitSuccessful" class="invite-a-friend">
      <h4>Bedankt!</h4>
        <p>Een medewerker van Capability zal binnenkort contact opnemen met als doel de benodigde re-integratie activiteiten door te spreken en uit te gaan voeren.
        Mochten er in de tussentijd vragen en/of opmerkingen zijn, verzoeken wij je contact op te nemen met het serviceteam van Capability via telefoonnummer 088-0450100.
        Voor algemene vragen die geen verband houden met je ziekte kun je contact opnemen met SharePeople.
      </p>
      <button [sp-button] [routerLink]="'dashboard'">Dashboard</button>
    </sp-shared-lib-rounded-container>
    <sp-shared-lib-rounded-container *ngIf="submitFail" class="invite-a-friend">
      <h4>Er is iets misgegaan</h4>
      <p class="error-message">{{submitFailMessage}}
      </p>
    </sp-shared-lib-rounded-container>
</ng-container>
