<sp-onboard-page-content-wrapper
  [footerConfig]="footerConfig"
  [btnDisabled]="!termsAccepted || createRequestProcessing"
  [showStepper]="true"
  [stepperConfig]="{currentStep: stepper, onboardingType: (onboardingType$ | async)}">
  <section class="introduction">
    <h4>
      Je aanmelding voor SharePeople is bijna gelukt!
    </h4>
    <br/><br/>
    <h5>Eénmalige inschrijfkosten</h5>
    <p>
      Rond je aanmelding af door de &eacute;&eacute;nmalige inschrijfkosten van {{(+confirmationInfo?.amount | spCurrency)}}
      (= {{confirmationInfo?.amountIncVat | spCurrency}} incl. BTW) te betalen.
    </p>
    <p *ngIf="confirmationInfo?.discount > 0">De speciale korting van {{confirmationInfo.discount | spCurrency}} is hier
      al in verwerkt.</p>
    <br/><br/>
    <h5>Maandelijkse deelnamekosten</h5>
    <form [formGroup]="confirmationForm">
      <div class="checkbox-wrapper">
        <sp-input-wrapper class="checkbox">
          <input type="checkbox" formControlName="termsAccepted" name="termsAccepted">
        </sp-input-wrapper>
        <label (click)="displayTerms()">
          Ik ga akkoord dat SharePeople elke maand {{(confirmationInfo?.monthlyMembershipFee | spCurrency)}}
          (= {{(confirmationInfo?.monthlyMembershipFeeIncVAT | spCurrency)}} incl. BTW) incasseert van mijn zakelijke
          rekening.
        </label>
      </div>
    </form>
    <div class="conditions-container" *ngIf="displayCheckboxTerms">

      <div class="conditions">
        <h1>Doorlopende SEPA machtiging</h1>
        <p>Hierbij geef je toestemming:</p>
        <p>
          • Aan SharePeople B.V. om doorlopende incasso-opdrachten te sturen naar jouw bank om een bedrag van jouw
          rekening af te schrijven wegens de deelnamekosten van het SharePeople platform en
        </p>
        <p>
          • Aan jouw bank om doorlopend een bedrag van jouw rekening af te schrijven overeenkomstig de opdracht van
          SharePeople B.V.
          De bankrekening is dezelfde die je gaat gebruiken om hierna de inschrijfkosten te voldoen.
        </p>
        <p>
          Als je het niet eens ben met deze afschrijving kan je deze laten terugboeken. Je neemt hiervoor binnen acht
          weken na afschrijving contact op met jouw bank.
          De incassant is SharePeople B.V. (Incassant ID NL32ZZZ678828110000).
          Het kenmerk van de machtiging is DM-{{confirmationInfo.memberCode}}.
        </p>
      </div>
    </div>
  </section>

  <section class="benefit-list" *ngIf="(this.onboardingType$ | async) == 'LONG'">
    <h4>Hier teken je toch voor?</h4>
    <ul class="checkmark-list">
      <li>Inkomen bij arbeidsongeschiktheid</li>
      <li>Maandelijks opzegbaar</li>
      <li>Eerste maand gratis</li>
    </ul>
  </section>

<!--  <footer>-->
<!--    <button [sp-button] (click)="submit()" class="subscribe-button"-->
<!--            [class.inactive]="!termsAccepted || createRequestProcessing">Betaal inschrijfkosten-->
<!--    </button>-->
<!--  </footer>-->
</sp-onboard-page-content-wrapper>
