import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, delay, filter, mergeMap, retryWhen, takeUntil} from 'rxjs/operators';
import {DonationService} from '../donation.service';
import {
  OnboardingType,
  OnboardingTypeService
} from '../../../../../onboarding/src/app/core-module/services/onboarding-type.service';
import {OnboardingService} from '../../../../../onboarding/src/app/onboarding-module/onboarding.service';

export interface PaymentStatus {
  // type based on https://docs.mollie.com/guides/webhooks > Payments API
  paymentStatus: ( 'cancelled' | 'expired' | 'failed' | 'paid' | 'open');
  // additional info:
  sickPersonName: string;
  wellWishes: boolean;
}

@Component({
  selector: 'sp-payment-processing',
  templateUrl: './processing-payment.component.html',
  styleUrls: ['./processing-payment.component.scss']
})
export class ProcessingPaymentComponent implements OnInit, OnDestroy {

  message = '';
  showWelWishes = false;
  messageButtonLink: string;
  private pollSubscription: Subscription;
  loading = true;
  paymentData;
  onboardingType$: Observable<any>;
  isSpLong: boolean;
  constructor(
    private donationService: DonationService,
    private router: Router,
    private route: ActivatedRoute,
    private onboardingTypeService: OnboardingTypeService,
    private onboardingService: OnboardingService,
  ) { }

  ngOnInit() {
    console.log('Donation processing-payment init()');
    const contributionToken = this.route.snapshot.queryParams.token;

    if (!contributionToken) {
      console.error('No contribution token in query params');
      this.router.navigate(['../dashboard']);
    }
    this.isSpLong = this.onboardingService.retrieveChosenCoverageType();
    this.pollPaymentService(contributionToken);
  }

  ngOnDestroy() {
    this.pollSubscription.unsubscribe();
  }

  pollPaymentService(contributionToken: string) {
    let  retries = 4;
    const delayTime = 3000;
    console.log('pollPaymentService: retries:'+ retries);
    this.pollSubscription = this.donationService.pollPaymentStatus(contributionToken)
      .pipe(
        retryWhen(
          (errors: Observable<any> ) => {return errors.pipe(
            delay(delayTime),
            mergeMap(error => {
              return retries -- > 0 ? of (error) : this.pollingTimeout();
            })
          );}
        ),
        delay(delayTime),
      ).subscribe(
        result => {
          this.paymentData = {
            sickPersonName: result.sickPersonName,
            contributionToken: contributionToken,
            wellWishes: result.wellWishes
          };
          this.handlePollingResponse(result, contributionToken);
        }
      );
  }

  handlePollingResponse(paymentResult: PaymentStatus, contributionToken?) {
    console.log('paymentResult:', paymentResult);
    switch (paymentResult.paymentStatus) {
      case 'paid':
        this.paymentPaid(paymentResult.wellWishes);
        break;
      case 'failed':
        this.paymentFailed();
        break;
      case 'cancelled':
        this.paymentCancelled();
        break;
      case 'open':
        setTimeout(() => {
          this.pollPaymentService(contributionToken);
        }, 2000);
        break;
      default:
        this.paymentFailed();
        break;
    }
  }

  pollingTimeout() {
    this.message = 'De betaling is mislukt. Probeer het nog een keer';
    console.log('Status: Timeout');
    // this.onboardingService.setCurrentStep('/confirmation');
    this.loading = false;
    this.messageButtonLink = '../../dashboard';
    return null;
  }

  paymentFailed() {
    this.message = 'De betaling is mislukt. Probeer het nog een keer';
    console.log('Status: Payment failed');
    // this.onboardingService.setCurrentStep('/confirmation');
    this.loading = false;
    this.messageButtonLink = '../../dashboard';
  }

  paymentCancelled() {
    this.message = 'De betaling is mislukt. Probeer het nog een keer';
    console.log('Status: Payment cancelled');
    // this.onboardingService.setCurrentStep('/confirmation');
    this.loading = false;
    this.messageButtonLink = '../../dashboard';
  }

  paymentPaid(wellWishes) {
    this.message = 'De betaling is gelukt';
    if(!wellWishes){ // if well wishes is false
      this.showWelWishes = true;
      this.loading = false;
      this.messageButtonLink = '../../dashboard';
    } else {
      this.router.navigate(['/donation/contribution-message'], {state: this.paymentData});
    }
  }
}
