import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../../../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'sp-title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.scss']
})
export class TitleHeaderComponent implements OnInit {
  media: Observable<any>;
  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.media = this.themeService.getLogo();
  }

}
