import {Injectable} from '@angular/core';
import {AuthCredentials} from './auth.model';
import {UserDetails} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clearAllCredentials() {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  storeAuthCredentials(credentials: AuthCredentials) {
    try {
      window.localStorage.setItem('authCredentials', JSON.stringify(credentials));
    } catch {
      throw new Error('Could not set local storage for credentials');
    }
  }

  retrieveAuthCredentials(): AuthCredentials {
    const credentials = window.localStorage.getItem('authCredentials');
    if (credentials) {
      return JSON.parse(credentials);
    }
  }

  setUserDetails(userDetails: UserDetails): void {
    try {
      window.localStorage.setItem('userDetails', JSON.stringify(userDetails));
    } catch {
      throw new Error('Could not set local storage for user details');
    }
  }

  getUserDetails(): any {
    const userDetails = window.localStorage.getItem('userDetails');
    if (userDetails) {
      return JSON.parse(userDetails);
    } else {
      return {access_token: null};
    }
  }
}
