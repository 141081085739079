import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {PaymentStatus} from '../../../../onboarding/src/app/shared-module/models/models';
import {DonationRestService} from './donation.rest.service';
import {Contribution} from './make-contribution/make-contribution.component';

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  constructor(
    private donationRest: DonationRestService
  ) { }

  getContributionLink(token: string) {
    return this.donationRest.getContributionUrl(token);
  }

  makeContribution(contributionDetails: Contribution) {
    this.getContributionLink(contributionDetails.contributionToken)
      .subscribe(
        ({paymentUrl}) => {
          console.log('payemnt rl', paymentUrl);
          window.location.href = paymentUrl;
        }
      );
  }

  pollPaymentStatus(contributionToken): Observable<PaymentStatus> {
    return this.donationRest.postPaymentStatus(contributionToken);
  }

  postContributionMessage(contributionToken, message) {
    return this.donationRest.postContributionMessage(contributionToken, message);
  }

}
