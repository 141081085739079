<div class="overlay-container" *ngIf="overlay">
  <div class="content-container">
    <div class="close-button" (click)="overlay=null">
      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
        <path
          d="M28.864 16.136a1.5 1.5 0 0 1 0 2.121L24.622 22.5l3.742 3.744a1.5 1.5 0 0 1-2.121 2.121L22.5 24.621l-4.244 4.243a1.5 1.5 0 0 1-2.121-2.121l4.243-4.244-4.743-4.742a1.5 1.5 0 0 1 2.121-2.121l4.744 4.742 4.242-4.242a1.5 1.5 0 0 1 2.121 0z"/>
      </svg>
    </div>
    <div [ngSwitch]="overlay.overlayType">

      <div *ngSwitchCase="'promise'">
        <div class="scroll-container">
          <div [innerHTML]="overlayContent"></div>
        </div>
      </div>


      <div *ngSwitchCase="'terms'">
        <div class="scroll-container">
          <h4 class="text-title">SharePeople b.v. Algemene Voorwaarden </h4>
          <div class="text-body">
            <h4>Algemeen</h4>
            <section>
              <h6>1)</h6>
              <p>
                Deze Algemene Voorwaarden zijn van toepassing, zodra de besloten SharePeople B.V.
                en/of haar werkmaatschappij of bestuurders en medewerkers, communiceren en
                faciliterend een relatie (zullen) aangaan. Alle bepalingen van deze voorwaarden zijn van
                kracht, tenzij en voor zover hier schriftelijk uitdrukkelijk van is afgeweken.
                Strekking
              </p>
            </section>
            <section>
              <h6>2)</h6>
              <p>
                a) De werkzaamheden van SharePeople betreffen het begeleiden van deelnemers in hun
                onderlinge contacten en het doen van donaties aan elkaar.
                <br>
                b) De relatie van SharePeople met wie eenmaal op basis van de onderhavige voorwaarden
                werd gecommuniceerd wordt geacht stilzwijgend akkoord te zijn gegaan met de
                toepasselijkheid van deze voorwaarden op later met SharePeople gesloten en of niet
                gesloten en of beëindigde overeenkomsten.
                Totstandkoming
              </p>
            </section>
            <section>
              <h6>3)</h6>
              <p>
                Een overeenkomst met een deelnemer komt tot stand op het moment dat SharePeople dit
                schriftelijk verklaart, dat kan ook per e-mail of andersoortig dataverkeer. De in voorgaande
                zin opgenomen regel geldt eveneens voor wijzigingen in overeenkomsten.
                Rechten en verplichtingen SharePeople
              <p>
            </section>
            <section>
              <h6>4)</h6>
              <p>
                a) SharePeople is gehouden de belangen van anderen waarmee zij een relatie aangaat
                naar beste weten en kunnen te behartigen, één en ander voor zover zulks, gelet op de
                rechtsverhouding en de daaruit voortvloeiende faciliterende werkzaamheden, mogelijk en
                wenselijk is.
                <br>
                b) Een resultaat van het faciliteren wordt niet gegarandeerd. Of het beoogde resultaat (het
                voorkomen van arbeidsongeschiktheid en het verzachten van de financiële gevolgen van
                arbeidsongeschiktheid) daadwerkelijk wordt bereikt is afhankelijk van tal van factoren.
                <br>
                c) Daar
                bij heeft SharePeople het recht om -in overleg met en na verkregen toestemming
                van de relatie/deelnemer van het platform -informatie met anderen te delen en bepaalde
                werkzaamheden te laten uitvoeren door derden of zich te laten bijstaan door derden.
                Weigert de deelnemer die informatie te verschaffen dan kan SharePeople haar
                werkzaamheden niet uitvoeren.
                Verplichtingen relatie, met name maar niet uitsluitend de deelnemers
              </p>
            </section>
            <section>
              <h6>5)</h6>
              <p>
                a) Op relaties en met name deelnemers rust de verplichting aan SharePeople tijdig,
                volledig en op de juiste wijze gegevens, inlichtingen en bescheiden te verstrekken welke
                benodigd zijn voor uitvoering van de faciliterende werkzaamheden.
                <br>
                b) Indien en voor zover tekortkomingen bij de uitvoering van de werkzaamheden verricht
                door SharePeople het gevolg zijn van niet, niet tijdig of niet in voldoende mate voldoen aan
                de op de relatie rustende (informatie) verplichting, is SharePeople daarvoor niet
                aansprakelijk.
                Vertrouwelijk

              </p>
            </section>
            <section>
              <h6>6)</h6>
              <p>
                Geheimhouding
                SharePeople verplicht zich alle gegevens en stukken, die betrekking hebben op de
                deelnemer(s), welke in redelijkheid hebben te gelden als vertrouwelijk en geheim, als
                zodanig te behandelen. SharePeople draagt er zorg voor -ook middels deze voorwaardendat deze
                verplichting ook wordt opgelegd aan eventuele relaties die door haar bij een
                opdracht worden ingeschakeld.
                Beëindiging
              </p>
            </section>
            <section>
              <h6>7)</h6>
              <p>
                a) Een deelnemer kan te allen tijde deelname beëindigen. SharePeople kan haar
                faciliterende werkzaamheden alleen beëindigen nadat een redelijke opzegtermijn in acht is
                genomen. Relaties die door SharePeople zijn ingeschakeld ten behoeve van deelnemers
                kunnen hun werkzaamheden niet dan na schriftelijke toestemming van de deelnemer
                beëindigen, omdat het hier gaat om begeleiding voor een beperkte periode van maximaal
                twee jaar bij arbeidsongeschiktheid, tenzij sprake is van onvoorziene omstandigheden of
                belangrijk gewijzigde omstandigheden.
                <br>
                b) Na het beëindigen van de relatie zullen alle data worden vernietigd dan wel tegen
                betaling in een zodanige staat terug worden gegeven dat de deelnemers c.q. de door
                SharePeople aan te wijzen derde, zonder schade voor de voortgang van die
                werkzaamheden, op aanvaardbare wijze verder uitvoerbaar is, met in achtneming van de in
                artikel 5 van deze voorwaarden bepaalde.
                <br>
                b) Een ieder kan een relatie met onmiddellijke ingang beëindigen indien de ander in
                surseance van betaling komt te verkeren of in staat van faillissement (of WSNP) is
                verklaard.
                Bepalingen van financiële aard
              </p>
            </section>
            <section>
              <h6>8)</h6>
              <p>
                a) Indien partijen niet schriftelijk anders zijn overeengekomen, wordt een aan SharePeople
                toekomende vergoeding (gebruikskosten en inschrijfkosten) voor de werkzaamheden van
                SharePeople vastgesteld aan de hand van een verantwoording van gemaakte kosten.
                <br>
                b) Alle honoraria zijn exclusief B.T.W.
                <br>
                c) Het overeengekomen honorarium dient te worden voldaan binnen 14 dagen na
                dagtekening van de opdrachtbevestiging.
                <br>
                d) Blijft naar aanleiding van een verzoek te betalen een deugdelijke reactie op dit verzoek
                uit, dan zal SharePeople gerechtigd zijn de overeenkomst te ontbinden. Eén en ander
                onverminderd het recht op bepaling van honorarium en vanwege de ontbinding
                toekomende schadevergoeding.
                Bepalingen m.b.t. beperkte aansprakelijkheid
              </p>
            </section>
            <section>
              <h6>9)</h6>
              <p>
                a) SharePeople is tegenover deelnemers slechts aansprakelijk voor schade ten gevolg van
                een ernstige toerekenbare tekortkoming in de uitvoering van de werkzaamheden en
                tegenover andere relaties in beginsel in het geheel niet aansprakelijk omdat SharePeople
                slechts faciliteert en niet zelf uitvoert.
                <br>
                b) Indien een rechter SharePeople toch aansprakelijk zou oordelen voor gelede schade, dan
                is haar aansprakelijkheid beperkt tot het bedrag dat in het voorkomende geval wordt
                uitgekeerd krachtens de door SharePeople afgesloten
                beroepsaansprakelijkheidsverzekering of andere aansprakelijkheidsverzekering,
                vermeerderd met het voor SharePeople geldende eigen risico, waarbij het totaal van deze
                bedragen is beperkt tot het maximumbedrag van de verzekering.
                <br>
                c) Indien, om welke reden dan ook, geen verzekeringsuitkering plaatsvindt, is de
                aansprakelijkheid van SharePeople beperkt tot het honorarium van haar werkzaamheden
                direct gerelateerd aan de (nagelaten) werkzaamheden waarop de aansprakelijkheid
                betrekking heeft, met een maximum van € 2.000,-.
                <br>
                d) SharePeople is niet gehouden tot vergoeding van indirecte schade geleden door
                deelnemers of relaties, waaronder begrepen maar niet beperkt tot gevolgschade, gederfde
                winst en schade ten gevolge van arbeidsongeschiktheid.
                <br>
                e) SharePeople zal bij de inschakeling van niet in haar organisatie werkzame derden (zoals
                adviseurs, deskundigen of dienstverleners) de nodige zorgvuldigheid in acht nemen.
                SharePeople is niet aansprakelijk voor ernstige tekortkomingen jegens deelnemers of voor
                eventuele fouten of tekortkomingen van deze derden. In zo’n geval is de deelnemer
                verplicht de ingeschakelde derden zelf aansprakelijk te stellen en eventueel geleden schade
                op deze derden te verhalen.
                <br>
                f) SharePeople is niet aansprakelijk voor door deelnemers of relatie geleden schade, van
                welke aard ook, indien bij het aangaan van de relatie of de uitvoering van de
                werkzaamheden is uitgegaan van door deelnemer of relatie verstrekte onjuiste en/of
                onvolledige gegevens.
                <br>
                g) Relatie vrijwaart SharePeople tegen alle aanspraken (zoals schades en
                rechtsvorderingen) van deelnemers of derden die met de relatie tussen SharePeople, de
                deelnemer en/of de relaties samenhangen, tenzij het betreft aanspraken ten gevolge van
                ernstige tekortkomingen van SharePeople.
                <br>
                h) Indien een relatie of deelnemer een eventuele vordering jegens SharePeople niet binnen
                1 jaar na het ontdekken van de schade in rechts aanhangig heeft gemaakt, komt deze
                rechtsvordering na het verstrijken van het jaar te vervallen.
              </p>
            </section>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'privacy'">
        <div class="scroll-container">
          <h2>Privacyverklaring</h2><br/>
          <div class="text-body">
            <section>
              <h6>PRIVACYVERKLARING</h6>
              <p>SharePeople, gevestigd aan Vondellaan 34, 3521 GH Utrecht, is verantwoordelijk voor de verwerking van
                persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
              <br/>
              <p><i>Contactgegevens:</i></p>
              <ul class="address-info">
                <li>SharePeople B.V.</li>
                <li>Vondellaan 34</li>
                <li>3521 GH Utrecht</li>
                <li>030-2080802</li>
                <li>www.sharepeople.nl</li>
              </ul>
              <br/>
              <p>Rutger Abbink is de Functionaris Gegevensbescherming van SharePeople. Hij is te bereiken via
                contact&#64;sharepeople.nl.</p>
            </section>
            <section>
              <h6>Persoonsgegevens die wij verwerken</h6>
              <p>SharePeople verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze
                gegevens zelf aan ons verstrekt.</p>
              <p>Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:</p>
              <ul>
                <li>Voor- en achternaam</li>
                <li>Voorletters</li>
                <li>Geslacht</li>
                <li>Geboortedatum</li>
                <li>Adresgegevens</li>
                <li>Telefoonnummer</li>
                <li>E-mailadres</li>
                <li>Bankrekeningnummer</li>
                <li>Bedrijfsnaam en beroep</li>
              </ul>
            </section>
            <section>
              <h6>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h6>
              <p>Onze website en diensten hebben niet de intentie gegevens te verzamelen over website bezoekers die
                jonger zijn dan 16 jaar. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Als je er
                van overtuigd bent dat wij persoonlijke gegevens hebben verzameld over een minderjarige, neem dan
                contact met ons op via contact&#64;sharepeople.nl, dan verwijderen wij deze informatie.</p>
            </section>
            <section>
              <h6>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h6>
              <p>SharePeople verwerkt jouw persoonsgegevens voor de volgende doelen:</p>
              <ul>
                <li>Verzenden van onze nieuwsbrief</li>
                <li>Het uitvoeren van onze dienstverlening</li>
                <li>Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren
                </li>
                <li>Je te informeren over wijzigingen van onze diensten en producten</li>
              </ul>
            </section>
            <section>
              <h6>Geautomatiseerde besluitvorming</h6>
              <p>SharePeople neemt geen besluiten op basis van geautomatiseerde verwerkingen over zaken die
                (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door
                computerprogramma’s of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van SharePeople)
                tussen zit.</p>
            </section>
            <section>
              <h6>Hoe lang we persoonsgegevens bewaren</h6>
              <p>SharePeople bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren
                waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende
                (categorieën) van persoonsgegevens:</p>
              <br/>
              <p><i>Potentiele deelnemers:</i></p>
              <p>Naam en email adres worden bewaard en geactualiseerd zolang de potentiele deelnemer zich niet
                uitschrijft.</p>
              <br/>
              <p><i>Deelnemers:</i></p>
              <p>Alle door de deelnemer verstrekte gegevens worden bewaard en geactualiseerd zolang de deelnemer als
                zodanig bij SharePeople geregistreerd is. Na deelname worden alleen naam en email adres bewaard, zolang
                de ex-deelnemer zicht niet uitschrijft.</p>
            </section>
            <section>
              <h6>Delen van persoonsgegevens met derden</h6>
              <p>SharePeople verstrekt deze uitsluitend aan derden als dit noodzakelijk is voor de uitvoering van onze
                diensten of om te voldoen aan een wettelijke verplichting.</p>
              <p>Specifiek deelt SharePeople persoonsgegevens van <u>deelnemers</u> met de volgende strategische
                partners:</p><br/>
              <ul style="color: #595e70;">
                <li>Payaut: voor de betaling van de donaties aan arbeidsongeschikte deelnemers;</li>
                <li>BrightPensioen: voor de uitvoering van het product SharePeople Bright en</li>
                <li>Knab bank: voor de uitvoering van het product Knab Crowdsurance</li>
                <li>Capability: (alleen zodra iemand zich ziek meldt) voor de begeleiding van arbeidsongeschikte
                  deelnemers
                </li>
              </ul><br/>
              <p>Deze persoonsgegevens worden slechts gedeeld met deze partners om hun diensten te verbeteren, relevante
                aanbevelingen te doen en om ervoor te zorgen dat geen aanbiedingen meer gedaan worden voor het product
                dat reeds is afgesloten.</p>
              <p>SharePeople zal nooit persoonsgegevens aan derden verstrekken voor commerciële doeleinden.</p>
            </section>
            <section>
              <h6>Cookies, of vergelijkbare technieken, die wij gebruiken</h6>
              <p>SharePeople gebruikt cookies om content en advertenties te personaliseren, om functies voor social
                media te bieden en om ons websiteverkeer te analyseren. Ook delen we informatie over het gebruik van
                onze site met onze partners voor social media, adverteren en analyse. Deze partners kunnen deze gegevens
                combineren met andere informatie die je aan ze hebt verstrekt of die ze hebben verzameld op basis van
                jouw gebruik van hun services. Je gaat akkoord met onze cookies als je onze website blijft
                gebruiken.</p>
            </section>
            <section>
              <h6>Gegevens inzien, aanpassen of verwijderen</h6>
              <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je
                het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen
                de verwerking van jouw persoonsgegevens door SharePeople en heb je het recht op
                gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om jouw persoonsgegevens
                waarover wij beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te
                sturen.<br/>
                Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of
                verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen
                naar contact&#64;sharepeople.nl.</p>
              <br/>
              <p>Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je
                identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable
                zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart.
                Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw
                verzoek.<br/>
                SharePeople wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de
                nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: <a
                  href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
                  target="_blank" style="border:none;color:#46b278;">https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</a>.
              </p>
            </section>
            <section>
              <h6>Hoe wij persoonsgegevens beveiligen</h6>
              <p>SharePeople neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik,
                verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij
                het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem
                dan contact op met onze klantenservice of via contact&#64;sharepeople.nl</p>
            </section>
            <section>
              <h6>AFWIJZING VAN AANSPRAKELIJKHEID</h6>
              <p>Ondanks voortdurende zorg en aandacht voor de website van SharePeople geeft SharePeople geen garanties
                op de volledigheid, juistheid of actualiteit van de inhoud van de website van SharePeople.
                <br/>SharePeople kan nooit aansprakelijk worden gesteld voor directe of indirecte schade door het
                gebruiken van de website van SharePeople, de onmogelijkheid de website van SharePeople te gebruiken, uit
                de levering van diensten door SharePeople of het gebrek aan levering van diensten door SharePeople.
              </p>
            </section>
            <section>
              <h6>WIJZIGINGEN</h6>
              <p>De inhoud van de website van SharePeople wordt regelmatig bijgewerkt. SharePeople behoudt zich het
                recht voor de inhoud van de website van SharePeople te allen tijde en met onmiddellijke ingang te
                wijzigen zonder voorafgaande kennisgeving.</p>
              <br/>
              <p>Laatst gewijzigd op 07-12-2021.</p>
            </section>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>












