<div class="accordion">
  <div class="accordion__container">
  <div class="accordion__header" (click)="toggleAccordion()" >
    <h4>{{themeName}}</h4>

    <div class="accordion__score-bar">
      <div class="accordion__score" *ngFor="let score  of scoreGroup; let i = index">
        <div class="accordion__score" [ngClass]="score.color"  ></div>
        <div *ngIf="score.isActive" class="accordion__score--triangle" ></div>
      </div>
      <img class="accordion__icon" src="./assets/icons/chevron-down.png" [class.accordion__icon--rotated]="collapsed"  />
    </div>
  </div>
  <ng-template [ngIf]="collapsed">
    <div class="accordion__main-text">
      <h3>Score: {{getDecimalScoreString(score)}}</h3>
      <div [innerHTML]="sanitizer.sanitize(1,score < '8.2' ? themeRecord[themeName].badScoreText : themeRecord[themeName].goodScoreText)" ></div>
    </div>
  </ng-template>
  </div>
</div>

