import { Pipe, PipeTransform } from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';

@Pipe({
  name: 'spCurrency'
})
export class SpCurrencyPipe implements PipeTransform {

  transform(value: number | string): string | null {
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }
    value /= 100;
    return formatCurrency(
      value,
      'nl',
      getCurrencySymbol('EUR', 'narrow'),
      'EUR',
      '1.2-2',
    );
  }

}
