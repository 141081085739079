import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoginDetails} from '../../../../onboarding/src/app/shared-module/models/models';
import {AuthCredentials, AuthCredentialsResponse} from './auth.model';
import {StorageService} from './storage.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  login(loginDetails: LoginDetails): Observable<AuthCredentialsResponse> {
    return this.http.post<AuthCredentialsResponse>(`${environment.auth_url}/login`, loginDetails)
      .pipe(
        tap(authCredentialsResponse => {
          this.setAuthCredentialsResponse(authCredentialsResponse);
        })
      );
  }

  logout(): void {
    this.storageService.clearAllCredentials();
    window.location.href = `${environment.login_url}`;
  }

  private decorateAuthCredentials(userDetails: AuthCredentialsResponse): AuthCredentials {
    const expiresAt = new Date().getTime() + userDetails.expires_in;
    return {...userDetails, expires_at: expiresAt};
  }

  isLoggedIn(): boolean {
    const tokenExpiryTime = this.storageService.retrieveAuthCredentials().expires_at;
    return tokenExpiryTime < new Date().getTime();
  }

  refreshAccessToken(): Observable<AuthCredentialsResponse> {
    const refreshToken = this.getRefreshToken();
    return this.http.post<AuthCredentialsResponse>(
      `${environment.refresh_url}/access_token`,
      `grant_type=refresh_token&refresh_token=${refreshToken}`,
      {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
    );
  }

  setAuthCredentialsResponse(authCredentialsResponse: AuthCredentialsResponse) {
    const authCredentials = this.decorateAuthCredentials(authCredentialsResponse);
    this.storageService.storeAuthCredentials(authCredentials);
  }

  getAuth(): AuthCredentials {
    return this.storageService.retrieveAuthCredentials();
  }

  getAccessToken(): string {
    if (this.getAuth()) {
      return this.getAuth().access_token;
    }
  }

  getRefreshToken(): string {
    if (this.getAuth()) {
      return this.getAuth().refresh_token;
    }
  }

  // This method should be customised based on the SpringSecurityRest plugin configuration.
  isRefreshUrl(url: string) {
    return url.includes('oauth') || url.includes('login');
  }
}
