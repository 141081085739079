<sp-portal-page-wrapper title="Persoonlijke gegevens">
  <form [formGroup]="personalDetailsForm" #form='ngForm'>

    <sp-input-wrapper
      [label]="'firstName' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('firstName').errors : null">
      <input formControlName="firstName" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'lastName' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('lastName').errors : null">
      <input formControlName="lastName" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'Legal form'"
      [error]="form.submitted ? this.personalDetailsForm.get('legalForm').errors : null">
      <input formControlName="legalForm" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'phone' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('phone').errors : null">
      <input formControlName="phone" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'gender' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('gender').errors : null">
      <input formControlName="gender" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'street' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('street').errors : null">
      <input formControlName="street" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'dateOfBirth' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('dateOfBirth').errors : null">
      <input formControlName="dateOfBirth" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'zipcode' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('zipcode').errors : null">
      <input formControlName="zipcode" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'houseNumber' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('houseNumber').errors : null">
      <input formControlName="houseNumber" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'city' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('city').errors : null">
      <input formControlName="city" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'placeOfBirth' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('placeOfBirth').errors : null">
      <input formControlName="placeOfBirth" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'nationality' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('nationality').errors : null">
      <input formControlName="nationality" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'addition' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('addition').errors : null">
      <input formControlName="addition" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'country' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('country').errors : null">
      <input formControlName="country" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'email' | prettyKey"
      [error]="form.submitted ? this.personalDetailsForm.get('email').errors : null">
      <input formControlName="email" type="text" >
    </sp-input-wrapper>

    <button (click)="submit()" [sp-button]>Doorgaan</button>
  </form>
</sp-portal-page-wrapper>
