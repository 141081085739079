<section class="introduction">
  <h1>Wachtwoord vergeten</h1>
</section>

<section class="login-form">
  <form [formGroup]="forgotPasswordForm" #form="ngForm">
    <sp-input-wrapper
      label="E-mailadres"
      [error]="form.submitted ? forgotPasswordForm.get('email').errors : null" >
      <input type="email" formControlName="email">
    </sp-input-wrapper>
    <button [sp-button] (click)="submit()" >Wachtwoord herstellen</button>
  </form>
</section>
