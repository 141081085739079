import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {BehaviorSubject, forkJoin, Observable, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {MemberData} from '../dashboard/dashboard.component';
import {MyAreaRestService} from '../my-area.rest.service';
import {Router} from '@angular/router';
import {MyAreaService} from '../my-area.service';
import {TcOverlayService} from '../../shared-module/tc-overlay/tc-overlay.service';
import {environment} from '../../../environments/environment';
import {SpCurrencyPipe} from 'sp-shared-lib';


export interface OverlayConfig {
  overlayType: string;
  overlayContent: string; // HTML
}
interface ICostAndCoverage {
  coverage: number;
  spLong: [
    {
      coverage: number;
      cost: number;
    }
  ];
}
@Component({
  selector: 'sp-portal-change-contribution',
  templateUrl: './change-contribution.component.html',
  styleUrls: ['./change-contribution.component.scss']
})


export class ChangeContributionComponent implements OnInit {
  // memberData$: BehaviorSubject<MemberData | null> = new BehaviorSubject<MemberData | null>(null);
  spLongJoined: boolean;
  subscription: boolean;
  availableCoverages;
  availableLongCoverages;
  currentCoverage$: Observable<number>;
  currentCoverage;
  coverageForm: UntypedFormGroup;
  currentLongCoverage;
  costAndLongCoverage;
  showTermsCheckBox = false;
  enableButton = false;
  showPromiseLong = false;
  noAOV = true;
  memberCode = '';
  constructor(
    private restService: MyAreaRestService,
    private myAreaService: MyAreaService,
    private tcOverlayService: TcOverlayService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.restService.getProductCoverages().pipe().subscribe((data) => {
      this.availableCoverages =  data.coverages.sort((a, b) => {return a - b;});
      this.availableLongCoverages =  data.coverageOptions.sort((a, b) => {return a - b;});
    });

    this.setupForm();
    this.myAreaService.memberData$.pipe(take(1)).subscribe(memberData => {

      this.currentCoverage = memberData.coverage;
      this.currentLongCoverage = memberData.spLongCoverage;
      this.spLongJoined = memberData.spLongJoined;
      this.subscription = memberData.aov.subscription;

      this.memberCode = memberData.memberCode;
      this.noAOV = memberData.spLongCoverage == 50000;
    });

    this.setupForm();
  }

  private setupForm(): void {
    this.coverageForm = this.formBuilder.group({
      amount: [this.currentCoverage, Validators.required],
      longAmount: [this.currentLongCoverage],
      termsAccepted: [false],
      feeAccepted: [false],
    });
    this.coverageForm.reset();
    // handling validations and enabling/disabling the terms checkbox and 'wijzingen' btn:
    this.coverageForm.valueChanges.subscribe(input => {
      if (this.selectedHigher(input)) {
        this.enableButton = this.canEnableButton();
        this.showTermsCheckBox = true;
        this.coverageForm.get('termsAccepted').setValidators([Validators.required, CustomValidators.valueIsTrue]);
        if(!this.noAOV) {
          this.coverageForm.get('feeAccepted').setValidators([Validators.required, CustomValidators.valueIsTrue]);
        } else {
          this.coverageForm.get('feeAccepted').setValidators([]);
        }
      } else if (this.selectedCurrent(input)){
        this.enableButton = false;
        this.showTermsCheckBox = false;
      } else {
        this.enableButton = true;
        this.showTermsCheckBox = false;
        this.coverageForm.get('termsAccepted').setValidators([]);
        this.coverageForm.get('feeAccepted').setValidators([]);
      }
    });

    this.coverageForm.get('amount').setValue(this.currentCoverage);
    this.coverageForm.get('longAmount').setValue(this.currentLongCoverage);

  }

  selectedCurrent(input){
    return input.amount && input.amount == this.currentCoverage && (input.longAmount!=null?input.longAmount && input.longAmount == this.currentLongCoverage:true);
  }

  selectedHigher(input){
    return input.amount && input.amount > this.currentCoverage || input.longAmount && input.longAmount > this.currentLongCoverage;
  }
  canEnableButton(){
    if(!this.noAOV) {
      return this.coverageForm.get('termsAccepted').value === true && this.coverageForm.get('feeAccepted').value === true;
    } else {
      return this.coverageForm.get('termsAccepted').value === true
    }
  }

  openOverlay(overlay: OverlayConfig) {
    this.tcOverlayService._overlay.next(overlay);
  }

  getPromiseOverlay() {
    const stopLong = this.coverageForm.get('longAmount').value==0?true:false;
    console.log(this.coverageForm.get('longAmount').value);
    this.myAreaService.getPromiseHTML(stopLong)
      .subscribe(
        (promiseHTML: any) => {
          this.openOverlay({
            overlayType: 'promise',
            overlayContent: promiseHTML.promise
          });
        }
      );
  }

  resetCheckboxes() {
    const input = {
      amount: this.coverageForm.get('amount').value,
      longAmount: this.coverageForm.get('longAmount').value,
    };
    return !this.selectedHigher(input);
  }

  navigateAOV(){
      this.updateCoverageLevel(true);
  }

  updateCoverageLevel(updateAOV) {
    this.coverageForm.updateValueAndValidity();
    if (this.resetCheckboxes()) {
      // FIXME: If the user checks and then unchecks and chooses a lower amount the form wil be NOT valid.
      //  This is unexpected because the validators have been removed.
      this.coverageForm.get('termsAccepted').setValue(null);
      this.coverageForm.get('feeAccepted').setValue(null);
    }
    if (this.coverageForm.valid) {
      if (window.confirm('Weet je het zeker? ')) {
        const chosenAmount = this.coverageForm.get('amount').value;
        const chosenLongAmount = this.coverageForm.get('longAmount').value;
        this.restService.postUpdatedCoverageLevel(chosenAmount,chosenLongAmount )
          .subscribe(() => {
            if (chosenAmount <= this.currentCoverage && chosenLongAmount <= this.currentLongCoverage) {
              if(updateAOV){
                window.open('https://wga.acture.nl/health-declaration?membercode='+ this.memberCode);
              }
              this.router.navigate(['dashboard']);
            } else {

                this.myAreaService.acceptNewPromise()
                  .subscribe(() => {
                    if (updateAOV) {
                      window.open('https://wga.acture.nl/health-declaration?membercode='+ this.memberCode);
                    }
                    return this.router.navigate(['dashboard']);
                  });
            }
          });
      }
    } else {
      console.log('NOT valid');
      this.coverageForm.markAllAsTouched();
    }
  }
}

