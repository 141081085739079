import {Component, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MyAreaRestService} from "../../my-area.rest.service";
import {ISelfTest} from "../dashboard.component";
import {AccordionThemes} from "../self-test-advice/ui/accordion/model";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {SharedModulePortal} from "../../../shared-module/shared-module.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'sp-portal-self-test',
  templateUrl: './self-test.component.html',
  styleUrls: ['./self-test.component.scss'],
  imports: [
    SharedModulePortal,
    MatTooltipModule
  ],
  standalone: true
})
export class SelfTestComponent implements OnInit{
  private restService: MyAreaRestService  = inject(MyAreaRestService)
  private  router = inject(Router);
  selfTests: ISelfTest[] = [];
  selectedSelfTests = new BehaviorSubject(null);
  toggleButton = new FormGroup({
    'isToggled': new FormControl(false)
  })

  ngOnInit() {
    this.restService.getSelfTestLists().subscribe((value) => {
      console.log(this.selectedSelfTests.getValue());
      this.selfTests = value ? value : null;
      this.selectedSelfTests.next(this.selfTests.slice(0,1))
    })
    this.toggleButton.controls.isToggled.valueChanges.subscribe((value) => {
      if(value) {
        this.selectedSelfTests.next(this.selfTests.slice(1,2));
      }
      else {
        this.selectedSelfTests.next(this.selfTests.slice(0,1));
      }
    })
  }

  getLocalDate(dateString: string) {
    return new Date(dateString).toLocaleDateString("nl-NL", {month: "short", day: undefined, year: "numeric"});
  }

  getDecimal(string: string) {
    return string.slice(0, -1);
  }

  navigateToSelfTestAdvice() {
    this.router.navigateByUrl('/dashboard/self-test-advice');

  }

  protected readonly AccordionThemes = AccordionThemes;
  protected readonly Object = Object;
  protected readonly parseInt = parseInt;
}
