
<div class="loading-container" *ngIf="!message">
  <p>Awaiting payment confirmation</p>
  <sp-loading-spinner *ngIf="loading"></sp-loading-spinner>
</div>

<div class="message-container" *ngIf="message">
  <p>{{message}}</p>
  <button sp-button [routerLink]="messageButtonLink">Volgende</button>
</div>
