import { Component, OnInit } from '@angular/core';
import {MyAreaService} from '../my-area.service';

export interface IDonationHistory {
  average: number;
  registrationCompletedDate: string;
  history: [
    {
      period: string;
      description: string;
      amount: number;
      status: {
        name: string;
        enum: string;
      }
    }
  ]
}

@Component({
  selector: 'sp-portal-contribution-history',
  templateUrl: './contribution-history.component.html',
  styleUrls: ['./contribution-history.component.scss']
})
export class ContributionHistoryComponent implements OnInit {

  donationHistory: IDonationHistory;

  constructor(private myAreaService: MyAreaService) { }

  ngOnInit() {
    this.myAreaService.getContributions().subscribe((donationHistory) => {
      this.donationHistory = donationHistory;
    });
  }

}
