import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {
  IlongCoverageOptions,
  IPaymentTerms,
  IUserAge, IUserPaymentConfirmation,
  IUserPromiseConfirmation
} from '../../models/sp-long.models';

@Injectable({
  providedIn: 'root'
})
export class SpLongRestService {

  public coverage$ = new BehaviorSubject<number>(0);
  public cost$ = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  postKnowledgeTestResult(spLongInvitationCode: string, understandsProduct: boolean) {
    return this.http.post(`${environment.api_url}/signuplong/knowledgeCheck/${spLongInvitationCode}`, {understandsProduct})
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else if (err.status === 409) {
            this.handleRejectedUser();
          } else {
            return throwError(err);
          }
        })
      );
  }

  getInitialCoverages(invitationCode: string) {
    return this.http.get<any>(`${environment.api_url}/signuplong/getInitialCoverage/${invitationCode}`)
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else {
            return throwError(err);
          }
        })
      );
  }

  getCoverageAndCost(spLongInvitationCode: string, dateOfBirth: string = null, occupationId: any): Observable<any> {
    console.log(`${environment.api_url}`);
    const url = `${environment.api_url}/signuplong/calcPrice/${spLongInvitationCode ? spLongInvitationCode:''}`;
    let queryParams = new HttpParams();
    if (dateOfBirth) {
        queryParams = queryParams.append('dateOfBirth', dateOfBirth);
    }
    if (occupationId) {
        queryParams = queryParams.append('occupationId', occupationId);
    }
    return this.http.get<any>(url, {params: queryParams})
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else {
            return throwError(err);
          }
        })
      );
  }

  getLongCoverageAndCost(spLongInvitationCode: string): Observable<any> {
    const url = `${environment.api_url}/signuplong/calcPriceLong/${spLongInvitationCode ? spLongInvitationCode : ''}`;

    return this.http.get<any>(url)
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else {
            return throwError(err);
          }
        })
      );
  }

  getCoverages() {
    return this.http.get<any>(`${environment.api_url}/signuplong/calcCoverage`)
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else {
            return throwError(err);
          }
        })
      );
  }

  postLongCoverageResult(spLongInvitationCode: string, coverage: IlongCoverageOptions) {
    return this.http.post(`${environment.api_url}/signuplong/addLongCoverage/${spLongInvitationCode}`, {coverage})
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else if (err.status === 409) {
            this.handleRejectedUser();
          } else {
            return throwError(err);
          }
        })
      );
  }

  getUserAge(spLongInvigationCode: string): Observable<IUserAge>{
    return this.http.get<IUserAge>(`${environment.api_url}/signuplong/age/${spLongInvigationCode}`)
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else {
            return throwError(err);
          }
        })
      );
  }

  postPromiseConfirmation(spLongInvitationCode: string, userPromiseConfirmation: IUserPromiseConfirmation): Observable<IUserPromiseConfirmation> {
    return this.http.post<IUserPromiseConfirmation>(`${environment.api_url}/signuplong/promises/${spLongInvitationCode}`, userPromiseConfirmation)
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else {
            return throwError(err);
          }
        })
      );
  }

  getPaymentTerms(spLongInvigationCode: string): Observable<IPaymentTerms>{
    return this.http.get<IPaymentTerms>(`${environment.api_url}/signuplong/paymentTerms/${spLongInvigationCode}`)
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else {
            return throwError(err);
          }
        })
      );
  }
  getPromiseOverlayHTML(spLongInvigationCode: string) {
    return this.http.get<any>(`${environment.api_url}/signuplong/promises/${spLongInvigationCode}`);
  }

  postPaymentConfirmation(spLongInvitationCode: string, userPaymentConfirmation: IUserPaymentConfirmation): Observable<IUserPaymentConfirmation> {
    return this.http.post<IUserPaymentConfirmation>(`${environment.api_url}/signuplong/payment/${spLongInvitationCode}`, userPaymentConfirmation)
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.displayErrorMessageAndReturnUserToStart();
          } else {
            return throwError(err);
          }
        })
      );
  }

  private displayErrorMessageAndReturnUserToStart() {
    console.log('something went wrong');
    window.localStorage.clear();
    this.router.navigate(['sp-long/intro']);
  }

  private handleRejectedUser() {
    this.router.navigate(['sp-long/rejected']);
  }
}
