<div class="popup-background" [class.active]="popupActive">
  <div class="popup-container">
    <div class="close-button" (click)="close()"></div>
    <form [formGroup]="formGroup" #form='ngForm'>
      <h4>Contact</h4>
      <div class="item-container"
           [class]="form.submitted && formGroup.get('email').errors?'item-container hasError' : 'item-container'">
        <div class="input-wrapper">
          <sp-input-wrapper label="Email" [error]="form.submitted ? formGroup.get('email').errors : null">
            <input type="text" formControlName="email">
          </sp-input-wrapper>
        </div>
      </div>
      <div class="item-container"
           [class]="form.submitted && (formGroup.get('telephone').errors)?'item-container hasError' : 'item-container'">
        <div class="input-wrapper">
          <sp-input-wrapper label="Mobiel nummer" [error]="form.submitted ? formGroup.get('telephone').errors : null">
            <input type="text" formControlName="telephone" placeholder="Telefoon" class="toUppercase">
          </sp-input-wrapper>
        </div>
      </div>
    <div class="btn-wrapper">
      <button [disabled]="formHasChanged" [sp-button] (click)="saveChanges()" class="save-btn">Opslaan</button>
    </div>
    </form>
  </div>
</div>
