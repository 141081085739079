import { Component, OnDestroy, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { OnboardingService } from '../../../onboarding-module/onboarding.service';
import {Router} from '@angular/router';
import {CustomValidators} from '../../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {catchError} from 'rxjs/operators';
import {EMPTY, Observable} from 'rxjs';
import {KvkDetails} from '../../models/models';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';

@Component({
  selector: 'sp-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {

  companyForm: UntypedFormGroup;
  confirmedKvk: string = null;
  showNoCompanyFound = false;
  awaitingServerResponse = false;
  footerConfig = {btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Ga verder', outputBtnClickEvent: () => {return this.saveCompany();}};
  onboardingType$: Observable<any>;
  stepper = 2;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private onboardingService: OnboardingService,
    private onboardingTypeService: OnboardingTypeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
    this.getKvkFromStorage();
    this.clearConfirmedKvKOnChange();
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
    this.stepper = this.onboardingTypeService.returnOnboardType$getValue()==='SHORT'?3:1;
  }

  ngOnDestroy(): void {
    this.onboardingService.setCurrentStep();
  }

  getKvkFromStorage() {
    const kvkDetails = this.onboardingService.retrieveCompany();
    if (kvkDetails.hasOwnProperty('kvk') && kvkDetails.kvk) {
      if (kvkDetails) {
        this.companyForm.get('kvk').setValue(kvkDetails.kvk);
        this.companyForm.get('companyName').setValue(kvkDetails.company);
        this.confirmedKvk = kvkDetails.kvk;
      }
    }
  }

  createForm() {
    this.companyForm = this.formBuilder.group({
      kvk: [null, [Validators.required, CustomValidators.kvk]],
      companyName: [null, [Validators.minLength(2)]]
    });
  }

  clearConfirmedKvKOnChange() {
    this.companyForm.get('kvk').valueChanges
      .subscribe(
        () => {
          this.confirmedKvk = null;
          this.showNoCompanyFound = false;
        }
      );
  }

  submitKvk() {
    this.companyForm.get('companyName').setValidators(null);
    this.companyForm.get('companyName').updateValueAndValidity();
    if (this.companyForm.invalid) {
      this.companyForm.markAllAsTouched();
    } else {
      this.awaitingServerResponse = true;
      const encryptedUserId = this.onboardingService.getItem('encryptedUserId');
      const hashedEmail = this.onboardingService.getItem('hashedEmail');
      this.onboardingService.checkKvk(this.companyForm.get('kvk').value, encryptedUserId, hashedEmail)
        .pipe(
          catchError(
            (err => {
              // 400 when submitting an invalid KVK number
              // 404 when the valid KVK does not exist.
              if (err.status === 400 || err.status === 404) {
                this.confirmedKvk = null;
                this.showNoCompanyFound = true;
                this.awaitingServerResponse = false;
                return EMPTY;
                // KVK API is not repsonding
              } else if (err.status === 503) {
                this.awaitingServerResponse = false;
                this.companyForm.get('companyName').setValue(null);
                this.companyForm.get('companyName').setValidators([Validators.required, Validators.minLength(2)]);
                this.companyForm.get('companyName').updateValueAndValidity();
                this.confirmedKvk = this.companyForm.get('kvk').value;
              } else {
                this.awaitingServerResponse = false;
                return err;
              }
            })
          )
        )
        .subscribe(
          (kvkDetails: KvkDetails) => {
            this.awaitingServerResponse = false;
            this.showNoCompanyFound = false;
            this.companyForm.get('companyName').setValue(kvkDetails.company);
            this.companyForm.get('kvk').setValue(kvkDetails.kvk);
            this.companyForm.get('companyName').setValidators([Validators.required, Validators.minLength(2)]);
            this.companyForm.get('companyName').updateValueAndValidity();
            this.companyForm.updateValueAndValidity();
            this.confirmedKvk = kvkDetails.kvk;
          }
        );
    }
  }

  saveCompany() {
    if (this.companyForm.valid) {
      const updatedCompanyDetails = {
        company: this.companyForm.get('companyName').value,
        kvk: this.companyForm.get('kvk').value
      };
      this.onboardingService.setAndStoreCompanyDetails(updatedCompanyDetails)
        .subscribe(
          () => {
            if (this.onboardingTypeService.returnOnboardType$getValue() === OnboardingType.PROSPECTS) {
              const spLong = this.onboardingService.retrieveChosenCoverageType();
              if (spLong === true) {
                this.router.navigate(['sp-prospects/promise']);
              } else {
                this.router.navigate(['sp-prospects/promise']);
              }
            } else {
              this.router.navigate(['promise']);
            }

          }
        );
    } else {
      this.companyForm.markAllAsTouched();
    }
  }
}
