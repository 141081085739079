import { Component, Input, Output, EventEmitter } from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'sp-portal-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent {
  @Input() popupActive: boolean;
  @Input() formGroup: FormGroup;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  @Output() private onClosePopup = new EventEmitter<string>();
  formHasChanged: boolean;

  constructor(){}

  public saveChanges(){
    if (this.formGroup.valid) {
      this.onFormGroupChange.emit(this.formGroup.value);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  close(){
    this.onClosePopup.emit('contactPopupActive');
  }
}
