import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {BehaviorSubject} from "rxjs";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {themeRecord, ZZPTheme} from "./model";


@Component({
  selector: 'sp-portal-accordion',
  templateUrl: './accordion.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgForOf
  ],
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit{
  @Input() collapsed = false;
  @Input() themeName: string;
  @Input() theme: ZZPTheme;
  @Input() score: string = "";
  @Output() emmitCollapseStateAccordion = new EventEmitter<boolean>();

  constructor(public sanitizer: DomSanitizer) {}

  scoreGroup = [
    {color: 'accordion__score--poor', isActive: false},
    {color: 'accordion__score--average', isActive: false},
    {color: 'accordion__score--good', isActive: false},
    {color: 'accordion__score--excellent', isActive: false},
  ]

  ngOnInit() {
    this.setActiveScore(this.score);
  }

  setActiveScore(score: string) {
    if (score >= "1" && score < "4.0") {
      this.scoreGroup[0].isActive = true;
      return;
    }
    if (score >= "4.0" && score < "6.2") {
      this.scoreGroup[1].isActive = true;
      return;
    }
    if (score >= "6.2" && score < "8.6") {
      this.scoreGroup[2].isActive = true;
      return;
    }
    if (score >= "8.6" || score < "10.0") {
      this.scoreGroup[3].isActive = true;
      return;
    }
  }

  toggleAccordion() {
    this.emmitCollapseStateAccordion.emit(this.collapsed);
  }

  getDecimalScoreString(score: string) {
    return score.substring(0, score.length - 1)
  }


  protected readonly themeRecord = themeRecord;
  protected readonly parseInt = parseInt;
}
