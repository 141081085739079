import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EditKycDetailsComponent} from './edit-kyc-details/edit-kyc-details.component';
import {KycComponent} from './kyc.component';
import {SummaryKycComponent} from './summary-kyc/summary-kyc.component';
import {PersonalDetailsComponent} from './personal-details/personal-details.component';
import {AddressComponent} from './address/address.component';


const routes: Routes = [
  {
    path: 'kyc',
    component: KycComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'summary'},
      {path: 'summary', component: SummaryKycComponent},
      {path: 'personal-details', component: PersonalDetailsComponent},
      {path: 'address', component: AddressComponent},
      {path: 'edit-details/:kycSection', component: EditKycDetailsComponent},
      {path: '**', redirectTo: 'summary'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KycRoutingModule { }
