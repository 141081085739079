<div class="popup-background" [class.active]="AOVTermsPopupActive">
  <div class="popup-container">
    <div class="flex-container">
      <div class="info">
        <h2>AOV polis activatie</h2>
      </div>
      <br/>
      <form [formGroup]="AOVForm">
        <div class="form-options">
          <div class="checkbox-wrap">
            <sp-input-wrapper>
              <input
                type="checkbox"
                placeholder=""
                name="agree"
                id="agree"
                formControlName="agree">
            </sp-input-wrapper>
             <label for="agree"> Door het betalen van de eerste premie, ga ik
              akkoord met de incasso van de vervolgpremies.
              Dat zal vanaf volgende maand {{fee | currency:'EUR'}} zijn.</label>
          </div>
        </div>
        <div class="buttons-container">
          <button type="button" class="secondary" [sp-button] (click)="skipPopUp()">Ik doe dit later</button>
          <button type="button" [sp-button] (click)="submitAOV()" [disabled]="disableSubmitBtn">Doorgaan</button>
        </div>
      </form>
    </div>
  </div>
</div>
