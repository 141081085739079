<sp-portal-page-wrapper title="Wijzigen inkomensniveau">
  <br/>
  <section >
    <form [formGroup]="coverageForm" #form='ngForm'>
      <div class="income-wrapper">
        <div>
          <p>SharePeople KORT:</p>
          <ng-container *ngFor="let coverageLevel of availableCoverages">
            <sp-input-wrapper
              label="{{coverageLevel.label | spCurrency}} {{currentCoverage===coverageLevel.amount ?'(Huidig niveau)':null}}">
              <input
                name="amount"
                formControlName="amount"
                type="radio"
                [value]="coverageLevel.amount"
              ></sp-input-wrapper>
          </ng-container>
        </div>
        <div *ngIf="spLongJoined">
          <p>SharePeople LANG:</p>
          <ng-container *ngFor="let longCoverageLevel of availableLongCoverages">
            <sp-input-wrapper
              label="{{longCoverageLevel.label}} {{currentLongCoverage===longCoverageLevel.amount?'(Huidig niveau)':null}}">
              <input
                name="longAmount"
                formControlName="longAmount"
                type="radio"
                [value]="longCoverageLevel.amount"
              ></sp-input-wrapper>
          </ng-container>
          <sp-input-wrapper *ngIf="!subscription && noAOV" label="Stoppen" >
            <input name="longAmount" formControlName="longAmount" type="radio" [value]="0" >
          </sp-input-wrapper>
        </div>
      </div>
      <section *ngIf="noAOV && coverageForm.controls.longAmount.value==200000">
        <div class="warning-container">
          <h3>Belangrijk !</h3>
          <p>Je gaat nu de SharePeople AOV afsluiten.
          Hiervoor verlaat je de SharePeople Portal en start

            je jouw aanvraag voor de verzekering bij onze partner Acture.</p>
        </div>
      </section>
      <ng-container *ngIf="!spLongJoined && showTermsCheckBox">
        <section class="introduction" >
          <br/>
          <h4>Dit beloof je</h4>
          <p>
            Houd je niet van contracten met kleine lettertjes? Wij ook niet. Wel moet je opnieuw een aantal dingen beloven om deel
            te nemen aan SharePeople.
          </p>
        </section>

        <section class="list">
          <ul>
            <li>Ik ben op dit moment 100% in staat mijn huidige beroep uit te oefenen en ik verwacht niet arbeidsongeschikt te worden.</li>
            <li>Ik verwacht de aankomende 6 maanden niet arbeidsongeschikt te raken door een al gediagnostiseerde ziekte of
              aandoening.
            </li>
            <li>Ik doe elke maand een donatie aan een arbeidsongeschikte deelnemer.</li>
          </ul>
        </section>
      </ng-container>
      <ng-container *ngIf="spLongJoined && showTermsCheckBox">
        <section class="introduction" >
          <br/>
          <h4>Dit beloof je</h4>
          <p>
            Houd je niet van contracten met kleine lettertjes? Wij ook niet. Wel moet je opnieuw een aantal dingen beloven. Dit zijn onder andere de volgende punten:
          </p>
        </section>

        <section class="list">
          <ul>
            <li>Ik ben op dit moment 100% in staat mijn huidige beroep uit te oefenen en ik verwacht niet arbeidsongeschikt te worden.</li>
            <li>Mocht ik blijvend arbeidsongeschikt worden voor mijn huidige beroep, dan ga ik er alles aan doen om in een
              ander beroep te werken en/of te ondernemen, zodat ik in mijn eigen inkomen kan voorzien.
            </li>
            <li>Mocht ik bij blijvende arbeidsongeschiktheid op een andere manier in mijn inkomen kunnen voorzien
              (bijvoorbeeld door een uitkering van een inkomens- of schadeverzekering, sociale verzekering of bijstand) dan
              zal ik (deels) afzien van donaties.
            </li>
          </ul>
        </section>
      </ng-container>
      <section class="acceptance" *ngIf="showTermsCheckBox">
        <div class="term" >
          <sp-input-wrapper class="terms-and-conditions" [error]="form.submitted ? coverageForm.get('termsAccepted').errors : null">
            <input type="checkbox" formControlName="termsAccepted" name="termsAccepted">
          </sp-input-wrapper>
          <p>Ik accepteer de volledige <a (click)="getPromiseOverlay()">Verklaring & Beloftes.</a></p>
        </div>
        <div class="term" *ngIf="!noAOV">
          <sp-input-wrapper class="terms-and-conditions" [error]="form.submitted ? coverageForm.get('feeAccepted').errors : null">
            <input type="checkbox" formControlName="feeAccepted" name="feeAccepted">
          </sp-input-wrapper>
          <p>Ik ga akkoord met de eenmalige wijzigingskosten van € 45.</p>
        </div>
      </section>
    </form>
  <section>

    <div class="inline-buttons">
      <button sp-button [routerLink]="'../'" >Terug naar overzicht</button>
      <button sp-button *ngIf="!noAOV || coverageForm.controls.longAmount.value!=200000" [disabled]="!enableButton" (click)="updateCoverageLevel(false)" >Wijzigen</button>
      <button sp-button *ngIf="noAOV && coverageForm.controls.longAmount.value==200000" [disabled]="!enableButton" (click)="navigateAOV()">Start aanvraag AOV</button>
    </div>

  </section>
    <section *ngIf="!noAOV">
      <p>* Wil je een wijziging doorgeven van jouw AOV, <a href="mailto:contact&#64;sharepeople.nl">email</a> of bel ons dan even </p>
    </section>
  </section>
  <ng-template #notAvailable>
    <p>Voor het verhogen van je inkomensniveau stuur je een mail naar <a href="mailto:contact&#64;sharepeople.nl">contact&#64;sharepeople.nl</a></p>
    <button sp-button [routerLink]="'../'" >Terug naar overzicht</button>
  </ng-template>
</sp-portal-page-wrapper>
<sp-tc-overlay></sp-tc-overlay>
