import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {RegistrationRestService} from '../registration.rest.service';
import {Router} from '@angular/router';
import {RegistrationService} from '../registration.service';

@Component({
  selector: 'sp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private registrationService: RegistrationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
  }


  createForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [null, [Validators.required, CustomValidators.email]]
    });
  }

  submit() {
    if (this.forgotPasswordForm.valid) {
      this.registrationService.resetPassword(this.forgotPasswordForm.get('email').value)
        .subscribe(() => {
          alert('Check je email om je wachtwoord te herstellen');
          this.router.navigate(['/registration/login']);
        });
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  }
}
