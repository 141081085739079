import { Component, OnInit } from '@angular/core';
import {MyAreaService} from './my-area.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Component({
  selector: 'sp-my-area',
  templateUrl: './my-area.component.html',
  styleUrls: ['./my-area.component.scss']
})
export class MyAreaComponent implements OnInit {

  constructor(
    private myAreaService: MyAreaService
  ) { }

  ngOnInit() {
    this.myAreaService.init();
    this.myAreaService.memberData$
      .pipe(catchError((error) => {
        return throwError(error);
      }));
  }
}
