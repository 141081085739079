<sp-onboard-page-content-wrapper [footerConfig]="footerConfig"
                                 [showStepper]="true"
                                 [stepperConfig]="{currentStep: 0, onboardingType: (onboardingType$ | async)}">
  <section class="main">
    <ng-container>
      <h4>Kies je gewenste netto inkomen</h4>
      <p>Hoeveel netto-inkomen wil je ontvangen als je arbeidsongeschikt raakt? Dit bedrag kan niet hoger zijn dan wat je nu
        verdient.</p>
      <form [formGroup]="coverageForm" #form='ngForm'>
        <ng-container *ngFor="let coverageLevel of coverageLevelOptions">
          <sp-input-wrapper label="{{coverageLevel.amount | spCurrency}}">
            <input type="radio" formControlName="amount" name="amount" [value]="coverageLevel.amount">
          </sp-input-wrapper>
        </ng-container>
      </form>
    </ng-container>


    <p class="form-info">
      Twijfel je? Geen stress. Je kunt dit later nog wijzigen.
    </p>
  </section>

  <section class="sub-content">
    <ng-container>
      <h4>Je betaalt dan {{((selectedCoverage)?.averageMonthlyCost | spCurrency)}} per maand</h4>
      <p>Dit baseren we op de deelnamekosten en het huidige gemiddelde donatiebedrag per maand.</p>
    </ng-container>
  </section>
</sp-onboard-page-content-wrapper>
