<sp-portal-page-wrapper title="Dank voor je donatie!">
  <form [formGroup]="contributionMessageForm" #form='ngForm'>
    <p>Onderstaand kan je {{paymentData?.sickPersonName}} beterschap wensen:</p>
    <textarea
      formControlName="message" type="text" rows="7">
    </textarea>
    <button class="send-btn" (click)="submitMessage()" [sp-button] [disabled]="this.disableSendButton">Verstuur</button>
  </form>
  <button (click)="backToDashboard()" [sp-button]>Terug naar Portal</button>
</sp-portal-page-wrapper>
