import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SpLongRestService} from '../rest-service/sp-long-rest.service';
import {
  ICalculatedCoverageAndCost,
  IPaymentTerms,
  IUserAge,
  IUserPaymentConfirmation, IUserPromiseConfirmation
} from '../../models/sp-long.models';

@Injectable({
  providedIn: 'root'
})
export class SpLongService {

  public costAndCoverage$ = new BehaviorSubject<ICalculatedCoverageAndCost>(null);

  constructor(
    private restService: SpLongRestService
  ) {}

  postLongCoverage(spLongInvitationCode: string,  coverage: any) {
    return this.restService.postLongCoverageResult(spLongInvitationCode, coverage);
  }

  getCostAndCoverage(spLongInvitationCode: string, dateOfBirth: string = null, occupationId: string = null): Observable<any> {
    return this.restService.getCoverageAndCost(spLongInvitationCode, dateOfBirth, occupationId);
  }

  getCostAndCoverageLong(spLongInvitationCode: string): Observable<any> {
    return this.restService.getLongCoverageAndCost(spLongInvitationCode);
  }

  getCoverages() {
    return this.restService.getCoverages();
  }

  getUserAge(spLongInvitationCode): Observable<IUserAge> {
    return this.restService.getUserAge(spLongInvitationCode);
  }

  getPaymentTerms(spLongInvitationCode: string): Observable<IPaymentTerms> {
    return this.restService.getPaymentTerms(spLongInvitationCode);
  }

  getPromiseHTML(spLongInvitationCode: string) {
    return this.restService.getPromiseOverlayHTML(spLongInvitationCode);
  }

  postUserPaymentConfirmation(spLongInvitationCode: string, acceptPayment: IUserPaymentConfirmation) {
    return this.restService.postPaymentConfirmation(spLongInvitationCode, acceptPayment);
  }

  postPromiseConfirmation(spLongInvitationCode: string, promiseObject: IUserPromiseConfirmation) {
    return this.restService.postPromiseConfirmation(spLongInvitationCode, promiseObject);
  }

}
