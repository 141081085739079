import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DonationRestService} from './donation.rest.service';
import {DonationService} from './donation.service';
import { MakeContributionComponent } from './make-contribution/make-contribution.component';
import {SharedModulePortal} from '../shared-module/shared-module.module';
import { DonationComponent } from './donation.component';
import {DonationRoutingModule} from './donation-routing.module';
import {ProcessingPaymentComponent} from './processing-payment/processing-payment.component';
import { ContributionMessageComponent } from './contribution-message/contribution-message.component';



@NgModule({
  declarations: [
    MakeContributionComponent,
    DonationComponent,
    ProcessingPaymentComponent,
    ContributionMessageComponent
  ],
  providers: [
    DonationService,
    DonationRestService
  ],
  imports: [
    SharedModulePortal,
    CommonModule,
    DonationRoutingModule
  ],
  exports: [
    MakeContributionComponent
  ]

})
export class DonationModule { }
