<div class="self_test-results">
  <div class="self_test-results__buttons">
    <div class="self_test-results__tab" [routerLink]="'/dashboard/self-test'">Afgeronde testen</div>
    <div class="self_test-results__tab advice">Advies</div>
  </div>
  <ng-container *ngIf="themes">
    <div class="self_test-results__health-pop-up">
      <h3>Advies</h3>
      <ng-container *ngIf="parseInt(testData[0]?.averageGroupScore) > 8.6">
        <p class="self_test-results__health-pop-up__description">
          Jij bent goed bezig om gezond te (blíjven) ondernemen. Mooi! <br/>
          Als SharePeople zetten wij ons er natuurlijk voor in om
          de zieke deelnemers goed te begeleiden.
          Maar wist je dat we er ook zijn voor gezonde deelnemers? <br/> Op onze <a href="https://www.sharepeople.nl/gezond-ondernemen/"> Gezond
          ondernemen-pagina </a> vind je blogs, workshops en cursussen die jou helpen om goed voor jezelf als ondernemer te
          blijven
          zorgen. Op alle vlakken. <br/>
        </p>
        <button class="self_test-results__health-pop-up__button hover-class" (click)="navigateToHealthPage()">ga aan de
          slag
        </button>
      </ng-container>
      <ng-container *ngIf="parseInt(testData[0]?.averageGroupScore) <= 8.6">
        <p class="self_test-results__health-pop-up__description">
          Het is goed om te beseffen dat JIJ je onderneming bent. Zonder jou is er geen onderneming en gebeurt er niets.
          Goed voor jezelf zorgen is  dus heel belangrijk. Wij helpen je daar graag bij. <br/>
          <br />
          Wil je praten met iemand die kan meedenken? Vraag <a
          href="https://calendly.com/contact-sharepeople/preventief-gesprek"> hier </a> een gesprek aan met een adviseur
          werkvermogen van SharePeople. <br/>
          <br />
          Neem eens een kijkje op de volgende pagina's om te werken aan jouw ontwikkelpunten: <br/>
        </p>
        <div class="self_test-results__redirect-buttons">
          <ng-container  *ngFor="let score of testData[0].scores; let index = i">
            <ng-container *ngIf="parseFloat(score.average) < 8.5">
              <button  class="self_test-results__health-pop-up__button hover-class" (click)="navigateToThemePage(score.buttonLink)">
                {{ score.buttonText}}
              </button>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

    </div>

  </ng-container>
  <div class="self_test-results__accordions">
    <div *ngFor="let theme of  testData[0]?.scores; let i = index">
      <sp-portal-accordion
        [themeName]="AccordionThemes[theme.group]"
        [score]="theme.average"
        (emmitCollapseStateAccordion)="collapseAccordions($event, i)"
        [expanded]="theme.expanded"
      ></sp-portal-accordion>
    </div>
  </div>
</div>
