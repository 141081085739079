import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sp-portal-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.scss', '../../../../global-scss/responsive-wrapper.scss']
})
export class DonationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
