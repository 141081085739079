import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ThemeService} from '../../../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {OnboardingService} from '../../onboarding-module/onboarding.service';

interface GaServiceOptions {
  onlyProduction: boolean;
}

@Injectable({
  providedIn: 'root'
})

/*
https://developers.google.com/analytics/devguides/collection/analyticsjs/
 */

/**
 * This service should be initialised in the app.component constructor:
 * export class AppComponent {
 *   constructor( ga: GoogleAnalyticsService) {
 *    ga.init({
 *     onlyProduction: true
 *    });
 *   }
 */

export class GoogleAnalyticsService {
  private gaServiceOptions: GaServiceOptions;

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private onboardingService: OnboardingService
  ) {
  }

  init(gaServiceOptions: GaServiceOptions) {
    this.gaServiceOptions = gaServiceOptions;
    Object.freeze<GaServiceOptions>(gaServiceOptions);
    if (this.collectionAllowed()) {
      this.subscribeToNavigationEvents();
      const currentTheme = this.themeService._currentTheme.getValue().partnerName;
      if (currentTheme === 'Knab') {
        this.injectKnabGoogleTagManagerScript();
      } else {
        this.injectGoogleTagManagerScript();
      }
    }
  }

  private injectGoogleTagManagerScript() {
    const script = document.createElement('script');
    const scriptContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5FVV4Q4')`;
    script.append(scriptContent);
    document.head.prepend(script);

    const noScript = document.createElement('noscript');
    const noScriptContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5FVV4Q4"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    noScript.append(noScriptContent);
    document.body.prepend(noScript);
  }

  private injectKnabGoogleTagManagerScript() {
    const script = document.createElement('script');
    const scriptContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PBPPHS9'); window.dataLayer.push({'event': '${this.onboardingService.getItem('externalId')}', 'hashedEmail': '${this.onboardingService.getItem('hashedEmail')}', 'encryptedUserId': '${this.onboardingService.getItem('encryptedUserId')}'});`;
    script.append(scriptContent);
    document.head.prepend(script);

    const noScript = document.createElement('noscript');
    const noScriptContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PBPPHS9"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    noScript.append(noScriptContent);
    document.body.prepend(noScript);
  }

  private collectionAllowed() {
    if (!this.gaServiceOptions.onlyProduction) {
      return true;
    } else if (environment.production) {
      return true;
    } else {
      console.info('No GA collection - Not a production build');
      return false;
    }
  }

  private subscribeToNavigationEvents() {
    this.router.events
      .pipe(distinctUntilChanged())
      .subscribe(
        ev => {
          if (ev instanceof NavigationEnd) {
            this.sendVirtualPageView(ev.urlAfterRedirects);
          }
        }
      );
  }

  // private sendGAPageView(url) {
  // console.log('GA-pageView', url);
  // (<any>window).ga('set', 'page', url);
  // (<any>window).ga('send', 'pageview');
  // }

  // For GTM
  private sendVirtualPageView(url: string) {
    console.log('sending VirtualPageView', url);
    // @ts-ignore
    const dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'virtualPageView',
      page: url
    });

    dataLayer.push({
      event: this.onboardingService.getItem('externalId'),
      encryptedUserId: this.onboardingService.getItem('encryptedUserId'),
      hashedEmail: this.onboardingService.getItem('hashedEmail')
    });
  }

  private sendTestEvent() {
    // console.log('GA-testEvent');
    (window as any).ga('send', {
      hitType: 'event',
      eventCategory: 'TestCategory',
      eventAction: 'testAction',
      eventLabel: 'testLabel'
    });
  }

  private sendLoadingTime(componentName: string, time: number) {
    // console.log('GA-loadingTime: ', componentName, time, 'ms');
    (window as any).ga('send', {
      hitType: 'event',
      eventCategory: 'ComponentLoading',
      eventAction: 'loading',
      eventLabel: componentName,
      eventValue: time
    });
  }

  private sendException(errorMessage, isFatal= false) {
    // console.log('GA-Exception');
    (window as any).ga('send', 'exception', {
      exDescription: errorMessage,
      exFatal: isFatal
    });
  }
}
