<div class="popup-background" [class.active]="longWarningPopupActive">
  <div class="popup-container">
    <div class="flex-container">
      <div class="info">
        <h2>SharePeople Lang</h2>
      </div>
      <br/>
      <form>
        <div class="form-options">
          <div class="checkbox-wrap">
             <label> Let op: Onder voorbehoud van medische acceptatie, wordt SharePeople Lang (AOV en Crowdsurance) beëindigd op je 62ste verjaardag.</label>
          </div>
        </div>
        <div class="buttons-container">
          <button type="button" class="secondary" [sp-button] (click)="skipPopUp()">Terug</button>
          <button type="button" [sp-button] (click)="continueToUrl()">Ga door</button>
        </div>
      </form>
    </div>
  </div>
</div>
