import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CommonModule } from '@angular/common';
import {PaginatorComponent } from './paginator/paginator.component';
import {TitleHeaderComponent } from './title-header/title-header.component';
import {DateInputComponent } from './date-input/date-input.component';
import {TcOverlayComponent } from './tc-overlay/tc-overlay.component';
import {TextTransformDirective } from './directives/text-transform.directive';
import {NumberRangeDirective } from './directives/number-range.directive';
import {AutoFocusDirective } from './directives/auto-focus.directive';
import {WhitespaceTrimmerDirective} from './directives/whitespace-trimmer.directive';
import {SpecialCharTrimmerDirective } from './directives/special-char-trimmer.directive';
import {SpSharedLibModule} from 'sp-shared-lib';
import {StartComponent} from './pages/start/start.component';
import {OnboardPageContentWrapperComponent } from './onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {ChoosePaymentComponent} from './pages/choose-payment/choose-payment.component';
import {IncomeComponent} from './pages/income/income.component';
import {NameAndContactComponent} from './pages/name-and-contact/name-and-contact.component';
import {AddressComponent} from './pages/address/address.component';
import {CompanyComponent} from './pages/company/company.component';
import {PaticipationComponent} from './pages/paticipation/paticipation.component';
import {RejectedScreenComponent} from './pages/rejected-screen/rejected-screen.component';
import {PromiseComponent} from './pages/promise/promise.component';
import {ConfirmationComponent} from './pages/confirmation/confirmation.component';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {RouterModule} from '@angular/router';
import {SPLongPromiseComponent} from './pages/sp-long-promise/sp-long-promise.component';
import {KnowledgeTestComponent} from './pages/knowledge-test/knowledge-test.component';
import {ProcessingPaymentComponent} from './pages/processing-payment/processing-payment.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpSharedLibModule,
    RouterModule
  ],
  declarations: [
    PaginatorComponent,
    TitleHeaderComponent,
    DateInputComponent,
    TcOverlayComponent,
    TextTransformDirective,
    NumberRangeDirective,
    WhitespaceTrimmerDirective,
    AutoFocusDirective,
    SpecialCharTrimmerDirective,
    OnboardPageContentWrapperComponent,
    StartComponent,
    ChoosePaymentComponent,
    IncomeComponent,
    NameAndContactComponent,
    AddressComponent,
    CompanyComponent,
    PaticipationComponent,
    RejectedScreenComponent,
    PromiseComponent,
    ConfirmationComponent,
    ErrorPageComponent,
    SPLongPromiseComponent,
    KnowledgeTestComponent,
    ProcessingPaymentComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PaginatorComponent,
    TitleHeaderComponent,
    DateInputComponent,
    TcOverlayComponent,
    TextTransformDirective,
    AutoFocusDirective,
    WhitespaceTrimmerDirective,
    SpecialCharTrimmerDirective,
    SpSharedLibModule,
    StartComponent,
    ChoosePaymentComponent,
    IncomeComponent,
    NameAndContactComponent,
    AddressComponent,
    CompanyComponent,
    PaticipationComponent,
    RejectedScreenComponent,
    PromiseComponent,
    ConfirmationComponent,
    ErrorPageComponent,
    OnboardPageContentWrapperComponent,
    SPLongPromiseComponent,
    KnowledgeTestComponent,
    ProcessingPaymentComponent
  ]
})
export class SharedModule { }
