import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OnboardingService} from '../../../onboarding-module/onboarding.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CoverageLevel} from '../../../onboarding-module/onboarding.rest.service';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'sp-choose-payment',
  templateUrl: './choose-payment.component.html',
  styleUrls: ['./choose-payment.component.scss']
})
export class ChoosePaymentComponent implements OnInit, OnDestroy {
  coverageForm: UntypedFormGroup;
  coverageLevelOptions: CoverageLevel[];
  selectedCoverage: CoverageLevel;
  onboardingType$: Observable<any>;
  footerConfig = {btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Ja, dit wil ik', outputBtnClickEvent: () => {return this.submit();}};

  constructor(
    private onboardingService: OnboardingService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private onboardingTypeService: OnboardingTypeService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.setupForm();
    this.getCoverageLevelOptionsFromStorage();
    this.checkForStoredCoverageLevel();
    this.listenToCoverageLevelChanges();
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
  }

  ngOnDestroy() {
    this.onboardingService.setCurrentStep();
  }


  submit() {
    if (this.coverageForm.invalid) {
      this.coverageForm.markAllAsTouched();
    } else {
      const coverageLevel = this.getCoverageLevelFromAmount(this.coverageForm.get('amount').value);
      this.onboardingService.storeChosenCoverageLevel( coverageLevel );
      this.router.navigate(['../name-and-contact'], {relativeTo: this.route});
    }
  }

  private setupForm() {
    this.coverageForm = this.formBuilder.group({
      amount: [null, Validators.required]
    });
  }

  private listenToCoverageLevelChanges(): void {
    this.coverageForm.valueChanges.subscribe(
      form => {
        this.selectedCoverage = this.getCoverageLevelFromAmount(form.amount);
      }
    );
  }

  private checkForStoredCoverageLevel() {
    const storedCoverageLevel =  this.onboardingService.retrieveSelectedCoverageLevel();
    if (storedCoverageLevel) {
      this.selectedCoverage = storedCoverageLevel;
      this.coverageForm.get('amount').patchValue(storedCoverageLevel.amount);
    }
  }


  private getCoverageLevelFromAmount(amount): CoverageLevel {
    if (!this.coverageLevelOptions) { return; }
    return this.coverageLevelOptions.find(level => {return level.amount === amount;});
  }

  private getCoverageLevelOptionsFromStorage() {
    const queryData = this.onboardingService.getQueries();
    this.onboardingService.getAndStoreCoverageLevelOptionsAndUSPs(queryData.externalId, queryData.encryptedUserId, queryData.hashedEmail, queryData.occupation, null).subscribe((resp)=>{
      this.coverageLevelOptions = resp.coverageLevels;
    });
  }
}
