import {
  AfterContentInit,
  Component,
  ElementRef,
  Input, OnChanges, Renderer2,
  SimpleChanges
} from '@angular/core';
import { NgForm, ValidationErrors } from '@angular/forms';
import { ValidationErrorMessage } from '../validation-service/validation-error-message.service';

@Component({
  selector: 'sp-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss']
})
export class InputWrapperComponent implements AfterContentInit, OnChanges {

  @Input() label: string;
  @Input() hint: string;
  @Input() extraClasses? : string;
  @Input() error: ValidationErrors;
  // @Input() error: Map<string, string>;
  @Input() formRef: NgForm; // this is a ref to the parent form. its currently unused

  inputEl: HTMLInputElement;

  errorMessage: string;

  isValid: boolean;

  showFallbackDatePicker = false;


  constructor(
    private elRef: ElementRef,
    private readonly renderer: Renderer2,
    private errors: ValidationErrorMessage
  ) {  }


  ngAfterContentInit(): void {
    const spDateSelectEl = this.elRef.nativeElement.querySelector('sp-date-select');
    const spDateTextEl = this.elRef.nativeElement.querySelector('gkt-forms-date-text');
    const selectEl = this.elRef.nativeElement.querySelector('select');
    const inputEl = this.elRef.nativeElement.querySelector('input');
    const wrapperEl = this.elRef.nativeElement.querySelector('sp-input-wrapper');
    if (wrapperEl) {return; } else {
      // console.log(spDateSelectEl, selectEl, inputEl, wrapperEl )
      this.inputEl = spDateSelectEl ? spDateSelectEl : spDateTextEl ? spDateTextEl : selectEl ? selectEl : inputEl;
      if (!this.inputEl) {
        throw new Error(`unable to find an element to attach label to: ${this.elRef.nativeElement.firstChild.tagName}` );
      }
      this.dynamicallyConnectLabel(this.elRef.nativeElement, 'label', this.inputEl.tagName);
    }
  }


  ngOnChanges(change: SimpleChanges) {
    if (change.error && change.error.currentValue && !change.error.firstChange) {
      this.errorMessage = this.findMessage(change.error.currentValue);
      this.setValidClass(false);
    } else {
      this.errorMessage = null;
      this.setValidClass(true);
    }
  }

  setValidClass(isValid: boolean) {
    if (!this.inputEl) {return; }
    if (isValid) {
      this.inputEl.classList.remove('sp-invalid');
    } else {
      this.inputEl.classList.add('sp-invalid');
    }
  }

  findMessage(error): string {
    return this.errors.getError(error);
  }

  /*
   TODO: Use
    import { uuid } from '../utils/generators';
    a_unique_string: string = uuid()
  */
  private dynamicallyConnectLabel(parentElement: HTMLElement, labelSelector: string, inputSelector: string): void {
    const randId = Math.floor(Math.random() * 10000).toString(10);
    switch (true) {
      case parentElement.querySelector(inputSelector).id !== '' : {
        if (parentElement.querySelector(labelSelector)) {
          parentElement.querySelectorAll(labelSelector).forEach(
            selected => {
              selected.setAttribute('for', parentElement.querySelector(inputSelector).id);
            }
          );
        }
        break;
      }
      case parentElement.querySelector(inputSelector).id === '' : {
        parentElement.querySelector(inputSelector).id = randId;
        if (parentElement.querySelector(labelSelector)) {
          parentElement.querySelectorAll(labelSelector).forEach(
            selected => {
              selected.setAttribute('for', randId);
            }
          );
        }
        break;
      }
    }
  }

}
