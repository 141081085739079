import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'sp-portal-aov-terms-popup',
  templateUrl: './aov-terms-popup.component.html',
  styleUrls: ['./aov-terms-popup.component.scss']
})
export class AOVTermsPopupComponent implements OnInit{

  constructor (private formBuilder: UntypedFormBuilder) {}

  @Input() AOVTermsPopupActive: boolean;
  @Input() fee: any;
  @Input() paymentUrl: string;
  @Output() close  = new EventEmitter();
  AOVForm: UntypedFormGroup;
  disableSubmitBtn: Boolean;

  ngOnInit() {
    this.disableSubmitBtn = true;
    this.AOVForm = this.formBuilder.group({
      agree: [{value: null, disabled: false}]
    });

    this.AOVForm.valueChanges
      .subscribe(() => {
          this.disableSubmitBtn = !this.AOVForm.controls.agree.value;
      });
  }
  skipPopUp() {
    this.close.emit('close');
  }
  submitAOV(){
  //    If agree terms
    if(this.AOVForm.get('agree').value) {
      //    navigate to payment  url.
          window.open(this.paymentUrl)
    }
    this.close.emit('close');
  }
}
