import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {themeRecord, ZZPTheme} from "./model";


@Component({
  selector: 'sp-portal-accordion',
  templateUrl: './accordion.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgForOf
  ],
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() expanded = false;
  @Input() themeName: string;
  @Input() theme: ZZPTheme;
  @Input() score: string = "";
  @Output() emmitCollapseStateAccordion = new EventEmitter<boolean>();

  constructor(public sanitizer: DomSanitizer) {
  }

  scoreGroup = [
    {color: 'accordion__score--poor', isActive: false},
    {color: 'accordion__score--average', isActive: false},
    {color: 'accordion__score--good', isActive: false},
    {color: 'accordion__score--excellent', isActive: false},
  ]

  ngOnInit() {
    this.setActiveScore(this.score);
  }

  setActiveScore(score: string) {
    let decimalScore = parseFloat(score);
    if (decimalScore >= 1.0 && decimalScore < 4.0) {
      this.scoreGroup[0].isActive = true;
      return;
    }
    if (decimalScore >= 4.0 && decimalScore < 6.2) {
      this.scoreGroup[1].isActive = true;
      return;
    }
    if (decimalScore >= 6.2 && decimalScore < 8.5) {
      this.scoreGroup[2].isActive = true;
      return;
    }
    if (decimalScore >= 8.5 || decimalScore <= 10.00 ) {
      this.scoreGroup[3].isActive = true;
      return;
    }
  }

  toggleAccordion = () => {
    this.emmitCollapseStateAccordion.emit(!this.expanded);
  }

  getDecimalScoreString(score: string) {
    return score.substring(0, score.length - 1)
  }

  protected readonly themeRecord = themeRecord;
  protected readonly parseFloat = parseFloat;
}
