<section class="info-list-container" *ngFor="let listSection of listSections$ | async">
  <h4>{{listSection.title}}</h4>
  <ng-container *ngFor="let listItem of listSection.listItems">
    <sp-portal-info-list-item [listItem]="listItem"></sp-portal-info-list-item>
    <a
      *ngIf="listItem.subtitle === 'Betalen per ' && listItem.value === 'factuur'"
      style="margin-top: 10px;"
      (click)="showIncassoAlert()"
    >Liever per incasso?</a>
  </ng-container>
</section>
