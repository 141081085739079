import {Component, OnInit} from '@angular/core';
import {TcOverlayService} from './tc-overlay.service';
import {OverlayConfig} from '../models/models';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'sp-tc-overlay',
  templateUrl: './tc-overlay.component.html',
  styleUrls: ['./tc-overlay.component.scss']
})
export class TcOverlayComponent implements OnInit {

  overlay: OverlayConfig;
  overlayContent;  // HTML

  constructor(
    private tcOverlayService: TcOverlayService,
    private sanitizer: DomSanitizer
  ) {
    this.tcOverlayService.overlay$.subscribe(
      overlay => {
        this.overlay = overlay;

        if (overlay) {
          this.overlayContent = sanitizer.bypassSecurityTrustHtml(overlay.overlayContent);
        }
      }
    );
  }

  ngOnInit() {
  }

}
