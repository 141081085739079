import {LOCALE_ID, NgModule} from '@angular/core';
import {SharedModule} from './shared/shared.module';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {GktFormsLibraryModule} from 'gkt-forms-library';

// import { GktFormsLibraryModule } from 'projects/gkt-forms-library/src/lib/gkt-forms-library.module';

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    GktFormsLibraryModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' }
  ],
  exports: [
    SharedModule,
    GktFormsLibraryModule
  ]
})
export class SpSharedLibModule {
  constructor() {
    registerLocaleData(localeNl, 'nl');
  }
}
