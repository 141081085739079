import { Component, OnInit } from '@angular/core';
import {AvatarDetails} from '../../../../../sp-shared-lib/src/lib/shared/avatar-card/avatar-card.component';
import {KycService} from '../services/kyc.service';
import {Observable} from 'rxjs';
import {KycData} from '../models/kyc-data.model';

@Component({
  selector: 'sp-portal-summary-kyc',
  templateUrl: './summary-kyc.component.html',
  styleUrls: ['./summary-kyc.component.scss']
})

/*
We should make this work e2e better,
The kycData object should be able to be handed into a component that allows that object to be manipulated.
Some dynamic compo with the datda handed in .
If required we cn make 1 funky AF compo that parses the server kycData into something useful
 */

export class SummaryKycComponent implements OnInit {
  kycData$: Observable<KycData>;
  helpAvatarDetails: AvatarDetails = {
    email: 'contact@sharepeople.nl',
    imgUrl: 'https://placeimg.com/64/64/people',
    tel: '+31 343 227002 '

  };

  constructor(
    private kycService: KycService
  ) { }

  ngOnInit() {
    this.kycData$ = this.kycService.kycData$;
  }

}
