import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DonationComponent} from './donation.component';
import {ProcessingPaymentComponent} from './processing-payment/processing-payment.component';
import {ContributionMessageComponent} from './contribution-message/contribution-message.component';


const routes: Routes = [
  {
    path: '',
    component: DonationComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'processing-payment'},
      {path: 'processing-payment', component: ProcessingPaymentComponent},
      {path: 'contribution-message', component: ContributionMessageComponent},
      {path: '**', redirectTo: 'processing-payment'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DonationRoutingModule { }
