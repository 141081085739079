import {Component, Input, OnInit, SimpleChanges, OnChanges} from '@angular/core';

@Component({
  selector: 'sp-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() currentStepIndex = 0;
  @Input() currentSubStepIndex = null;
  @Input() onboardingType = 'SHORT';

  steps: any[];
  subSteps: string [] = [];

  constructor() {
    this.steps = [
      'Nog 5 stappen te gaan',
      'Nog 4 stappen te gaan',
      'Nog 3 stappen te gaan',
      'Nog 2 stappen te gaan',
      'Nog 1 stap te gaan',
      'Nog 1 stap'
    ];

    this.subSteps = [
      'Voortgang aanvraag',
      'Voortgang aanvraag',
      'Voortgang aanvraag',
      'Voortgang aanvraag',
    ];
  }

  ngOnInit() {
    if (this.onboardingType=='LONG') {
      this.steps = [
        'Voortgang aanvraag',
        'Voortgang aanvraag',
        'Voortgang aanvraag',
        'Voortgang aanvraag',
      ];
    }
    if (this.onboardingType=='PROSPECTS') {
      this.steps = [
        'Voortgang aanvraag',
        'Voortgang aanvraag',
        'Voortgang aanvraag',
        'Voortgang aanvraag',
      ];
    }
  }

  ngOnChanges(changes: OnChanges) {
    console.log('currentStepIndex: '+ this.currentStepIndex);
    console.log('currentSubStepIndex: '+ this.currentSubStepIndex);
    // if (this.onboardingType=='LONG' && this.currentSubStepIndex) {
    //     let index = this.currentSubStepIndex;
    //     this.steps = [
    //       'Voortgang aanvraag',
    //       'Voortgang aanvraag',
    //       'Voortgang aanvraag',
    //       'Voortgang aanvraag',
    //       'Voortgang aanvraag',
    //       'Resultaat',
    //     ];
    // }
  }

}
