<sp-onboard-page-content-wrapper
  [displayFooter]="hasUnderstood"
  [footerConfig]="footerConfig"
  [showFooterNote]="true"
  [btnDisabled]="!this.knowledgeTestForm.valid"
  [footerNoteHtml]="footerNote"
  [showStepper]="true"
  [stepperConfig]="{currentStep: 1, onboardingType: (onboardingType$ | async)}">
  <section class="main">
    <ng-container>
      <h4>Voor we verdergaan… Een korte kennistoets.</h4>
      <div class="form-container">
        <h4>Ik begrijp dat:</h4>

        <form [formGroup]="knowledgeTestForm" #form='ngForm'>
        <!--SharePeople Lang bestaat uit een crowdsurance én een verzekering ineen.-->
          <sp-input-wrapper class="terms-and-conditions"
                            label="SharePeople Lang bestaat uit een crowdsurance én een verzekering ineen.">
            <input type="checkbox" formControlName="eight" name="eight">
          </sp-input-wrapper>

          <!--We bij crowdsurance het risico onderling delen.">-->
          <sp-input-wrapper class="terms-and-conditions"
                            label="We bij crowdsurance het risico onderling delen.">
            <input type="checkbox" formControlName="first" name="first">
          </sp-input-wrapper>

          <sp-input-wrapper class="terms-and-conditions"
                            label="Ik iedere maand een donatie moet doen aan een arbeidsongeschikte deelnemer.">
            <input type="checkbox" formControlName="second" name="second">
          </sp-input-wrapper>

          <!--Het risico bestaat dat ik bij arbeidsongeschiktheid minder ontvang dan mijn gekozen inkomensniveau. Dit is alleen het geval als het ziektepercentage hoger wordt dan 8%.-->
          <sp-input-wrapper class="terms-and-conditions"
                            label="Ik bij arbeidsongeschiktheid niet meer ontvang dan mijn inkomen uit ondernemen.">
            <input type="checkbox" formControlName="third" name="third">
          </sp-input-wrapper>

          <!--Het donatiebedrag geleidelijk kan/zal stijgen, omdat we iemand soms wel meer dan 30 jaar moeten blijven ondersteunen met donaties.-->
          <sp-input-wrapper class="terms-and-conditions"
                            label="Het donatiebedrag fluctueert omdat het gebaseerd is op het arbeidsongeschiktheidspercentage.">
            <input type="checkbox" formControlName="fourth" name="fourth">
          </sp-input-wrapper>

          <!-- NIEUW -->
          <sp-input-wrapper class="terms-and-conditions"
                            label="Ik geen aanspraak kan maken op donaties bij arbeidsongeschiktheid door stressgerelateerde klachten, zoals een burn-out, ontstaan in het eerste jaar na aanmelding.">
            <input type="checkbox" formControlName="fifth" name="fifth">
          </sp-input-wrapper>

          <!-- NIEUW -->
          <sp-input-wrapper class="terms-and-conditions"
                            label="Ik bij stressgerelateerde klachten maximaal 2 jaar donaties ontvang.">
            <input type="checkbox" formControlName="sixth" name="sixt">
          </sp-input-wrapper>
          <!--Het uitgangspunt van SharePeople Lang is om te blijven werken/ondernemen. SharePeople Lang is een vangnet en geen hangmat.-->
          <sp-input-wrapper class="terms-and-conditions"
                            label="Ik geen aanspraak kan maken op donaties bij arbeidsongeschiktheid door een alcohol- of drugsverslaving.">
            <input type="checkbox" formControlName="seventh" name="seventh">
          </sp-input-wrapper>
        </form>

      </div>

    </ng-container>

  </section>

  <div *ngIf="!hasUnderstood" class="not-understand-overlay">
    <p>Het is belangrijk dat je begrijpt hoe SharePeople Lang werkt en of het voor jou de juiste oplossing is. Ga naar
      <a href="https://www.sharepeople.nl/advies" target="_blank">www.sharepeople.nl/advies</a> voor meer informatie.
    </p>

    <p>Deze aanvraag kunnen we nu niet met je voortzetten.</p>

    <p class="exit"><a (click)="updateStatus()">Sluit</a></p>
  </div>
</sp-onboard-page-content-wrapper>

<ng-template #footerNote>
  <a (click)="updateStatus()">Ik begrijp niet hoe SharePeople Lang werkt.</a>
</ng-template>

