import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {Occupation} from '../../dashboard/occupation-popup/occupation-popup.component';
import {MyAreaService} from '../../my-area.service';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CustomValidators} from '../../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';

@Component({
  selector: 'sp-portal-company-popup',
  templateUrl: './company-popup.component.html',
  styleUrls: ['./company-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyPopupComponent implements OnInit, AfterViewInit {
  @Input() popupActive: boolean;
  @Input() formGroup: FormGroup;
  @Input() profile: any;
  @Output() private onFormGroupChange = new EventEmitter<any>();
  @Output() private onClosePopup = new EventEmitter<string>();
  @ViewChildren('iban') ibanRef: QueryList<ElementRef>;
  formHasChanged: boolean;
  @Input() occupations: Occupation[];
  filteredOccupations: Observable<Occupation[]>;
  @Output() incasso: boolean;

  constructor(
    private myAreaService: MyAreaService, private ref: ChangeDetectorRef
  ) {
  }

  public ngOnInit() {
    this.formGroup.get('occupation')?.disable();
    this.searchFilter();
    this.formGroup.get('incasso').patchValue(this.profile.incasso, {emitEvent: true, onlySelf: false});
    if (this.formGroup.get('incasso').value === true) {
      this.incasso = true;
    }

    this.updateValidationsForPayment(this.formGroup.get('incasso').value);
    this.ref.markForCheck();
  }

  ngAfterViewInit(): void { }

  displayOccupationTitle(occupation) {
    return occupation ? occupation.name : occupation;
  }

  displayIncassoSettings(value) {
    this.formGroup.get('incasso').setValue(value);
    this.incasso = value;
    if (value === true) {
      if (this.ibanRef.first) {
        this.ibanRef.first.nativeElement.focus();
      }
      if (this.profile.incasso) {
        this.formGroup.patchValue({termsAccepted: false});
      }
    } else {
      this.formGroup.patchValue({monthlyPayment: false});
    }
    this.updateValidationsForPayment(value);
  }

  private updateValidationsForPayment(incasso: boolean) {
    if(incasso) {
      // Overwrite validation again if incasso is ticked
      this.formGroup.get('termsAccepted').setValidators([Validators.required, CustomValidators.valueIsTrue]);
      this.formGroup.get('termsAccepted').updateValueAndValidity();
      this.formGroup.get('iban').setValidators([Validators.required, CustomValidators.iban]);
      this.formGroup.get('iban').updateValueAndValidity();
    } else {
      // termsAccepted and iban validations are irrelevant if !incasso:
      this.formGroup.get('termsAccepted').setValidators([]);
      this.formGroup.get('termsAccepted').updateValueAndValidity();
      this.formGroup.get('iban').setValidators([]);
      this.formGroup.get('iban').updateValueAndValidity();
    }
  }

  public saveChanges(){
    let occupation = this.formGroup.get('occupation');
    if (typeof(occupation.value) === 'string') {
      occupation.setValue(null);
    }
    if (this.formGroup.valid) {
      this.onFormGroupChange.emit(this.formGroup.value);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  private _filter(value) {
    if(!this.occupations) return
    // the following is for error handling – when typing, value is a string; when value is selected, it turns into the whole Occupation object;
    const filterValue = value.name ? value.name.toLowerCase() : value.toString().toLowerCase();
    var filtered = this.occupations.filter(occupation => {return occupation.name?.toLowerCase().includes(filterValue);});

    return filtered
  }

  searchFilter() {
    this.filteredOccupations = this.formGroup.controls.occupation.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          return this._filter(value);
        })
      );
  }

  close() {
    this.onClosePopup.emit('companyPopupActive');
  }
}
