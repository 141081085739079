import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

/*
https://developers.google.com/analytics/devguides/collection/analyticsjs/
 */

export class GoogleAnalyticsService {

  constructor(
  ) {  }

  // sendPageView(url) {
  // console.log('GA-pageView', url);
  // (<any>window).ga('set', 'page', url);
  // (<any>window).ga('send', 'pageview');
  // }

  sendPageView(url: string) {
    if (environment.production) {
      // @ts-ignore
      const dataLayer = window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: 'virtualPageView',
        page: url
      });
      this.injectGoogleTagManagerScript();
    }
  }

  private injectGoogleTagManagerScript() {
    const script = document.createElement('script');
    const scriptContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MJGDX8D')`;
    script.append(scriptContent);
    document.head.prepend(script);

    const noScript = document.createElement('noscript');
    const noScriptContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJGDX8D"
                  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    noScript.append(noScriptContent);
    document.body.prepend(noScript);
  }

  sendTestEvent() {
    // console.log('GA-testEvent');
    (window as any).ga('send', {
      hitType: 'event',
      eventCategory: 'TestCategory',
      eventAction: 'testAction',
      eventLabel: 'testLabel'
    });
  }

  sendLoadingTime(componentName: string, time: number) {
    // console.log('GA-loadingTime: ', componentName, time, 'ms');
    (window as any).ga('send', {
      hitType: 'event',
      eventCategory: 'ComponentLoading',
      eventAction: 'loading',
      eventLabel: componentName,
      eventValue: time
    });
  }

  sendException(errorMessage, isFatal= false) {
    // console.log('GA-Exception');
    (window as any).ga('send', 'exception', {
      exDescription: errorMessage,
      exFatal: isFatal
    });
  }
}
