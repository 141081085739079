import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup} from '@angular/forms';
import {MyAreaService} from '../../my-area.service';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

export interface Occupation {
  id: number;
  name: string;
}

@Component({
  selector: 'sp-portal-occupation-popup',
  templateUrl: './occupation-popup.component.html',
  styleUrls: ['./occupation-popup.component.scss']
})

export class OccupationPopupComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private myAreaService: MyAreaService,
  ) { }

  occupationPopupActive = false;
  occupationForm: UntypedFormGroup;
  disableSubmitBtn = true;
  occupations: Occupation[];
  filteredOccupations: Observable<Occupation[]>;

  ngOnInit() {
    const hidePopupUntilNextSession = sessionStorage.getItem('hidePopupUntilNextSession');
    this.myAreaService.profile$.subscribe(profileData => {
      if (!profileData.occupation && !hidePopupUntilNextSession) {
        this.occupationPopupActive = true;
      }
    });
    this.myAreaService.getOccupations()
      .subscribe(occupations => {
        this.occupations = occupations;
        this.searchFilter();
      });
    this.createOccupationForm();
  }

  createOccupationForm() {
    this.occupationForm = this.formBuilder.group({
      occupation: [{value: null, disabled: false}]
    });

    // enables/disables submit button if no valid value is filled:
    this.occupationForm.valueChanges
      .subscribe(() => {
        if (this.occupationForm.controls.occupation.value.id) {
          this.disableSubmitBtn = false;
        } else {
          this.disableSubmitBtn = true;
        }
      });
  }

  submitOccupation() {
    const occupationId = this.occupationForm.controls.occupation.value.id;
    if (occupationId) {
      this.myAreaService.sendOccupation(occupationId)
        .subscribe(() => {
          this.occupationPopupActive = false;
          this.myAreaService.getMemberProfile();
        });
    }
  }

  displayOccupationTitle(occupation) {
    return occupation ? occupation.name : occupation;
  }

  skipPopUp() {
    this.occupationPopupActive = false;
    sessionStorage.setItem('hidePopupUntilNextSession', 'true');
  }

  private _filter(value) {
    // the following is for error handling – when typing, value is a string; when value is selected, it turns into the whole Occupation object;
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();

    return this.occupations.filter(occupation => {return occupation.name?.toLowerCase().includes(filterValue);});
  }

  searchFilter() {
    this.filteredOccupations = this.occupationForm.controls.occupation.valueChanges
      .pipe(
        startWith(''),
        map(value => {return this._filter(value);})
      );
  }

}
