<sp-portal-page-wrapper title="Woonadres">
  <form [formGroup]="addressDetailsForm" #form='ngForm'>
    <sp-input-wrapper
      [label]="'Straat'"
      [error]="form.submitted ? this.addressDetailsForm.get('street').errors : null">
      <input formControlName="street" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'Huisnummer'"
      [error]="form.submitted ? this.addressDetailsForm.get('houseNumber').errors : null">
      <input formControlName="houseNumber" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'ext.'"
      [error]="form.submitted ? this.addressDetailsForm.get('extension').errors : null">
      <input formControlName="extension" type="text" >
    </sp-input-wrapper>

    <sp-input-wrapper
      label="Postcode"
      [error]="form.submitted ? this.addressDetailsForm.get('postalCode').errors : null">
      <input type="text" formControlName="postalCode">
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'Woonplaats'"
      [error]="form.submitted ? this.addressDetailsForm.get('city').errors : null">
      <input formControlName="city" type="text">
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'province'"
      [error]="form.submitted ? this.addressDetailsForm.get('province').errors : null">
      <input formControlName="province" type="text">
    </sp-input-wrapper>

    <sp-input-wrapper
      [label]="'Land'"
      [error]="form.submitted ? this.addressDetailsForm.get('country').errors : null">
      <input formControlName="country" type="text">
    </sp-input-wrapper>

    <button (click)="submit()" [sp-button]>Doorgaan</button>
  </form>
</sp-portal-page-wrapper>
