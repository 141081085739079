import { NgModule } from '@angular/core';
import {ButtonComponent} from './button/button.component';
import {InputWrapperComponent} from './input-wrapper/input-wrapper.component';
import {CommonModule} from '@angular/common';
import {DateSelectComponent} from './date-select/date-select.component';
import { DateTextComponent } from './date-text/date-text.component';
import {FormsModule} from '@angular/forms';
import { AllowedCharsDirective } from './directives/allowed-chars.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    ButtonComponent,
    InputWrapperComponent,
    DateSelectComponent,
    DateTextComponent,
    AllowedCharsDirective
  ],
  exports: [
    ButtonComponent,
    InputWrapperComponent,
    DateSelectComponent,
    DateTextComponent,
    AllowedCharsDirective
  ]
})
export class GktFormsLibraryModule { }
