import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {filter, distinctUntilChanged, map} from 'rxjs/operators';
import {MemberData} from './dashboard/dashboard.component';
import {MyAreaRestService} from './my-area.rest.service';
import {UserProfile} from './profile/profile.component';

@Injectable({
  providedIn: 'root'
})
export class MyAreaService {

  private _profileData = new BehaviorSubject<UserProfile>(null);
  profile$: Observable<UserProfile> = this._profileData.asObservable().pipe(filter(x => {return !!x;}), distinctUntilChanged());

  private _memberData = new BehaviorSubject<MemberData>(null);
  memberData$: Observable<MemberData> = this._memberData.asObservable().pipe(filter(x => {return !!x;}), distinctUntilChanged());

  private _memberSpLongInvitationCode = new BehaviorSubject<MemberData>(null);
  memberSpLongInvitationCode$: Observable<MemberData> = this._memberSpLongInvitationCode.asObservable().pipe(filter(x => {return !!x;}), distinctUntilChanged());

  constructor(
    private restService: MyAreaRestService,
  ) {}


  init() {
    this.getMemberProfile();

    this.restService
      .getMemberData()
      .subscribe(
        (data: MemberData) => {
          this._memberData.next(data);
        });

    //We do this because we need to do this call everytime we get back from a self test
    // To Trigger the backend to give us results and parse them to the MemberData.
    this.restService.calculateResultTrigger().subscribe()
  }

  getMemberProfile() {
    return this.restService.getMemberProfile()
      .subscribe(
        (data: UserProfile) => {return this._profileData.next(data);});
  }

  pollMemberData(): Observable<MemberData> {
    return this.restService.getMemberData();
  }

  updateProfile(newSettings) {
    return this.restService.updateProfile(newSettings);
  }

  getContributions(): Observable<any> {
    return this.restService.getContributions();
  }

  postEyevestorSignUp(): Observable<any> {
    return this.restService.postEyevestorSignUp();
  }

  startSelfTest() {
    return this.restService.startSelfTest();
  }

  getPromiseInfo() {
    return this.restService.getPromiseInfo();
  }

  downloadPromise() {
    return this.restService.downloadPromise();
  }

  getPromiseHTML(stopLong) {
    return this.restService.getPromiseHTML(stopLong);
  }

  acceptNewPromise() {
    return this.restService.acceptNewPromise();
  }

  getOccupations() {
    return this.restService.getOccupations();
  }

  sendOccupation(occupationId) {
    return this.restService.sendOccupation(occupationId);
  }

  reportSickness(form) {
    return this.restService.reportSickness(form);
  }

  getCostAndCoverage(spLongInvitationCode) {
    return this.restService.getCoverageAndCost(spLongInvitationCode);
  }

  getExtendedCoverageOptions() {
    return this.restService.getExtendedCoverageOptions();
  }

  selectExtendedCoverage(optionId) {
    return this.restService.selectExtendedCoverage(optionId)
      .pipe(map((selectedOption: any) => {
        const profileData = this._profileData.getValue();
        const updatedProfileData = {...profileData, extendedCoverageResponse: selectedOption };
        this._profileData.next(updatedProfileData);
      }));
  }
}
