import {Component, isDevMode, OnInit} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {SpLongInvitationCode} from '../../../sp-long-module/models/sp-long.models';
import {SpLongService} from '../../../sp-long-module/services/sp-long-service/sp-long.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {catchError, filter, takeUntil} from 'rxjs/operators';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';
import {OnboardingService} from '../../../onboarding-module/onboarding.service';
import {CoverageLevel} from '../../../onboarding-module/onboarding.rest.service';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {HttpStatusCode} from '@angular/common/http';

interface ICostAndCoverage {
  coverage: number;
  spLong: [
    {
      coverage: number;
      cost: number;
      costComponents: {
        monthlyFee,
        aovPremium,
        aovCoverage,
        longCrowdSuranceCoverage,
        shortContribution,
        longContribution,
        totalCost
      }
    }
  ];
}

@Component({
  selector: 'sp-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit {

  defaultCoverage = 200000;
  coverageForm: UntypedFormGroup;
  cost: number;
  selectedLongOption;

  costAndCoverage;
  oboardingType$: Observable<any>;
  disableSendButton: boolean;
  development: boolean;
  isSPLong: boolean;
  coverageLevelOptions: CoverageLevel[];
  paginatorStep = 1;
  footerConfig = {btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Ja, dit wil ik', outputBtnClickEvent: () => {return this.submitPROSPECTS();}};

  spLongAvailabe: boolean = true;

  euroFormat = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
  });

  selectedValue: number = 266;

  constructor(
    private spLongService: SpLongService,
    private onboardingTypeService: OnboardingTypeService,
    private onboardingService: OnboardingService,
    private formBuilder: UntypedFormBuilder,
    private router: Router) {
  }

  ngOnInit(): void {
    this.development = isDevMode();
    const spLongInvitationCode = localStorage.getItem(SpLongInvitationCode.SPLONGINVITATIONCODE);
    this.oboardingType$ = this.onboardingTypeService.onboardingType$;
    this.isSPLong = this.onboardingService.retrieveChosenCoverageType();

    const type = this.onboardingTypeService.returnOnboardType$getValue();
    if (type === OnboardingType.PROSPECTS) {
      this.getCoverageLevelOptionsFromStorage();
      this.paginatorStep = 1;
      this.defaultCoverage = 200000;
    }
    if (type === OnboardingType.LONG) {
      this.paginatorStep = 0;
    }
    this.createForm();
    this.setFooterbuttonClickEvent();

    if (type === OnboardingType.PROSPECTS) {
      let dob = this.onboardingService.retrieveContactDetails().dob;
      let occupationId = this.onboardingService.retrieveChosenOccupation();
      this.spLongService.getCostAndCoverage(null, dob, occupationId).pipe(
        catchError((origErr) => {
          // window.alert(origErr.error.error);
          if (origErr.status == HttpStatusCode.NotAcceptable.valueOf()) {
            this.disableLong();
          }
          return throwError(origErr);
        })).subscribe((value: ICostAndCoverage) => {
        this.costAndCoverage = value;
        console.log(this.costAndCoverage);
        if (this.onboardingTypeService.returnOnboardType$getValue() === OnboardingType.LONG) {
          if (!this.costAndCoverage.currentShortCoverage) {
            this.router.navigate(['sp-long/error-page']);
          }
        }
        if (!this.costAndCoverage.currentShortCoverage) {
          this.costAndCoverage.currentShortCoverage = this.defaultCoverage;
        }
        this.setCost(this.defaultCoverage);
      });
    }

    if (type === OnboardingType.LONG) {
      this.spLongService.getCostAndCoverageLong(spLongInvitationCode)
        .pipe(
          catchError((origErr) => {
            if (origErr.status == HttpStatusCode.NotAcceptable.valueOf()) {
              this.disableLong();
            }
            return throwError(origErr);
          })
        ).subscribe((response) => {
          this.costAndCoverage = {
            currentShortCoverage: response.coverage,
            coverages: response.spLong.map((lc) => {
              return { longCoverage: lc.coverage, cost: lc.cost, hidden: lc.hidden, costComponents: lc.costComponents };
            })
          };

          // If currentShortCoverage is an option in longCoverages, make it default
          if(this.costAndCoverage.coverages.find( it => {return it.longCoverage == this.costAndCoverage.currentShortCoverage;})){
            this.defaultCoverage = this.costAndCoverage.currentShortCoverage;
          }
          this.coverageForm.get('coverage').setValue(this.defaultCoverage);
          this.setCost(this.defaultCoverage);
        });
    }

    if (this.costAndCoverage && !this.costAndCoverage.currentShortCoverage) {
      this.costAndCoverage.currentShortCoverage = this.defaultCoverage;
    }

    this.coverageForm.get('coverage').valueChanges.subscribe(selectedValue => {
      if (type === OnboardingType.PROSPECTS) {
        this.costAndCoverage.currentShortCoverage = selectedValue;
      }
      this.setCost(selectedValue);
    });

  }

  private disableLong() {
    this.spLongAvailabe = false;
    this.footerConfig.footerBtnText = 'Ga door';
    this.footerConfig.outputBtnClickEvent = () => {return this.continueShort()};
  }

  setCost(selectedValue) {
    const type = this.onboardingTypeService.returnOnboardType$getValue();
    if (type === OnboardingType.LONG) {
      this.selectedLongOption = this.costAndCoverage.coverages.find((it) => {
        return it.longCoverage === selectedValue
      });
    } else {
      let selectedLong = this.costAndCoverage.coverages.find((it) => {
        return it.longCoverage === selectedValue
      });
      this.selectedLongOption = selectedLong.shortOptions.find( (it) => {
        return it.coverage === selectedValue
      });
    }
    this.cost = this.selectedLongOption.cost;

    this.selectedValue = (selectedValue);
    if (type === OnboardingType.LONG) {
      this.animateGraph();
    }
  }

  setFooterbuttonClickEvent() {
    const destroy$ = new Subject();
    this.oboardingType$.pipe(
      filter(x => {return !!x;}),
      takeUntil(destroy$)
    ).subscribe((type: OnboardingType) => {
      if (type === OnboardingType.PROSPECTS) {
        this.footerConfig.outputBtnClickEvent = () => {return this.submitPROSPECTS();};
      } else {
        this.footerConfig.outputBtnClickEvent = () => {return this.submitSPLONG();};
      }
      destroy$.next();
    });
  }

  createForm() {
    this.coverageForm = this.formBuilder.group({
      coverage: [this.defaultCoverage, [Validators.required]]
    });
  }

  submitSPLONG() {
    if (this.coverageForm.invalid) {
      this.coverageForm.markAllAsTouched();
    } else {
      const spLongInvitationCode = localStorage.getItem(SpLongInvitationCode.SPLONGINVITATIONCODE);
      const coverageLevel = this.coverageForm.get('coverage').value;
      this.onboardingService.storeChosenSpLongCoverageLevel(coverageLevel);
      this.spLongService.postLongCoverage(spLongInvitationCode, coverageLevel).subscribe((res) => {
        this.router.navigate(['sp-long/knowledge-test']);
      });
    }
  }

  submitPROSPECTS() {
    if (this.coverageForm.invalid) {
      this.coverageForm.markAllAsTouched();
    } else {
      const shortCoverageLevel = {
        amount: this.coverageForm.get('coverage').value,
        averageMonthlyCost: this.getCoverageLevelFromAmountProspects(this.costAndCoverage.currentShortCoverage)
      };
      const longCoverageLevel = this.coverageForm.get('coverage').value;
      this.onboardingService.updateCoverageLevelOptions(this.costAndCoverage.coverages.find((it) => {return it.longCoverage === this.costAndCoverage.currentShortCoverage;}).shortOptions);
      this.onboardingService.storeChosenCoverageLevel(shortCoverageLevel);
      this.onboardingService.storeChosenSpLongCoverageLevel(longCoverageLevel);
      this.router.navigate(['sp-prospects/extra-income']);
    }
  }

  animateGraph() {
    const aovValue = this.selectedLongOption.costComponents.aovCoverage;
    const crowdValue = this.selectedLongOption.costComponents.longCrowdSuranceCoverage;
    const scale = 1500;
    const baseLineHeight = 240;

    let aovBlock = document.getElementById('aov');
    let aovAmount = document.getElementById('aovAmount');
    let aovBlockHeight = aovValue / scale;

    aovBlock.setAttribute("height", (aovBlockHeight) + "px");
    aovBlock.setAttribute("y", baseLineHeight - aovBlockHeight + "px");
    aovAmount.setAttribute("y", (baseLineHeight + 8 - aovBlockHeight / 2).toString());
    aovAmount.textContent = this.formatAmount(aovValue)+'*';

    let longCrowdBlock = document.getElementById('longCrowd');
    let longCrowdAmount = document.getElementById('longCrowdAmount');
    let longCrowdBlockHeight = crowdValue / scale;

    longCrowdBlock.setAttribute("height", (longCrowdBlockHeight) + "px");
    longCrowdBlock.setAttribute("y", baseLineHeight - longCrowdBlockHeight - aovBlockHeight + "px");
    longCrowdAmount.setAttribute("y", (baseLineHeight + 8 - (longCrowdBlockHeight / 2) - aovBlockHeight).toString());
    longCrowdAmount.textContent = this.formatAmount(crowdValue);
    if (crowdValue > 0) {
      longCrowdAmount.setAttribute("visibility", "visible")
    } else {
      longCrowdAmount.setAttribute("visibility", "hidden")
    }

    let shortCrowdHave = document.getElementById('have');
    let shortCrowdBlock = document.getElementById('shortCrowd');
    let shortCrowdAmount = document.getElementById('shortCrowdAmount');
    let shortCrowdBlockHeight = this.costAndCoverage?.currentShortCoverage / scale;

    shortCrowdBlock.setAttribute( "height",(shortCrowdBlockHeight)+"px");
    shortCrowdBlock.setAttribute( "y",baseLineHeight - shortCrowdBlockHeight+"px");
    shortCrowdAmount.setAttribute( "y",(baseLineHeight + 8 - shortCrowdBlockHeight / 2).toString());
    shortCrowdAmount.textContent = this.formatAmount(this.costAndCoverage?.currentShortCoverage);
    shortCrowdHave.setAttribute( "transform",`translate(10, ${baseLineHeight - shortCrowdBlockHeight - 40})`);
  }

  formatAmount(nr: number) {
    return this.euroFormat.format((nr/100));
  }

  private getCoverageLevelFromAmountProspects(amount): number {
    if (!this.costAndCoverage) {
      return;
    }
    const options = this.costAndCoverage.coverages.find((it) => {return it.longCoverage === amount;}).shortOptions;
    return options.find(it => {return it.coverage === amount;}).cost;
  }

  private getCoverageLevelOptionsFromStorage() {
    this.coverageLevelOptions = this.onboardingService.retrieveCoverageLevelsArray();
    this.coverageLevelOptions?.sort(((a, b) => {
      return a.amount < b.amount ? -1 : 1;
    }));
  }

  continueShort() {
    this.router.navigate(['sp-prospects/select-coverage']);
  }
}
