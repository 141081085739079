import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {PaymentStatus} from '../../../../onboarding/src/app/shared-module/models/models';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DonationRestService {

  constructor(
    private http: HttpClient
  ) { }

  postPaymentStatus(contributionToken): Observable<PaymentStatus> {
    return this.http.post<PaymentStatus>(`${environment.api_url}/signup/mollie/paymentStatus`, {contributionToken});
  }

  getContributionUrl(paymentToken: string): Observable<{paymentUrl: string}> {
    return this.http.post<{paymentUrl: string}>(`${environment.api_url}/member/contributionPaymentLink`, {paymentToken} );
  }

  postContributionMessage(contributionToken, message): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/member/sendMessage`, {contributionToken, message});
  }
}
