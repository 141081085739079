import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sp-portal-info-list',
  templateUrl: './info-list.component.html',
  styleUrls: ['./info-list.component.scss']
})
export class InfoListComponent implements OnInit {

  @Input() listSections$;

  constructor() { }

  ngOnInit() {
  }

  showIncassoAlert() {
    alert('Stuur even een mail naar contact@sharepeople.nl met je IBAN, dan gaan we de volgende factuur automatisch incasseren.');
  }
}
