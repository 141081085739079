import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SetPassword} from './new-password/new-password.component';
import {LoginDetails} from '../../../../onboarding/src/app/shared-module/models/models';
import {environment} from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class RegistrationRestService {

  constructor(
    private http: HttpClient
  ) {}

  newPassword(setPassword: SetPassword): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/signup/setPassword`, setPassword);
  }

  // ** warn - should be under /member not /signup
  verifyAccount(activationCode): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/signup/verifyAccount`, {activationCode});
  }

  login(loginDetails: LoginDetails) {
    return this.http.post<any>(`${environment.auth_url}/login`, loginDetails);
  }

  resetPassword(email: string) {
    return this.http.post<any>(`${environment.api_url}/signup/requestReset`, {email});
  }

  checkResetTokenValidity(resetToken): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/signup/verifyResetLink`, {resetToken});
  }
}
