import { NgModule } from '@angular/core';

import { KycRoutingModule } from './kyc-routing.module';
import { KycComponent } from './kyc.component';
import {DocumentDetailsSectionComponent} from './summary-kyc/document-details-section/document-details-section.component';
import { SummaryKycComponent } from './summary-kyc/summary-kyc.component';
import { PersonalDetailsSectionComponent } from './summary-kyc/personal-details-section/personal-details-section.component';
import {SharedModulePortal} from '../shared-module/shared-module.module';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { AddressComponent } from './address/address.component';
import {KycRestService} from './services/kyc.rest.service';
import {KycService} from './services/kyc.service';
import { EditKycDetailsComponent } from './edit-kyc-details/edit-kyc-details.component';
import {SpSharedLibModule} from 'sp-shared-lib';

@NgModule({
  imports: [
    SpSharedLibModule,
    SharedModulePortal,
    KycRoutingModule
  ],
  providers: [
    KycRestService,
    KycService
  ],
  declarations: [
    KycComponent,
    SummaryKycComponent,
    PersonalDetailsSectionComponent,
    DocumentDetailsSectionComponent,
    PersonalDetailsComponent,
    AddressComponent,
    EditKycDetailsComponent
  ]
})
export class KycModule { }
