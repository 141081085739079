import { Injectable } from '@angular/core';
import {RegistrationRestService} from './registration.rest.service';
import {LoginDetails} from '../../../../onboarding/src/app/shared-module/models/models';
import {StorageService} from '../core-module/storage.service';
import {tap} from 'rxjs/operators';
import {AuthService} from '../core-module/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(
    private restService: RegistrationRestService,
    private storageService: StorageService,
    private authService: AuthService

  ) { }

  login(loginDetails: LoginDetails) {
    return this.restService.login(loginDetails)
      .pipe(
        tap(userTokens => {
          return this.storageService.storeAuthCredentials(userTokens);
        })
      );
  }

  logout() {
    this.authService.logout();
  }

  resetPassword(email: string) {
    return this.restService.resetPassword(email);
  }

}
