<!--IMPORTANT: content-grid container - the one contains tile is rendered based on user's status, so there are 4 instances of them. -->
<!--When making a change in tiles, it needs be done in all instances and tested with 4 different users since layout also changes per user status-->
<!--statuses:-->
<!-- - users without splong invitation code-->
<!-- - users with splong invitation code (not joined or rejected)-->
<!-- - users with sp-long rejected true-->
<!-- - users with sp-long joined true-->

<!--when SP long product will be available for all users, those conditions needs to be reviewed-->

<ng-container *ngIf="(memberData$ | async) as memberData; else loading">
  <ng-container *ngIf="(profileData$ | async) as profileData; else loading">
    <ng-container *ngIf="(updatedHistory$ | async) as updatedHistory; else loading">
      <section class="outstanding-donations">
        <sp-portal-make-donation
          [contributionDetails]="(memberData$ | async)?.contribution"
          [hasMissedContributions]="(memberData$ | async)?.contributionHistory?.hasMissedContributions"
          class="contribution-due">
        </sp-portal-make-donation>
      </section>

      <section class="donation-history">
        <div class="section-header">
          <h4>Donaties</h4>
          <span (click)="navigateTo('contribution-history')">bekijk alle</span>
        </div>
        <ng-container
          *ngIf="memberData.contributionHistory.history && memberData.contributionHistory.history.length > 0; else noDonations">
          <div class="horizontal-scroll">
            <ng-container *ngFor="let donation of updatedHistory">
              <sp-shared-lib-rounded-container class="donation-tile-month"
                                               [ngClass]="donation.status === 'no-donation' ? 'no-donation' : ''"
                                               *ngIf="donation.period">
                <div>
                  <p class="amount">&#8364; {{ donation.amount }}</p>
                  <p class="month">{{ donation.period }}</p>
                  <p *ngIf="donation.status !== 'Niet gedaan'" class="receiver">{{ donation.description }}</p>
                  <p *ngIf="donation.status === 'Niet gedaan'" class="not-paid"> {{ donation.status }}</p>
                </div>
              </sp-shared-lib-rounded-container>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #noDonations>
          <section class="no-contributions">
            <h4>Er zijn nog geen donaties</h4>
          </section>
        </ng-template>
      </section>

      <section class="account">
        <div class="section-header">
        </div>
        <div class="content-grid">
          <sp-shared-lib-rounded-container *ngIf="!memberData.eyecons " class="invest-in-sharepeople tile-green" style="order:1">
            <div class="invest-in-sharepeople__header" [ngStyle]="{'color': 'black'}" > Tijdelijk 3 maanden deelname cadeau</div>
            <div class="invest-in-sharepeople__call-to-action">
                <h4 class="invest-in-sharepeople__subtitle" >Word coöperatielid vanaf €75</h4>
                <p>Verdien je investering supersnel terug én deel mee in onze groei.</p>
                <div class="invest-in-sharepeople__actions" >
                  <button class="button-secondary invest-in-sharepeople__icon-gap"  [style.width]="'15rem'"  [sp-button]  (click)="openPopup()">word coöperatielid <img
                    ngSrc="assets/icons/arrow-right-icon.png" alt="arrow right icon" [ngStyle]="{'filter': 'invert(1)'}"
                    height="24" width="24"/></button>
                  <button class="button-tertiary invest-in-sharepeople__icon-gap" [style.width]="'10rem'"  [sp-button] (click)="openLink('https://sharepeople.nl/cooperatie')" >meer info  <img
                    ngSrc="assets/icons/arrow-right-icon.png" alt="arrow right icon"
                    height="24" width="24"/></button>
                </div>
            </div>
          </sp-shared-lib-rounded-container>
          <sp-shared-lib-rounded-container class="tile-light-blue" style="order:1"
                                           [ngStyle]="{'order': memberData.eyecons ? 2 : 1}"
          >
            <ng-container [ngTemplateOutlet]="yourAccount"
                          [ngTemplateOutletContext]="{ memberData: memberData }"></ng-container>
          </sp-shared-lib-rounded-container>


          <sp-shared-lib-rounded-container class="sp-aov-first-payment tile-yellow"
                                           *ngIf="memberData.aov.eligibleForAovFirstPayment" style="order:2">
            <div class="container-inner">
              <h4>SharePeople AOV</h4>
              <p>
                Betaal <a (click)="openAOVPayment()" target="_blank">hier</a> je eerste premie
                van {{ memberData.aov.firstFee | currency:'EUR' }}.
              </p>
            </div>
          </sp-shared-lib-rounded-container>

          <sp-shared-lib-rounded-container class="zzp-self-test tile-light-blue"
                                           [ngStyle]="{'order': !memberData.aov.subscription ? 4 : 3}">
            <div class="container-inner">
              <h4 class="subtitle">ZZP-Zelftest</h4>
              <p *ngIf="!(memberData$ | async).lastSelfTestDate" class="zzp-self-test__intro-text">
                Goed voor jezelf zorgen is belangrijk, zeker als ondernemers. Maar, hoe doe je dat?
              </p>
              <p *ngIf="(memberData$ | async).lastSelfTestDate" class="zzp-self-test__intro-text">Goed voor jezelf zorgen is
                belangrijk. En, hoe sta jij er nu voor?</p>
            </div>
            <br/>
            <ng-container>
              <div class="zzp-self-test__second-part">
                <!--              Depending on the zelf test score we show either: both buttons or one of the two-->
                <ng-container
                  *ngIf="!(memberData$ | async).lastSelfTestDate || (memberData$ | async).lastSelfTestMonths >= 6">
                  <button (click)="startSelfTest()" class="zzp-self-test__button"
                          [style.max-width]="memberData.lastSelfTestDate ? '65%' : '100%'">doe de ZZP-Zelftest <img
                    ngSrc="assets/icons/arrow-right-icon.png" alt="arrow right icon"
                    height="24" width="24"/></button>
                </ng-container>
                <ng-container *ngIf="(memberData$ | async).lastSelfTestDate">
                  <button
                    [ngClass]="
                        'zzp-self-test__button' "
                    [style.max-width]="memberData.lastSelfTestDate ? '75%' : '100%'"
                    (click)="navigateToSelfTestResults()"
                  >
                    bekijk resultaten <img ngSrc="assets/icons/arrow-right-icon.png" height="24" width="24"
                                           alt="arrow right icon"/></button>
                </ng-container>
              </div>

            </ng-container>
            <p class="zzp-self-test__offer">
              Of bekijk het aanbod:
              <a class="zzp-self-test__link" href="https://www.sharepeople.nl/gezond-ondernemen/">Gezond ondernemen</a>
            </p>

          </sp-shared-lib-rounded-container>

          <sp-shared-lib-rounded-container class="tile-light-blue"
                                           [ngStyle]="{'order': !memberData.aov.subscription ? 3 : 4}">
            <ng-container [ngTemplateOutlet]="callInSick"
                          [ngTemplateOutletContext]="{ memberData: memberData, profileData: profileData }"></ng-container>
          </sp-shared-lib-rounded-container>


          <ng-template #inviteAFriend>
            <form class="invite-a-friend" [formGroup]="memberInviteForm" #form="ngForm">
              <div class="invite-a-friend--link">
                <div class="invite-a-friend--link__header">
                  <h4 class="invite-a-friend--link__header--dark-text">3 maanden gratis </h4>
                  <h4>deelname cadeau</h4>
                </div>
                <p>Voor jou en iedereen die SharePeople-deelnemer wordt via jouw persoonlijke uitnodiging.</p>
                <br/>
                <div class="invite-a-friend__share__input" (mouseover)="openModal()">
                  <button class="invite-a-friend__button" cdkOverlayOrigin #trigger="cdkOverlayOrigin" disabled>deel
                    jouw link
                  </button>
                </div>
              </div>
              <div class="invite-a-friend--share">
                <sp-input-wrapper
                  label="Voor- en achternaam"
                  [extraClasses]="'sharing-label'"
                  [error]="form.submitted && !memberInvited ? memberInviteForm.get('name').errors : null">
                  <input type="text" formControlName="name">
                </sp-input-wrapper>
                <sp-input-wrapper
                  label="E-mailadres"
                  [extraClasses]="'sharing-label'"
                  [error]="form.submitted && !memberInvited ? memberInviteForm.get('email').errors : null">
                  <input type="email" formControlName="email">
                </sp-input-wrapper>
                <p *ngIf="memberInvited">Bedankt! Voeg meer toe?</p>
                <p class="error-message" *ngIf="displayMemberInviteError">Probeer het a.u.b. opnieuw.</p>
                <button class="invite-a-friend__button" (click)="sendMemberInvite()"><p
                  class="invite-a-friend__button--btn-text">uitnodigen</p> <img src="assets/icons/arrow-right.png"
                                                                                width="24" height="24"/></button>
              </div>
            </form>
            <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                         [cdkConnectedOverlayOpen]="shareIconsOpen" [cdkConnectedOverlayPositions]="tooltipPositions">
              <div class="sharing-buttons" (mouseleave)="this.shareIconsOpen = false">
                <div class="sharing-buttons__icon" (click)="openMail()">
                  <img class="icon" alt="mail icon" src="assets/icons/icon-mail.png">
                </div>
                <div class="sharing-buttons__icon" (click)="navigateToWhatsapp()">
                  <img class="icon" alt="whatsapp icon" src="assets/icons/whatsapp.svg">
                </div>
                <!--                spPortalCopyText [copyText]="triggerCopyAlert()"-->
                <div class="sharing-buttons__icon" (click)="copyText()">
                  <img class="icon" alt="link icon" src="assets/icons/icon-link.png">
                </div>
              </div>
            </ng-template>
          </ng-template>

          <sp-shared-lib-rounded-container class="tile-yellow"
                                           [ngStyle]="{'order': !memberData.aov.subscription ? 5 : 6}"
                                           *ngIf="memberData.canApplyForAov || memberData.spLongCoverage===50000">
            <div class="container-inner">
              <h4>Voor jou: De SharePeople AOV</h4>
              <p>
                Meld je nu aan voor onze groeps-AOV. Met dekking tot aan de AOW-leeftijd én een geldige opt-out voor de
                verplichte AOV.</p>
              <p><a (click)="startAovOnboarding()">Regel 't nu</a>.</p>
            </div>
          </sp-shared-lib-rounded-container>


          <!--          We first check if there is a medical Assessment Code is present and then check for VEO4 which means the Medical Assessment is finished, so we don't have to show this tile as well. -->
          <ng-container
            *ngIf="memberData$?.getValue()?.medicalAssessment?.code && memberData$?.getValue()?.medicalAssessment?.code !== 'VE04'">
            <sp-shared-lib-rounded-container class="tile-yellow"
                                             [ngStyle]="{'order': !memberData.aov.subscription ? 5 : 6}">
              <div class="container-inner"
                   (click)="navigateMedicalPage('https://www.sharepeople.nl/MABstatus_' +  memberData$.getValue()?.medicalAssessment?.code)">
                <h4>Aanvraag SharePeople AOV</h4>
                <p>
                  Jouw Medische verklaring is in behandeling bij het Medisch Adviesbureau en heeft per {{
                    currentDate.toLocaleDateString("nl-NL", {
                      weekday: undefined,
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })
                  }} de status:</p>
                <br/>
                <b>{{ memberData$?.getValue()?.medicalAssessment.status }}</b>
              </div>
            </sp-shared-lib-rounded-container>
          </ng-container>


          <sp-shared-lib-rounded-container class="tile-light-blue"
                                           [ngStyle]="{'order': !memberData.aov.subscription ? 6 : 5}">
            <ng-container [ngTemplateOutlet]="moreInfo"></ng-container>
          </sp-shared-lib-rounded-container>

          <ng-container *ngIf="memberData$?.getValue()?.eyecons">
            <sp-shared-lib-rounded-container class="invite-a-friend" >
              <div class="container-inner">
                <ng-container [ngTemplateOutlet]="inviteAFriend"
                              [ngTemplateOutletContext]="{ memberData: memberData }"></ng-container>
              </div>
            </sp-shared-lib-rounded-container>
          </ng-container>
        </div>


      </section>

      <sp-portal-aov-terms-popup [AOVTermsPopupActive]="aovTermsPopup" [fee]="memberData.aov.monthlyFee"
                                 [paymentUrl]="memberData.aov.paymentUrl"
                                 (close)="aovTermsPopup =false"></sp-portal-aov-terms-popup>
      <sp-portal-long-warning-popup [longWarningPopupActive]="longWarningPopup" [spLongUrl]="linkToSpLong"
                                    (close)="longWarningPopup=false;pollMemberData()"></sp-portal-long-warning-popup>

    </ng-container>
  </ng-container>
</ng-container>

<div class="popup-background" [class.active]="eyevestorPopupActive">
  <div class="popup-container">
    <div class="flex-container">
      <div class="close-button" (click)="eyevestorPopupActive = false">
        <ng-container *ngTemplateOutlet="crossIcon"></ng-container>
      </div>
      <p *ngIf="popupLoading">Loading...</p>
      <p *ngIf="!popupLoading">{{ popupText }}</p>
    </div>
  </div>
</div>

<sp-portal-occupation-popup></sp-portal-occupation-popup>
<!--<sp-portal-extended-coverage-popup [extendedCoverageOptions]="extendedCoverageOptions"></sp-portal-extended-coverage-popup>-->

<ng-template #loading>
  <sp-loading-spinner></sp-loading-spinner>
</ng-template>

<ng-template #crossIcon>
  <svg style="fill: #46b278" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492">
    <path
      d="M300 246L484 62a27 27 0 000-38L468 8a27 27 0 00-38 0L246 192 62 8a27 27 0 00-38 0L8 24a27 27 0 000 38l184 184L8 430a27 27 0 000 38l16 16a27 27 0 0038 0l184-184 184 184a27 27 0 0038 0l16-16a27 27 0 000-38L300 246z"/>
  </svg>
</ng-template>

<ng-template #checkmark>
  <svg style="fill: #46b278" xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
    <path
      d="M4.757 7.525L10.205.393a1 1 0 0 1 1.59 1.214l-6.111 8a1 1 0 0 1-1.47.131L.325 6.183a1 1 0 0 1 1.35-1.477l3.082 2.819z"/>
  </svg>
</ng-template>


<ng-template #callInSick let-memberData="memberData" let-profileData="profileData">
  <ng-container *ngIf="!memberData.reportedSick">
    <h4>Ziek melden</h4>
    <p>Ben je ziek en verwacht je dat dit langer dan een maand gaat duren?</p>
    <!-- WARNING: Please take note that sickness report is not yet implemented, until it is we should go to the external link -->
    <!--    <p><a [routerLink]="'report'" >Klik hier</a> om je ziek te melden.</p>-->
    <p><a
      href="https://www.sharepeople.nl/a15rgw35ws76f/?spid={{memberData.memberCode}}&voornaam={{profileData.firstName}}&achternaam={{profileData.lastName}}&email={{profileData.email}}&gebdd={{profileData.dob}}"
      target="_blank">Klik hier</a> om je ziek te melden.</p>
  </ng-container>
  <ng-container *ngIf="memberData.reportedSick">
    <h4>Ziek gemeld</h4>
    <p>Eerste ziektedag: {{ memberData.reportedSick }}</p>
  </ng-container>
</ng-template>

<ng-template #yourAccount let-memberData="memberData">
  <div class="container-inner">
    <h4 class="your-account__header">Jouw account</h4>
    <p class="accountStatus">Status: Actief</p>
    <p class="accountMemberSinceDate">Deelnemer sinds: {{ memberData.startDate }}</p>
    <br/>
    <p class="memberGroupName">Groep: {{ memberData.group }}</p>
    <p class="insuredAmount"><a
      [routerLink]="'change-contribution'">Inkomensniveau: {{ memberData.coverage | spCurrency }} <span
      class="pencil"></span>
    </a></p>
    <p class="sicknessPercentage">Ziektepercentage in jouw groep: {{ memberData.utilisationInfo.spShort }}%</p>
    <ng-container *ngIf="memberData?.spLongJoined">
      <br/>
      <p>SharePeople Lang</p>
      <p><a
        [routerLink]="'change-contribution'">Inkomensniveau: {{ memberData.spLongCoverage | spCurrency }} <span
        class="pencil"></span></a></p>
      <p class="sicknessPercentage">Ziektepercentage in jouw groep: {{ memberData.utilisationInfo.spLong }}%</p>
    </ng-container>
  </div>
</ng-template>

<ng-template #invest>
  <div class="first-part">
    <h4 class="subtitle">Wil je ook lid worden van de SharePeople Cooperatie?</h4>
    <p><a href="https://sharepeople.nl/cooperatie" target="_blank">Klik dan hier</a> voor meer informatie.</p>
    <p><a (click)="openPopup()">Klik vervolgens hier</a> om een account in Eyevestor aan te maken.</p>
  </div>
  <div class="second-part">
    <h4 class="subtitle">Investeringsronde {{ currentDate.getFullYear() }}</h4>
  </div>
</ng-template>

<ng-template #moreInfo>
  <h4>{{ customContentBlockData?.title }}</h4>
  <p>{{ customContentBlockData?.description }}</p>
  <button [sp-button] (click)="gotoCustomContentLink()">Lees meer</button>
</ng-template>
