import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'button [sp-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input('sp-button') spButton;

  constructor() { }

  ngOnInit() {
  }

}
