import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyKey'
})
export class PrettyKeyPipe implements PipeTransform {

  transform(value) {
    const wordBreakIndex = [];
    const letterArray = value.split('').map(
      (letter, i) => {
        if (letter === letter.toUpperCase()) {
          wordBreakIndex.push(i);
          return letter.toLowerCase();
        } else {
          return letter;
        }
      }
    );
    wordBreakIndex.forEach((wordBreak, i) => {
      letterArray.splice(wordBreak + i, 0, ' ');
    });
    letterArray[0] = letterArray[0].toUpperCase();
    return letterArray.join('');
  }
}
