import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ChangeContributionComponent} from './change-contribution/change-contribution.component';
import {ReportComponent} from './report/report.component';
import { MyAreaComponent } from './my-area.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {ProfileComponent} from './profile/profile.component';
import {ContributionHistoryComponent} from './contribution-history/contribution-history.component';
import {SelfTestComponent} from "./dashboard/self-test/self-test.component";
import {SelfTestAdviceComponent} from "./dashboard/self-test-advice/self-test-advice.component";



const routes: Routes = [
  {
    path: '',
    component: MyAreaComponent,
    children: [
      // {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
      {path: '', component: DashboardComponent},
      {path: 'profile', component: ProfileComponent},
      {path: 'change-contribution', component: ChangeContributionComponent},
      {path: 'contribution-history', component: ContributionHistoryComponent},
      {path: 'self-test', component: SelfTestComponent},
      {path: 'self-test-advice', component: SelfTestAdviceComponent},
      {path: 'report', component: ReportComponent},
      {path: '**', redirectTo: ''}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAreaRoutingModule { }
