import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {KycRestService} from './kyc.rest.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {KycData} from '../models/kyc-data.model';

@Injectable({
  providedIn: 'root'
})
export class KycService {

  /*
    This module uses a single state object (kycData) to make it easier to be sure
     the form state is consistent across the module.
    All components listen to the kycData$ observable for their own data.
    All components push any updates to the kycData via the saveKycData() method which
     will store and then update the kycData$ Observable that all the components are listening to.
   */

  _kycData$: BehaviorSubject<KycData> = new BehaviorSubject<KycData>(null);
  kycData$: Observable<KycData> = this._kycData$.asObservable();

  constructor(
    private kycRest: KycRestService
  ) {
    this.kycRest.getKycData().subscribe(
      kycData => {
        this._kycData$.next(kycData);
      }
    );
  }

  private getCurrentState(): KycData {
    return this._kycData$.getValue();
  }

  updateKycData(updatedKycData: KycData) {
    const currentState = this.getCurrentState();
    const updatedState = {...currentState, ...updatedKycData};
    const post = this.kycRest.postKycData(updatedState);
    post.subscribe(
      kycData => {return this._kycData$.next(kycData);}
    );
    return post;
  }
}
