import {Component, OnInit} from '@angular/core';
import {TcOverlayService} from '../../tc-overlay/tc-overlay.service';
import {OnboardingService} from '../../../onboarding-module/onboarding.service';
import {Router} from '@angular/router';
import {OverlayConfig} from '../../models/models';
import {StorageService} from '../../../core-module/services/storage.service';
import {ThemeService} from '../../../../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {Observable} from 'rxjs';
import {OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';

@Component({
  selector: 'sp-promise',
  templateUrl: './promise.component.html',
  styleUrls: ['./promise.component.scss']
})


export class PromiseComponent implements OnInit {

  promiseAccepted = false;
  TCAccepted = false;
  productName: string;
  onboardingType$: Observable<any>;
  stepper = 3;
  footerConfig = {
    btnType: FooterButtonType.CLICKEVENT,
    footerBtnText: 'Ga verder',
    outputBtnClickEvent: () => {return this.submit();}
  };

  constructor(
    private tcOverlayService: TcOverlayService,
    private onboardingService: OnboardingService,
    private onboardingTypeService: OnboardingTypeService,
    private storageService: StorageService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.retrievePromise();
    const onboardingData = this.storageService.getOnboardingData();
    this.productName = onboardingData.productName;
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
    this.stepper = this.onboardingTypeService.returnOnboardType$getValue()==='SHORT'?4:3;
  }

  retrievePromise() {
    this.promiseAccepted = this.onboardingService.retrievePromise();
  }

  openOverlay(overlay: OverlayConfig) {
    this.tcOverlayService._overlay.next(overlay);
  }

  getPromiseOverlay() {
    this.onboardingService.getPromiseHTML()
      .subscribe(
        promiseHTML => {
          this.openOverlay({
            overlayType: 'promise',
            overlayContent: promiseHTML.promises ? promiseHTML.promises : promiseHTML.promise
          });
        }
      );
  }

  getTermsOverlay() {
    this.openOverlay({
      overlayType: 'terms',
      overlayContent: ''
    });
  }

  getPrivacyOverlay() {
    this.openOverlay({
      overlayType: 'privacy',
      overlayContent: ''
    });
  }

  submit() {
    if (this.promiseAccepted && this.TCAccepted) {
      this.onboardingService.getAndStorePromise(this.promiseAccepted)
        .subscribe(
          (data: any) => {
            const confirmationInfo = {
              memberCode: data.memberCode,
              amount: data.amount,
              amountIncVat: data.amountIncVat,
              monthlyMembershipFee: data.monthlyMembershipFee,
              monthlyMembershipFeeIncVat: data.monthlyMembershipFeeIncVAT,
              discount: data.discount
            };

            this.onboardingService.setCurrentStep('/confirmation');
            this.router.navigate(['confirmation'], {state: confirmationInfo});
          }
        );
    }
  }
}
