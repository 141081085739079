import { Component, OnInit } from '@angular/core';
import {KycService} from '../services/kyc.service';
import {Observable} from 'rxjs';
import {KycData} from '../models/kyc-data.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'sp-portal-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {

  personalDetailsForm: UntypedFormGroup;
  kycData$: Observable<KycData>;

  constructor(
    private kycService: KycService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.kycData$ = this.kycService.kycData$;
    this.createForm();
    this.subscribeToUpdates();
  }

  submit() {
    if (this.personalDetailsForm.valid) {
      this.kycService.updateKycData({...this.personalDetailsForm.value})
        .subscribe(() => {return this.goToSummary();});
    } else {
      this.personalDetailsForm.markAllAsTouched();
    }
  }

  private createForm() {
    this.personalDetailsForm = this.formBuilder.group({
      firstName: ['', []],
      lastName: ['', []],
      legalForm: ['', []],
      phone: ['', []],
      gender: ['', []],
      street: ['', []],
      dateOfBirth: ['', []],
      zipcode: ['', []],
      houseNumber: ['', []],
      city: ['', []],
      placeOfBirth: ['', []],
      nationality: ['', []],
      addition: ['', []],
      country: ['', []],
      email: ['', []],
    });
  }

  private subscribeToUpdates() {
    this.kycData$.subscribe(
      (kycData: KycData) => {
        if (kycData) {
          this.personalDetailsForm.patchValue(kycData);
        }
      }
    );
  }

  private goToSummary() {
    this.router.navigate(['./kyc/summary']);
  }
}
