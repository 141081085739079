/*
 * Public API Surface of sp-shared-lib
 *
 * You need to expose the main module using:
 * export * from './lib/sp-shared-lib.module';
 *
 * The components, pipes, services, directives etc should be exposed individually
 * In the apps you can consume them using:
 * import {SpCurrencyPipe} from 'sp-shared-lib';
 */



export * from './lib/sp-shared-lib.module';
export { SpCurrencyPipe } from './lib/shared/pipes/sp-currency.pipe';
export {AvatarCardComponent} from './lib/shared/avatar-card/avatar-card.component';
export {LoadingSpinnerComponent} from './lib/shared/loading-spinner/loading-spinner.component';
export {RoundedContainerComponent} from './lib/shared/rounded-container/rounded-container.component';
export {PrettyKeyPipe} from './lib/shared/pipes/pretty-key.pipe';
export {SharedModule} from './lib/shared/shared.module';
