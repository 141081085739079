<div class="self_test-results">
  <ng-container *ngIf="testData">
    <div class="self_test-results__buttons">
      <div class="self_test-results__tab">Testscore {{ testData[0]?.completedDate}}</div>
      <div class="self_test-results__tab advice">Advies</div>
    </div>
  </ng-container>

  <ng-container *ngIf="themes" >
    <div class="self_test-results__health-pop-up">
      <h3>Advies</h3>
      Jij bent goed bezig om gezond te (blíjven) ondernemen. Mooi! Als SharePeople zetten wij ons er natuurlijk voor in om
      de zieke deelnemers goed te begeleiden. Maar wist je dat we er ook zijn voor gezonde deelnemers? Op onze Gezond
      ondernemen-pagina vind je blogs, workshops en cursussen die jou helpen om goed voor jezelf als ondernemer te blijven
      zorgen. Op alle vlakken.
      <button class="self_test-results__health-pop-up__button hover-class" (click)="navigateToHealthPage()" >ga aan de slag</button>
    </div>

  </ng-container>
  <div class="self_test-results__accordions" >
    <div  *ngFor="let theme of  testData[0]?.scores; let i = index">
      <sp-portal-accordion
        [themeName]="AccordionThemes[theme.group]"
        [score]="theme.average"
        (emmitCollapseStateAccordion)="collapseAccordions($event, i)"
        [collapsed]="accordionStates[i]"
      > </sp-portal-accordion>
    </div>
  </div>
</div>
