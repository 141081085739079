import { Component, OnInit } from '@angular/core';
import {UserProfile} from '../profile/profile.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {MyAreaService} from '../my-area.service';
import {EMPTY, Observable, throwError} from 'rxjs';
import {MemberData} from '../dashboard/dashboard.component';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'sp-portal-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  reportSicknessForm: UntypedFormGroup;
  defaultAddress: UntypedFormGroup;
  memberData$: Observable<MemberData>;
  profileData$: Observable<UserProfile>;
  fullName: string;
  iban: string;
  dob: string;
  email: string;
  memberCode: string;
  submitSuccessful = false;
  submitFail = false;
  submitFailMessage: string;

  constructor(private formBuilder: UntypedFormBuilder, private myAreaService: MyAreaService, private router: Router) { }

  ngOnInit() {
    this.createReportForm();
    this.memberData$ = this.myAreaService.memberData$;
    this.profileData$ = this.myAreaService.profile$;
    this.autoFill();
  }

  createReportForm() {
    this.reportSicknessForm = this.formBuilder.group({
      bsn: ['', [Validators.required, CustomValidators.bsn(9)]],
      startDate: ['', [Validators.required]],
      hoursPerWeek: [null, [Validators.required, CustomValidators.maxHours(40) ] ],
      hoursCurrentPerWeek: [null, [Validators.required, CustomValidators.maxHours(40) ] ],
      absenceExpected: ['', [Validators.required]],
      industrialAccident: ['', [Validators.required]],
      stressRelated: ['', [Validators.required]],
      aov: ['', [Validators.required]],
      aovWaitingTime: [''],
      contact: this.formBuilder.group({
        person: [''],
        phone: [''],
        email: ['']
      })
    });
  }

  autoFill() {
    this.profileData$.subscribe(profileData => {
      this.fullName = profileData.firstName + ' ' + profileData.lastName;
      this.email = profileData.email;
      this.dob = profileData.dob;
    });
    this.memberData$.subscribe(memberData => {
      console.log(memberData);
      this.memberCode = memberData.memberCode;
    });
  }

  submitReport() {
    if (this.reportSicknessForm.valid) {
      const {
        bsn,
        startDate,
        hoursPerWeek,
        hoursCurrentPerWeek,
        absenceExpected,
        industrialAccident,
        stressRelated,
        aov,
        aovWaitingTime,
        defaultAddress
      } = this.reportSicknessForm.value;
      const form = {
        bsn,
        startDate,
        hoursPerWeek,
        hoursCurrentPerWeek,
        absenceExpected,
        industrialAccident,
        stressRelated,
        aov,
        aovWaitingTime,
        defaultAddress
      };

      this.myAreaService.reportSickness(form)
        .pipe(
          catchError((origErr) => {
            console.log(origErr);

            if (origErr.status === 400) {
              this.submitFail = true;
              this.submitFailMessage = origErr.error.error;

            } else if (origErr.status === 422) {
              return EMPTY;
            } else {
              console.log(origErr);
              return throwError(origErr);
            }
          })
        )
        .subscribe(
          (resp: any) => {
            console.log('submitSuccessful', resp);
            if (resp.status && resp.status === 'Ok') {
              this.submitSuccessful = true;
              console.log('Status OK');
              this.router.navigate(['dashboard']);
            }
          }
        );
    }
  }
}
