import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {delay} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {KycData} from '../models/kyc-data.model';



@Injectable({
  providedIn: 'root'
})
export class KycRestService {

  constructor(
    private http: HttpClient
  ) { }

  getKycData(): Observable<KycData> {
    return this.http.get<KycData>(`${environment.api_url}/kyc/accountInfo`);
  }

  postKycData(kycData: KycData): Observable<KycData> {
    return this.http.patch<KycData>(`${environment.api_url}/kyc/accountInfo`, {...kycData});
    // this.kycData = kycData;
    // console.log('posted', kycData);
    // return of(this.kycData)
    //   .pipe(delay(1200));
  }
}
