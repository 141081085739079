import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  Address,
  ContactDetails,
  KvkDetails,
  MembershipPayment,
  PaymentStatus, RegCode,
  SpPaymentRequest
} from '../shared-module/models/models';

import * as Sentry from '@sentry/browser';

export interface CoverageLevelOptions {
  code: string;
  coverageLevels: CoverageLevel[];
  usps: string[];
  discount: number;
  maxAge: number;
  partnerName: string;
  productName: string;
  encryptedUserId?: string;
  hashedEmail?: string;
  style?: string;
}

export interface CoverageLevel {
  amount: number;
  averageMonthlyCost: number;
}

@Injectable({
  providedIn: 'root'
})
export class OnboardingRestService {
  public regCodeFallback: any | string; // this is a fallback regCodeFallback that is updated every call.

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  getCoverageLevelOptions(code: string, externalId: string, encryptedUserId?: string, hashedEmail?: string, style?: string, occupation?: number, splong?: boolean): Observable<CoverageLevelOptions> {
    return this.http.post<CoverageLevelOptions>(`${environment.api_url}/signup/showOptions`, {code, externalId, encryptedUserId, hashedEmail, style, occupation, splong})
      .pipe(
        tap(response => {return this.storeRegCodeIfAvailable(response);}),
        catchError<CoverageLevelOptions, Observable<never>>(err => {
          return this.checkForRegCodeError(err);
        })
      );
  }

  getMemberCode(registrationCode: string, invitationCode: string) : Observable<string> {
    return this.http.post<string>(`${environment.api_url}/signup/memberCode`, {registrationCode, invitationCode})
  }

  postNameAndContact(contactForm: ContactDetails,  hasUnderstood: boolean, encryptedUserId?: string, hashedEmail?: string, externalId?: string): Observable<RegCode> {
    return this.http.post<RegCode>(`${environment.api_url}/signup/personalInfo`, {...contactForm, externalId, encryptedUserId, hashedEmail})
      .pipe(
        tap(response => {return this.storeRegCodeIfAvailable(response);}),
        catchError<RegCode, Observable<never>>(err => {
          return this.checkForRegCodeError(err);
        })
      );
  }

  postAddress(address: Address, regCode, encryptedUserId?: string, hashedEmail?: string): Observable<any> {
    return this.http.post<string>(`${environment.api_url}/signup/address`, {
      registrationCode: regCode,
      address: address.street + ' '+ address.number,
      postalCode: address.postalCode,
      city: address.city,
      encryptedUserId: encryptedUserId,
      hashedEmail: hashedEmail
    }).pipe(
      tap(response => {return this.storeRegCodeIfAvailable(response);}),
      catchError<any, Observable<never>>(err => {
        return this.checkForRegCodeError(err);
      })
    );
  }

  checkKvk(kvk, regCode, encryptedUserId?: string, hashedEmail?: string): Observable<KvkDetails> {
    return this.http.post<KvkDetails>(`${environment.api_url}/signup/getCompanyInfo`, {
      kvk,
      encryptedUserId: encryptedUserId,
      hashedEmail: hashedEmail,
      registrationCode: regCode}).pipe(
      tap(response => {return this.storeRegCodeIfAvailable(response);}),
      catchError<KvkDetails, Observable<never>>(err => {
        return this.checkForRegCodeError(err);
      })
    );
  }

  postCompany(kvk, company, registrationCode: string): Observable<KvkDetails> {
    return this.http.post<KvkDetails>(`${environment.api_url}/signup/company`, {
      kvk,
      companyName: company,
      registrationCode,
    })
      .pipe(
        tap(response => {return this.storeRegCodeIfAvailable(response);}),
        catchError<KvkDetails, Observable<never>>(err => {
          return this.checkForRegCodeError(err);
        })
      );
  }

  postPromiseAcceptance(promiseAccepted: boolean, registrationCode: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.api_url}/signup/promise`, {promiseAccepted, registrationCode})
      .pipe(
        tap(response => {return this.storeRegCodeIfAvailable(response);}),
        catchError<boolean, Observable<never>>(err => {
          return this.checkForRegCodeError(err);
        })
      );
  }

  postPromiseProspectsAcceptance(criteriaAccepted: {acceptPromise: boolean, acceptPrivacy: boolean}, registrationCode: string): Observable<any> {
    return this.http.post<boolean>(`${environment.api_url}/signuplong/promises/${registrationCode}`, criteriaAccepted)
      .pipe(
        tap(response => {return this.storeRegCodeIfAvailable(response);}),
        catchError<boolean, Observable<never>>(err => {
          return this.checkForRegCodeError(err);
        })
      );
  }

  requestNewPayment(registrationCode: string, incassoAgreement: boolean) {
    const usePaymentAccountForIncasso = incassoAgreement;
    return this.http.post(`${environment.api_url}/signup/requestPayment`, {
      registrationCode, usePaymentAccountForIncasso
    }) as Observable<SpPaymentRequest>;
  }

  postPaymentStatus(registrationCode, isBrightPensioenMember): Observable<PaymentStatus> {
    return this.http.post<PaymentStatus>(`${environment.api_url}/signup/mollie/paymentStatus`, {registrationCode, isBrightPensioenMember})
      .pipe(
        tap(response => {return this.storeRegCodeIfAvailable(response);}),
        catchError<PaymentStatus, Observable<never>>(err => {
          return this.checkForRegCodeError(err);
        })
      );
  }

  postMembershipPayment(membershipPayment: MembershipPayment) {
    return this.http.post<MembershipPayment>(`${environment.api_url}/signup/membershipFee`, membershipPayment)
      .pipe(
        tap(response => {return this.storeRegCodeIfAvailable(response);}),
        catchError<MembershipPayment, Observable<never>>(err => {
          return this.checkForRegCodeError(err, false);
        })
      );
  }

  getOverlayHTML(registrationCode: string) {
    return this.http.post<any>(`${environment.api_url}/signup/promiseForProduct`, {registrationCode});
  }

  postMgmInvitedBy(registrationCode, invitedUser) {
    return this.http.post<any>(`${environment.api_url}/signup/mgmInvitedBy`, {registrationCode, invitedUser})
      .pipe(
        tap(response => {return this.storeRegCodeIfAvailable(response);}),
        catchError<any, Observable<never>>(err => {
          return this.checkForRegCodeError(err);
        })
      );
  }

  getDiscountCodeRedirectUrl(discountCode: string): Observable<string | null> {
    return this.http.get(`${environment.api_url}/signup/redirectUrl?discountCode=${discountCode}`)
      .pipe(map(response => response['redirectUrl']),
        catchError<any, Observable<never>>(err => {
          return null;
        }));
  }

  private storeRegCodeIfAvailable(response) {
    if (response && response.registrationCode) {
      console.log('storing fallback regCode:', response.registrationCode);
      this.regCodeFallback = response.registrationCode;
    }
  }

  private checkForRegCodeError(err: any, redirect = true) {
    if (err.status === 400) {
      this.clearLSAndReturnUserToStart();
      if (redirect) {
        this.throwSentryRegCodeError(err);
      }
    } else {
      return throwError(err);
    }
  }

  throwSentryRegCodeError(err) {
    const onboardingData = window.localStorage.getItem('onboardingData');
    Sentry.setContext('localstorage_attributes', {
      type: 'Server called with no RegCode',
      err: err.err,
      onboardingData: onboardingData,
    });
    Sentry.captureException(new Error('Server called with no RegCode'));
  }

  private clearLSAndReturnUserToStart() {
    console.log('something went wrong');
    window.localStorage.clear();
    this.router.navigate(['/']);
  }

  postToLoggingEndpoint(payload: any) {
    return this.http.post<any>(`${environment.api_url}/util/log`, payload);
  }
}




