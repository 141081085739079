import {Component, ElementRef, Input, OnInit, TemplateRef} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';

export enum FooterButtonType {
  NAVIGATION = 'navigation',
  CLICKEVENT = 'clickEvent',
  FORMSUBMIT = 'formSubmit'
}

//TODO: make sure to hande all buttons to be disabled if a form invalid

@Component({
  selector: 'sp-onboard-page-content-wrapper',
  templateUrl: './onboard-page-content-wrapper.component.html',
  styleUrls: ['./onboard-page-content-wrapper.component.scss']
})
export class OnboardPageContentWrapperComponent implements OnInit {
  @Input() showStepper = false;
  @Input() showFooterNote = false;
  @Input() formId: FormGroupDirective;
  @Input() footerNoteHtml: TemplateRef<any>;
  @Input() btnDisabled: boolean;
  @Input() displayFooter = true;

  @Input() stepperConfig: {
    currentStep: number,
    currentSubStep?: number,
    onboardingType: string
  };

  @Input() footerConfig: {
    btnType: FooterButtonType,
    navigateTo?: string,
    footerBtnText: string,
    outputBtnClickEvent?: any,
    noteBefore?: boolean,
    footerNote?: string
  } | undefined;

  constructor() {
  }

  ngOnInit(): void {}

  triggerFormSubmit() {
    this.formId.onSubmit(undefined);
    this.footerConfig.outputBtnClickEvent();
  }
}
