import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OnboardingService} from '../../../onboarding-module/onboarding.service';
import {OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';
import {GoogleAnalyticsService} from '../../../core-module/services/google-analytics.service';
import {StorageService} from '../../../core-module/services/storage.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'sp-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, OnDestroy {

  usps: string[];
  productName = 'SharePeople';
  onboardingType$: Observable<any>;
  development: boolean;

  constructor(
    private route: ActivatedRoute,
    private onboardingService: OnboardingService,
    private onboardingTypeService: OnboardingTypeService,
    private storageService: StorageService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {  }

  ngOnInit() {
    this.development = isDevMode();

    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
    // Clearing the localStorage, removing any previously stored `discountCode` and making sure we start a brand new onboarding:
    this.storageService.clearAllCredentials();
    const params = this.route.snapshot.queryParams;
    if (params?.code) {
      this.storeDiscountCode(params.code);
    } /*else if (this.route.snapshot.params.discountCode) {
      const discountCodeFromRoute = this.route.snapshot.params.discountCode;
      this.storeDiscountCode(discountCodeFromRoute);
    }*/
    this.storeQueries();
  }

  private storeQueries() {
    // At the point this is run, LS contains the new discountcode but old everything else
    // Then something gets cleared in getAndStoreCoverageLevelOptionsAndUSPs before this actually saves data
    const externalId = this.route.snapshot.queryParams?.externalid;
    const encryptedUserId = this.route.snapshot.queryParams?.userId;
    const hashedEmail = this.route.snapshot?.queryParams?.email;
    this.onboardingService.storeQueries(externalId, encryptedUserId, hashedEmail);
    this.googleAnalyticsService.init({
      onlyProduction: true
    });
    // this.onboardingService.getAndStoreCoverageLevelOptionsAndUSPs(externalId, encryptedUserId, hashedEmail).subscribe(
    //   optionsAndUsps => {
    //     this.usps = optionsAndUsps.usps;
    //     this.productName = optionsAndUsps.productName;
    //     // Since the getAndStoreCoverageLevelOptionsAndUSPs method has called next on the $onboardingStyle observable,
    //     // we can now init the GA service:
    //     this.googleAnalyticsService.init({
    //       onlyProduction: true
    //     });
    //   }
    // );
  }

  isThereACodeInStorage() {
    return !!this.onboardingService.retrieveDiscountCode();
  }

  storeDiscountCode(code: string) {
    this.onboardingService.storeDiscountCode(code);
  }

  ngOnDestroy() {
    this.onboardingService.setCurrentStep();
  }
}
