import {Component, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CustomValidators} from '../../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {OnboardingService} from '../../../onboarding-module/onboarding.service';
import {ContactDetails, RegCode} from '../../models/models';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {formatDate} from '@angular/common';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';

@Component({
  selector: 'sp-name-and-contact',
  templateUrl: './name-and-contact.component.html',
  styleUrls: ['./name-and-contact.component.scss']
})
export class NameAndContactComponent implements OnInit, OnDestroy {
  contactForm: UntypedFormGroup;
  maxAge: number;
  onboardingType$: Observable<any>;
  footerConfig = {btnType: FooterButtonType.FORMSUBMIT, footerBtnText: 'Ga verder', outputBtnClickEvent: () => {return this.submit();}};
  stepper = 2;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private onboardingService: OnboardingService,
    private onboardingTypeService: OnboardingTypeService
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.maxAge = this.onboardingService.retrieveProductMaxAge();
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
    this.stepper = this.onboardingTypeService.returnOnboardType$getValue()==='SHORT'?1:1;
    this.autofillForm();
  }

  ngOnDestroy() {
    this.onboardingService.setCurrentStep();
  }

  createForm() {
    const coverageType = this.onboardingService.retrieveChosenCoverageType();
    const hasUnderstood = this.onboardingService.retrieveknowledgeCheck();
    const occupation = this.onboardingService.retrieveChosenOccupation();

    this.contactForm = this.formBuilder.group({
      firstName: [null, [Validators.required, Validators.minLength(2)]],
      lastName: [null, [Validators.required, Validators.minLength(2)]],
      email: [null, [Validators.required, CustomValidators.email]],
      telephone: [null, [Validators.required, CustomValidators.phone]],
      dob: [null, [Validators.required, CustomValidators.simpleDate]],
      occupation: [occupation],
      spLong: [coverageType?coverageType:false],
      hasUnderstood: [hasUnderstood]
    });
    this.checkForContactDetails();
  }

  checkForContactDetails() {
    const contactDetails: ContactDetails = this.onboardingService.retrieveContactDetails();

    if (contactDetails.dob) {
      let date = new Date(contactDetails.dob);
      contactDetails.dob = formatDate(date, 'dd-MM-yyyy', 'nl', 'CET');
      this.contactForm.patchValue(contactDetails);
      this.contactForm.get('dob').disable();
    }
  }

  // input dd-MM-yyyy
  parseDateDutch(date){
    const RegExpNumberedCaptureGroups =/^([0-9]{1,2})-([0-9]{1,2})-([1,2]{1}[0-9]{3})$/

    const matchObj = RegExpNumberedCaptureGroups.exec(date)
    const year = matchObj[3] // 2019
    const month = matchObj[2] // 12
    const day = matchObj[1] // 31

    let newDate = new Date();
    newDate.setDate(+day);
    newDate.setMonth((+month) -1);  // 0-11
    newDate.setFullYear(+year);
    return newDate
  }

  submit() {
    const contactDetails = {...this.contactForm.getRawValue()};

    // Convert to locale 'en' manually
    let date = this.parseDateDutch(contactDetails.dob);
    this.contactForm.patchValue({dob :formatDate(date, 'dd-MM-yyyy', 'nl', 'CET')});
    contactDetails.dob = formatDate(date, 'yyyy-MM-dd', 'nl', 'CET');

    if (!this.contactForm.valid) {
      this.contactForm.markAllAsTouched();
    } else {
      this.onboardingService.setAndStoreContactDetails(contactDetails)
        .pipe(
          catchError((origErr) => {
            if (origErr.status === 422) {
              if (origErr.error.error === 'Invalid email') {
                this.contactForm.get('email').setErrors({
                  invalidEmail: true
                });
              } else if (origErr.error.error === 'a payment request for this registration is already open') {
                // BE found an open payment already in progress – redirect to existing Mollie payment
                alert('There is already an open payment in process');
                window.location.href = origErr.error.paymentLink;
              } else if (origErr.error.error === 'member already exists') {
                // BE identified that this account has already paid the subscription – redirect to Portal's login page
                alert('Het inschrijfgeld is al voldaan. Graag inloggen om verder te gaan');
                window.location.href = environment.portal_url;
              } else {
                alert('An error occurred. Please, try again or get in contact with us.');
              }
            }
            return throwError(origErr);
          })
        )
        .subscribe(
          (regCode: RegCode) => {
            this.onboardingService.storeRegCode(regCode.registrationCode);
            if(this.onboardingTypeService.returnOnboardType$getValue() === OnboardingType.PROSPECTS) {
                this.router.navigate(['sp-prospects/address']);
            } else {
              this.router.navigate(['address']);
            }
          }
        );
    }
  }

  autofillForm() {
    if (isDevMode()) {
      // this.contactForm.patchValue({firstName: 'Test', lastName: 'user', email: 'testuser@geckotech.nl', telephone: '0612345678', dob: '21-6-1990'});
    }
  }
}
