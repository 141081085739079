import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyAreaComponent } from '../my-area-module/my-area.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import {ConfirmAccountComponent} from './confirm-account/confirm-account.component';


const routes: Routes = [
  {
    path: '',
    component: RegistrationComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'login'},
      {path: 'login', component: LoginComponent},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'new-password', component: NewPasswordComponent},
      {path: 'confirm-account', component: ConfirmAccountComponent},
      {path: '**', redirectTo: 'login'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule { }
