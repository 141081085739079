import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';

/*

Should probs rename this back to just details as used for the docs and the help section.

 */

@Component({
  selector: 'sp-portal-document-details-section',
  templateUrl: './document-details-section.component.html',
  styleUrls: ['./document-details-section.component.scss']
})
export class DocumentDetailsSectionComponent implements OnInit, OnChanges {

  @Input() title: string;
  @Input() kycSectionData;
  @Input() link: string;
  public isIncomplete: boolean;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.kycData && changes.kycData.currentValue) {
      this.isIncomplete = this.hasMissingValue(changes.kycData.currentValue);
    }
  }

  hasMissingValue(data) {
    return Object.keys(data).some(key => {return !data[key];});
  }

  followLink() {
    this.router.navigate([`${this.link}`]);
  }
  // followLink() {
  //   const link = [];
  //   link.push(this.link);
  //   this.router.navigate(link);
  // }
}
