import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {KycService} from '../services/kyc.service';

@Component({
  selector: 'sp-portal-edit-kyc-details',
  templateUrl: './edit-kyc-details.component.html',
  styleUrls: ['./edit-kyc-details.component.scss']
})
export class EditKycDetailsComponent implements OnInit {

  kycSectionKey: string;
  kycSectionData$;
  kycSectionForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private kycService: KycService
  ) {
    this.kycSectionKey = this.route.snapshot.params.kycSection;
    this.kycSectionData$ = this.kycService.kycData$
      .pipe(
        map(kycData => {
          if (kycData) {
            return kycData[this.kycSectionKey];
          }
        })
      );
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    const group: any = {};
    this.kycSectionData$.subscribe(
      kycSectionData => {
        for (const key in kycSectionData) {
          if (kycSectionData.hasOwnProperty(key)) {
            group[key] = new UntypedFormControl(kycSectionData[key] || '');
          }
        }
        this.kycSectionForm = new UntypedFormGroup(group);
        console.log(group);
      }
    );
  }

  submit() {
    throw new Error('method not implemented');
  }
}
