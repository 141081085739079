import { Directive, ElementRef, HostListener, Input } from '@angular/core';

interface Range {
  min: number;
  max: number;
}



@Directive({
  selector: '[spNumberRange]'
})
export class NumberRangeDirective {
  @Input('spNumberRange') range: Range;


  constructor(
    private elementRef: ElementRef
  ) {}

  @HostListener('keyup') checkRange() {
    const hostElem = this.elementRef.nativeElement;
    const numberToCheck = hostElem.value;

    if (numberToCheck && this.range.min > numberToCheck) {
      hostElem.value = this.range.min;
    }
    if (numberToCheck && this.range.max < numberToCheck) {
      hostElem.value = this.range.max;
    }
  }

}
