<!--
So you click the button and the relevant MOLLIE screen opens.
Once you pay (or not) you return the portal and a Thank you text is visible in the green area (or the above text when payment not success).
This is visible for 24 hours. Then green area will be invisible.

Immediately remove green area when donation is REVOKED
-->


<ng-container *ngIf="contributionDetails || hasMissedContributions else successMessage">
  <ng-container *ngTemplateOutlet="hasMissedContributions ? missedContributions : contributionRequests"></ng-container>
</ng-container>

<ng-template #contributionRequests>
  <sp-shared-lib-rounded-container class="contribution-due">
    <p>
      Er staat een betaalverzoek voor je klaar.
      Het gaat om een donatie aan {{contributionDetails.payeeName}}
      van {{contributionDetails.contributionAmount/100 | spCurrency}}.
    </p>
    <button type="button" sp-button (click)="gotoPayment()">Donatie doen</button>
  </sp-shared-lib-rounded-container>
</ng-template>

<ng-template #missedContributions>
  <sp-shared-lib-rounded-container class="contribution-due">
    <p>LET OP: je hebt donaties gemist</p>
    <p>Klik <a href="https://www.sharepeople.nl/donatiegemist" target="_blank" ref="noreferrer">hier</a> voor meer infomatie</p>
  </sp-shared-lib-rounded-container>
</ng-template>

<ng-template #successMessage>
  <sp-shared-lib-rounded-container class="contribution-due">
      <p>
        Je hebt geen openstaande donatie.
      </p>
  </sp-shared-lib-rounded-container>
</ng-template>

