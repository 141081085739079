<p class="steps-remaining" *ngIf="!currentSubStepIndex">
  {{steps[currentStepIndex]}}
</p>
<p class="steps-remaining" *ngIf="currentSubStepIndex">
  {{steps[currentSubStepIndex-1]}}
</p>
<div class="step-indicator">

  <ng-container *ngFor="let step of steps; let i = index">
<!--     if !substeps -->

    <span class="step" *ngIf="!currentSubStepIndex" [class.active]="currentStepIndex >= i"></span>
    <span class="step" *ngIf="currentSubStepIndex && currentSubStepIndex && i != currentStepIndex-1" [class.active]="currentStepIndex > i"></span>
<!--     if substeps -->
    <span *ngIf="currentSubStepIndex && i+1 == currentStepIndex">
      <span class="sub-step step"  *ngFor="let subStep of subSteps; let j = index" [class.active]="j < currentSubStepIndex"></span>
    </span>
  </ng-container>
</div>

