import {Component, OnDestroy, OnInit} from '@angular/core';
import {OnboardingService} from '../../../onboarding-module/onboarding.service';
import {Router} from '@angular/router';
import {SpPaymentRequest} from '../../models/models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {StorageService} from '../../../core-module/services/storage.service';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';

@Component({
  selector: 'sp-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy{

  paymentRequest: SpPaymentRequest = null;
  displayCheckboxTerms: boolean;
  confirmationForm: UntypedFormGroup;
  usePaymentAccountForIncasso: boolean;
  confirmationInfo;
  termsAccepted = false;
  destroy$ = new Subject();
  productName: string;
  createRequestProcessing = false;
  onboardingType$: Observable<any>;
  stepper = 3;

  footerConfig = {
    btnType: FooterButtonType.CLICKEVENT,
    footerBtnText: 'Betaal inschrijfkosten',
    outputBtnClickEvent: () => {return this.submit();}
  };

  constructor(
    private onboardingService: OnboardingService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private onboardingTypeService: OnboardingTypeService,
  ) { }

  ngOnInit() {
    this.createForm();
    this.termsAccepted = false;
    // this.confirmationInfo = window.history.state;

    this.confirmationForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {return value.termsAccepted ? this.termsAccepted = true : this.termsAccepted = false;});
    const onboardingData = this.storageService.getOnboardingData();
    this.productName = onboardingData.productName;
    const confirmationInfo = this.onboardingService.retrievePromise();
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;

    this.onboardingType$.pipe(takeUntil(this.destroy$)).subscribe((type) => {
      this.stepper = type === 'SHORT' ? 5 : 3;
      this.confirmationInfo = confirmationInfo;

      // const spLong = this.onboardingService.retrieveChosenCoverageType();
      // if (spLong === true) {
      //   delete confirmationInfo.monthlyMembershipFee;
      //   delete confirmationInfo.monthlyMembershipFeeIncVAT;
      //   this.confirmationInfo = confirmationInfo;
      //
      //   this.confirmationInfo.monthlyMembershipFee = 1500;
      //   this.confirmationInfo.monthlyMembershipFeeIncVAT = 1815;
      // } else {
      //   this.confirmationInfo = confirmationInfo;
      // }
    });
  }

  createForm() {
    this.confirmationForm = this.formBuilder.group({
      termsAccepted: [false]
    });
  }

  // FIXME: Very unclear
  displayTerms(){
    this.displayCheckboxTerms = true;
  }

  createPaymentRequest(incassoAgreement) {
    this.onboardingService.getNewAndUpdatePaymentRequest(incassoAgreement)
      .subscribe(
        (paymentRequest: SpPaymentRequest) => {
          this.paymentRequest = paymentRequest;
          this.gotoMollie();
        }
      );
  }

  submit() {
    if (this.termsAccepted) {
      this.createRequestProcessing = true;
      const incassoAgreement = this.confirmationForm.get('termsAccepted').value;
      this.createPaymentRequest(incassoAgreement);
    }
  }

  gotoMollie() {
    //TODO: check onboardtype for navigation
    this.onboardingService.setCurrentStep('/processing-payment');
    // todo: uncomment when testing locally:
    // if(this.onboardingTypeService.returnOnboardType$getValue() == OnboardingType.PROSPECTS) {
    //   this.router.navigate(['sp-prospects/processing-payment']);
    // } else {
    //   this.router.navigate(['processing-payment']);
    // }
    // todo: uncomment when testing against server:
    window.location.href = this.paymentRequest.paymentLink;
  }

  skipMollie() {
    this.onboardingService.setCurrentStep('membership-payment');
    this.router.navigate(['membership-payment']);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
