
export interface ICalculatedCoverageAndCost {
  coverage: number;
  cost: number;
}
export interface IlongCoverageOptions {
  amount: string;
  id: number;
  hidden: boolean;
}

export interface IHealthTestData {
  smoker: string;
  underTreatment: boolean;
  expectedSickness: ('Yes' | 'No' | 'Maybe');
  gender?: ('Male' | 'Female');
  height?: number;
  weight?: number;
  bmi?: number

}

export interface IUserMetrics {
  height: number;
  weight: number;
}

export interface IUserAge {
  age: string;
}

export interface IUserBmi {
  bmi: string;
  bmiStatus: string;
}

export interface  IUserHealthResult {
  bmi: string;
  isHealthy: boolean;
}

export interface  IUserPromiseConfirmation {
  acceptPromise: boolean;
  acceptPrivacy: boolean;
}

export interface  IUserPaymentConfirmation {
  acceptPayment: boolean;
}

export interface  IPaymentTerms {
  monthlyAmountVAT: number;
  monthlyAmount: number;
  annualAmountVAT: number;
  annualAmount: number;
  paymentPeriod: PaymentPeriod;
  paymentType: PaymentType

}

export enum PaymentPeriod {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
  MONTHLYINDUTCH = 'maand',
  ANNUALINDUTCH = 'jaar'
}

export enum PaymentType {
  INCASSO ='INCASSO',
  INVOICE ='INVOICE'
}

export enum SpLongInvitationCode {
  SPLONGINVITATIONCODE = 'spLongInvitationCode'
}

