import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {RegistrationService} from '../registration.service';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Component({
  selector: 'sp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;

  displayError = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private registrationService: RegistrationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
  }


  createForm() {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, CustomValidators.email]],
      password: [null, [Validators.required]],
    });
  }

  submit() {
    const loginDetails = {
      ...this.loginForm.value
    };
    this.displayError = false;
    this.registrationService.login(loginDetails)
      .pipe(
        catchError((origError) => {
          if (origError) {
            this.displayError = true;
            return throwError(origError);
          }
        })
      )
      .subscribe(
        (credentials) => {
          if (credentials && credentials.access_token) {
            this.router.navigate(['/dashboard']);
          }
        }
      );
  }

}
