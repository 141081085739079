import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedContainerComponent } from './rounded-container/rounded-container.component';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import { SpCurrencyPipe } from './pipes/sp-currency.pipe';
import { AvatarCardComponent } from './avatar-card/avatar-card.component';
import { PrettyKeyPipe } from './pipes/pretty-key.pipe';


@NgModule({
  declarations: [
    RoundedContainerComponent,
    LoadingSpinnerComponent,
    SpCurrencyPipe,
    AvatarCardComponent,
    PrettyKeyPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    SpCurrencyPipe
  ],
  exports: [
    RoundedContainerComponent,
    LoadingSpinnerComponent,
    SpCurrencyPipe,
    AvatarCardComponent,
    PrettyKeyPipe
  ]
})
export class SharedModule { }

