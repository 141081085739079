import { Directive, ElementRef, HostListener} from '@angular/core';


/*
    Usage notes:
    <input type='text' spWhitespaceTrimmer'>
 */
@Directive({
  selector: '[spWhitespaceTrimmer]'
})
export class WhitespaceTrimmerDirective {

  hostElem = this.elementRef.nativeElement;

  constructor(
    private elementRef: ElementRef
  ) {}

  @HostListener('keyup') removeWhitespace() {
    if (this.hostElem.value) {
      this.hostElem.value = this.hostElem.value.trim().replace(/\s/g, '');
    }
  }

}
