<sp-onboard-page-content-wrapper
  [footerConfig]="footerConfig"
  [formId]="form"
  [showStepper]="true"
  [stepperConfig]="{currentStep: stepper, onboardingType: (onboardingType$ | async)}">
  <p class="introduction">
    Vertel wat meer over jezelf
  </p>

  <form [formGroup]="contactForm" #form='ngForm' id="contact-form">
    <sp-input-wrapper
      label="Voornaam"
      [error]="form.submitted ? this.contactForm.get('firstName').errors : null">
      <input formControlName="firstName" type="text">
    </sp-input-wrapper>

    <sp-input-wrapper
      label="Achternaam"
      [error]="form.submitted ? this.contactForm.get('lastName').errors : null">
      <input formControlName="lastName" type="text">
    </sp-input-wrapper>

    <sp-input-wrapper
      label="E-mailadres"
      [error]="form.submitted ? form.form.get('email').errors : null">
      <input formControlName="email" type="text" [spTextTransform]="'toLowerCase'">
    </sp-input-wrapper>

    <sp-input-wrapper
      class="half-width"
      label="Mobiel nummer"
      [error]="form.submitted ? this.contactForm.get('telephone').errors : null">
      <input formControlName="telephone" type="tel" placeholder="06">
    </sp-input-wrapper>


    <div class="input-wrapper" ng>
      <sp-input-wrapper
        class="half-width"
        label="Geboortedatum"
        [error]="form.submitted ? this.contactForm.get('dob').errors : null">
          <input formControlName="dob" type="text"/>
      </sp-input-wrapper>
      <p class="input-tip">bijv. 20-07-1989</p>
    </div>
  </form>
</sp-onboard-page-content-wrapper>

