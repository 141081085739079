<div *ngIf="title" class="header">
  <h4 class="title">{{title}}</h4>
  <span class="edit"  *ngIf="link" (click)="followLink()">Wijzigen</span>
</div>
<sp-shared-lib-rounded-container>
  <div class="detail-item">
    <span class="item-title">First Name</span>
    <span class="item-value">{{kycSectionData.firstName}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">Last name</span>
    <span class="item-value">{{kycSectionData.lastName}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">legalForm</span>
    <span class="item-value">{{kycSectionData.legalForm}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">phone</span>
    <span class="item-value">{{kycSectionData.phone}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">gender</span>
    <span class="item-value">{{kycSectionData.gender}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">street</span>
    <span class="item-value">{{kycSectionData.street}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">dateOfBirth</span>
    <span class="item-value">{{kycSectionData.dateOfBirth}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">zipcode</span>
    <span class="item-value">{{kycSectionData.zipcode}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">houseNumber</span>
    <span class="item-value">{{kycSectionData.houseNumber}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">city</span>
    <span class="item-value">{{kycSectionData.city}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">placeOfBirth</span>
    <span class="item-value">{{kycSectionData.placeOfBirth}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">nationality</span>
    <span class="item-value">{{kycSectionData.nationality}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">addition</span>
    <span class="item-value">{{kycSectionData.addition}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">country</span>
    <span class="item-value">{{kycSectionData.country}}</span>
  </div>
  <div class="detail-item">
    <span class="item-title">email</span>
    <span class="item-value">{{kycSectionData.email}}</span>
  </div>
  <ng-content></ng-content>
</sp-shared-lib-rounded-container>
