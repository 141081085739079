<div class="popup-background" [class.active]="popupActive">
  <div class="popup-container">
    <div class="close-button" (click)="close()"></div>
    <form [formGroup]="formGroup" #form='ngForm'>
      <h4>Bedrijf</h4>


      <div class="item-container">

        <div class="dropdown">
          <sp-input-wrapper label="Beroep">
            <input formControlName="occupation" type="text" [value]="displayOccupationTitle(this.formGroup.get('occupation').value)">
          </sp-input-wrapper>
        </div>
      </div>

      <div class="item-container">
        <div class="label-wrapper">
          <label>Betalen per</label>
        </div>
        <div class="toggle-wrapper" >
          <input type="radio" id="incasso" formControlName="incasso" [value]="true"
                 (click)="displayIncassoSettings(true)">
          <label for="incasso">Incasso</label>
          <input type="radio" id="factuur" formControlName="incasso" [value]="false"
                 (click)="displayIncassoSettings(false)">
          <label for="factuur">Factuur</label>
        </div>
      </div>

      <div class="item-container">
        <label>Periode</label>
        <div class="toggle-wrapper">
          <span *ngIf="incasso">
            <input type="radio" id="monthly" formControlName="monthlyPayment" [value]="true">
            <label for="monthly">Maandelijks</label>
          </span>
          <input type="radio" id="yearly" formControlName="monthlyPayment" [value]="false">
          <label for="yearly">Jaarlijks</label>
        </div>
      </div>

      <div *ngIf="incasso" class="item-container">
        <div class="input-wrapper">
          <sp-input-wrapper
            label="Incasso IBAN"
            [error]="form.submitted ? formGroup.get('iban').errors : null">
            <input type="text"
                   #iban
                   formControlName="iban"
                   [spTextTransform]="'toUpperCase'"
                   spSpecialCharTrimmer
            >
          </sp-input-wrapper>
        </div>
      </div>

      <sp-input-wrapper class="terms-and-conditions" *ngIf="incasso && formGroup.dirty"
                        label="Ik geef toestemming aan SharePeople B.V. om de deelnamekosten van mijn bovengenoemde bankrekening te incasseren."
                        [error]="form.submitted ? formGroup.get('termsAccepted').errors : null">
        <input type="checkbox" formControlName="termsAccepted" name="termsAccepted">
      </sp-input-wrapper>

      <div class="btn-wrapper">
        <button [disabled]="formHasChanged" [sp-button] (click)="saveChanges()" class="save-btn">Opslaan</button>
      </div>
    </form>
  </div>
</div>
