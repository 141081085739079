import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'sp-shared-lib-rounded-container',
  templateUrl: './rounded-container.component.html',
  styleUrls: ['./rounded-container.component.scss']
})
export class RoundedContainerComponent implements OnInit {

  @Input() shadow: (boolean|undefined);

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.shadow !== undefined) {
      this.addClassBasedOnInput(this.shadow, 'shadow');
    }
  }


  addClassBasedOnInput(input, className: string) {
    const el = this.elRef.nativeElement;
    this.renderer.addClass(el, className);
  }

}
