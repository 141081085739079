import {Component, ElementRef, OnInit, OnDestroy, QueryList, ViewChildren, Output} from '@angular/core';
import {EMPTY, Observable, throwError, Subject} from 'rxjs';
import {DateTimeService} from '../../../../../sp-shared-lib/src/lib/shared/services/date-time.service';
import {AuthService} from '../../core-module/auth.service';
import {MemberData} from '../dashboard/dashboard.component';
import {MyAreaService} from '../my-area.service';
import {FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {catchError, takeUntil, map} from 'rxjs/operators';
import {Occupation} from '../dashboard/occupation-popup/occupation-popup.component';

export interface UserProfile {
  firstName: string;
  lastName: string;
  streetAndNumber: string;
  postalCode: string;
  city: string;
  dob: string;
  email: string;
  telephone: string;
  company: string;
  kvk: string;
  occupation: any;
  iban: string;
  monthlyPayment: boolean;
  membershipFee: string;
  incasso: boolean;
  style?: string;
  extendedCoverageResponse?: any;
}

export interface ISelfTestResponse {
  formUrl: string;
}

@Component({
  selector: 'sp-portal-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {

  @ViewChildren('iban') ibanRef: QueryList<ElementRef>;
  public profile$: Observable<UserProfile>;
  public memberData$: Observable<MemberData>;
  public profile: UserProfile;

  editingForm: UntypedFormGroup;
  personalFormGroup: FormGroup;
  contactFormGroup: FormGroup;
  companyFormGroup: FormGroup;

  destroy$ = new Subject();
  participantPromiseInfo;
  occupations: Occupation[];
  // personal: PersonalPopupComponent;
  // contact: ContactPopupComponent;
  // company: CompanyPopupComponent;
  occupationLabel: string;
  initialFormValues: object;

  @Output() personalPopupActive = false;
  @Output() companyPopupActive = false;
  @Output() contactPopupActive = false;
  @Output() confirmPopup = false;

  constructor(
    private authService: AuthService,
    private myAreaService: MyAreaService,
    private formBuilder: UntypedFormBuilder,
    public dateTimeService: DateTimeService,
  ) {
  }

  ngOnInit() {
    this.createForm();
    this.memberData$ = this.myAreaService.memberData$;
    this.profile$ = this.myAreaService.profile$;

    this.populateForm();

    this.myAreaService.getPromiseInfo()
      .subscribe(promise => {
        return this.participantPromiseInfo = promise;
      });
  }

  updateFormPersonal(form) {
    this.saveChanges(form);
  }

  updateFormContact(form) {
    this.saveChanges(form);
  }

  updateFormCompany(form) {
    this.saveChanges(form);
  }

  createForm() {
    this.personalFormGroup = this.formBuilder.group({
      streetAndNumber: ['', [Validators.required]],
      postalCode: ['', [Validators.required, CustomValidators.postcode]],
      city: ['', [Validators.required]],
      dob: ['', [Validators.required]],
    });

    this.contactFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email]],
      telephone: ['', [Validators.required, CustomValidators.phone]],
    });

    this.companyFormGroup = this.formBuilder.group({
      occupation: [null],
      incasso: [null, [Validators.required]],
      monthlyPayment: [null, [Validators.required]],
      iban: [null, [Validators.required, CustomValidators.iban]],
      termsAccepted: [null, [Validators.required, CustomValidators.valueIsTrue]]
    });

    this.editingForm = this.formBuilder.group({
      personal: this.personalFormGroup,
      contact: this.contactFormGroup,
      company: this.companyFormGroup
    });
  }

  populateForm() {
    this.myAreaService.profile$.pipe(takeUntil(this.destroy$))
      .subscribe(profileData => {
        this.profile = profileData;
        this.editingForm.patchValue({personal: {streetAndNumber: profileData.streetAndNumber}});
        this.editingForm.patchValue({personal: {postalCode: profileData.postalCode}});
        this.editingForm.patchValue({personal: {city: profileData.city}});
        this.editingForm.patchValue({personal: {dob: profileData.dob}});

        this.editingForm.patchValue({contact: {email: profileData.email}});
        this.editingForm.patchValue({contact: {telephone: profileData.telephone}});

        this.editingForm.patchValue({company: {incasso: profileData.incasso}});
        this.editingForm.patchValue({company: {monthlyPayment: profileData.monthlyPayment}});
        this.editingForm.patchValue({company: {iban: profileData.iban}});
        if (profileData.incasso) {
          this.editingForm.patchValue({company: {termsAccepted: true}});
        }
        this.initialFormValues = this.editingForm.value;

        this.myAreaService.getOccupations()
          .subscribe(occupations => {
            this.occupations = occupations;
            if (this.profile.occupation) {
              const occupation = this.occupations.find((el) => {
                return el.id === this.profile.occupation.id;
              });
              this.occupationLabel = occupation.name;
              this.editingForm.patchValue({company: {occupation: occupation}});
              // If occupation is saved, user may not change it anymore
              this.companyFormGroup.get('occupation')?.disable();
            }
          });
      });
  }

  saveChanges(newSettings) {
    this.updateSettings(newSettings);
    this.personalPopupActive = false;
    this.companyPopupActive = false;
    this.contactPopupActive = false;
    this.confirmPopup = true;
  }

  updateSettings(newSettings) {
    this.myAreaService.updateProfile(newSettings)
      .pipe(
        catchError((origErr) => {
          if (origErr.status === 400) {
            // if iban is invalid, save null;
            this.editingForm.get('iban').setValue(null);
            // this.router.navigate(['dashboard']);
          } else if (origErr.status === 422) {
            // incasso is true, so don't allow invalid iban:
            this.editingForm.get('iban').setErrors({
              invalidIban: true
            });
            return EMPTY;
          } else {
            return throwError(origErr);
          }
        })
      )
      .subscribe(
        (resp: any) => {
          if (resp.status && resp.status === 'UPDATED') {
            this.myAreaService.profile$.pipe(map(profile => {
              // We seem to be updating the global profile object here based on the formGroups
              profile.streetAndNumber = this.editingForm.get(['personal', 'streetAndNumber']).value;
              profile.postalCode = this.editingForm.get(['personal', 'postalCode']).value;
              profile.city = this.editingForm.get(['personal', 'city']).value;
              profile.dob = this.editingForm.get(['personal', 'dob']).value;

              // Update contact
              profile.telephone = this.editingForm.get(['contact','telephone']).value;
              profile.email = this.editingForm.get(['contact','email']).value;

              //  update company
              let occupationId = this.editingForm.get(['company','occupation']).value?.id;
              if (occupationId) {
                const occupation = this.occupations.find((el) => {
                  return el.id === +occupationId;
                });
                if (occupation) {
                  this.occupationLabel = occupation.name;
                }
              }
              profile.incasso = this.editingForm.get(['company','incasso']).value;
              profile.monthlyPayment = this.editingForm.get(['company','monthlyPayment']).value;
              profile.iban = this.editingForm.get(['company','iban']).value;


            })).subscribe();
          }
        }
      );
  }

  downloadPromise() {
    return this.myAreaService.downloadPromise()
      .subscribe(file => {
        const url = window.URL.createObjectURL(new Blob([file as BlobPart], {type: 'application/pdf'}));
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;
        link.download = `Verklaring SharePeople - ${this.participantPromiseInfo.fullName}.pdf`;
        link.click();
      });
  }

  /**
   * Open modal
   * @param popup = form to edit [personal, contact, company]
   */
  openEdit(popup) {
    this[popup] = true;
  }

  public closePopup(_event) {
    this.populateForm();
    this[_event] = false;
  }

  closeConfirm() {
    this.confirmPopup = false;
  }

  logout() {
    this.authService.logout();
  }

  endParticipation(url) {
    window.open(url);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  protected readonly FormGroup = FormGroup;
}
