<sp-onboard-page-content-wrapper
  [footerConfig]="footerConfig"
  [showStepper]="true"
  [btnDisabled]="!promiseAccepted || !TCAccepted"
  [stepperConfig]="{currentStep: stepper, onboardingType: (onboardingType$ | async)}">
  <section class="introduction">
    <h4>Dit beloof je</h4>
    <p>
      Houd je niet van contracten met kleine lettertjes? Wij ook niet. Wel moet je ons een aantal dingen beloven om deel
      te nemen aan {{productName}}.
    </p>
  </section>

  <section class="list">
    <ul>
      <li>Ik ben op dit moment 100% in staat mijn huidige beroep uit te oefenen en ik verwacht niet arbeidsongeschikt te
        worden.
      </li>
      <li>Ik verwacht de komende 6 maanden niet arbeidsongeschikt te raken door bestaande klachten of een reeds
        gediagnosticeerde ziekte of aandoening.
      </li>
      <li>Ik doe elke maand een donatie aan een arbeidsongeschikte deelnemer.</li>
    </ul>
  </section>

  <section class="acceptance">
    <div class="term">
      <sp-input-wrapper>
        <input type="checkbox" #promise [(ngModel)]="promiseAccepted">
      </sp-input-wrapper>
      <p>
        Ik accepteer de volledige
        <a (click)="getPromiseOverlay()">
          Verklaring & Beloftes
        </a>
      </p>
    </div>
    <div class="term">
      <sp-input-wrapper>
        <input type="checkbox" #tca [(ngModel)]="TCAccepted">
      </sp-input-wrapper>
      <p>
        Ik accepteer het
        <a (click)="getPrivacyOverlay()">privacybeleid</a>
        en de
        <a (click)="getTermsOverlay()">algemene voorwaarden</a>
      </p>
    </div>
  </section>
  <sp-tc-overlay></sp-tc-overlay>
</sp-onboard-page-content-wrapper>
