import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';

export interface UserDetails {
  activationCode: string;
  username: string;
  firstName: string;
  lastName: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _userDetails: UserDetails;

  constructor(
    private storageService: StorageService
  ) { }

  setDetails(userDetails: UserDetails) {
    this.storageService.setUserDetails(userDetails);
    this._userDetails = userDetails;
  }

  getUserDetails(): UserDetails {
    return this.storageService.getUserDetails();
  }

}

