import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

export enum OnboardingType {
  SHORT = 'SHORT',
  LONG = 'LONG',
  PROSPECTS = 'PROSPECTS'
}

@Injectable({
  providedIn: 'root'
})
export class OnboardingTypeService {

  private _onBoardingType = new BehaviorSubject(null);
  public onboardingType$ = this._onBoardingType as Observable<OnboardingType>;

  constructor(private router: Router) {
  }

  removeOnboardType() {
    this._onBoardingType.next(null);
  }

  listenToRouteChanges() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.setCurrentOnboardingFlowType(evt);
    });
  }

  setCurrentOnboardingFlowType(navEvent: NavigationEnd) {
    let newOnBoardType: OnboardingType | null = null;
    const navUrl = navEvent.urlAfterRedirects ? navEvent.urlAfterRedirects : navEvent.url;

    if (navUrl.includes('/start') || navUrl.includes('/intro')) {
      this._onBoardingType.next(null);
    }

    if (navUrl.includes('/sp-long')) {
      newOnBoardType = OnboardingType.LONG;
    }

    if (navUrl.includes('/sp-prospects')) {
      newOnBoardType = OnboardingType.PROSPECTS;
    }

    if (newOnBoardType === null && !navUrl.includes('/registration')) {
      newOnBoardType = OnboardingType.SHORT;
    }

    if (!this._onBoardingType.getValue()) {
      this._onBoardingType.next(newOnBoardType);
    }
    console.log(newOnBoardType);
  }

  returnOnboardType$getValue() {
    return this._onBoardingType.getValue();
  }
}
