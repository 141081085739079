import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MyAreaService} from '../../my-area.service';

@Component({
  selector: 'sp-portal-extended-coverage-popup',
  templateUrl: './extended-coverage-popup.component.html',
  styleUrls: ['./extended-coverage-popup.component.scss']
})
export class ExtendedCoveragePopupComponent implements OnInit {

  constructor(
    private myAreaService: MyAreaService,
  ) { }

  popupActive = false;
  extendedCoverageResponse = new UntypedFormControl();
  @Input() extendedCoverageOptions;

  ngOnInit() {
    const hidePopupUntilNextSession = sessionStorage.getItem('hidePopupUntilNextSession');
    this.myAreaService.profile$.subscribe(profileData => {
      if (!profileData.extendedCoverageResponse && !hidePopupUntilNextSession) {
        this.popupActive = true;
      }
    });
  }

  handleSubmit() {
    this.popupActive = false;
    sessionStorage.setItem('hidePopupUntilNextSession', 'true');
    if (this.extendedCoverageResponse.value) {
      this.myAreaService.selectExtendedCoverage(this.extendedCoverageResponse.value)
        .subscribe();
    }
  }

}
