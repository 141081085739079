import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {

  static fullname(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    if(control.value.includes(' ')) {
      return null;
    } else {
      return { invalidName: true};
    }
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const email = control.value;
    const regex = /^([_A-Za-z0-9-+]+)(\.[_A-Za-z0-9-+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})/;
    if (regex.test(email)) {
      return null;
    } else {
      return { invalidEmail: true };
    }
  }

  static postcode(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const postCode = control.value;
    const regex = /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i;
    if (!regex.test(postCode)) {
      return { invalidPostcode: true };
    } else {
      return null;
    }
  }

  static phone(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const phoneNumber = control.value;
    const regex = /^(06)[0-9]{8}$/;
    if (regex.test(phoneNumber)) {
      return null;
    } else {
      return { invalidPhone: true };
    }
  }


  static valueIsTrue(control: AbstractControl): ValidationErrors | null {
    return control.value === true ? null : {isNotTrue: true};
  }


  static kvk(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const phoneNumber = control.value;
    const regex = /^[0-9]{8}$/;
    if (regex.test(phoneNumber)) {
      return null;
    } else {
      return { invalidKvk: true };
    }
  }

  static MatchFields( firstControlName, secondControlName): ValidationErrors | null {
    return (AC: AbstractControl) => {
      const firstControlValue = AC.get(firstControlName).value;
      const secondControlValue = AC.get(secondControlName).value;

      if (firstControlValue !== secondControlValue) {
        AC.get(secondControlName).setErrors({ matchFields: true });
      } else {
        return null;
      }
    };
  }

  static minMaxYears(minYears, maxYears): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return;
      }
      const ISOdateString = control.value;

      const year = parseInt(ISOdateString.split('-')[0], 10);
      if (!year) {
        return null;
      }

      if ( year > maxYears || year < minYears ) {
        return {
          yearRangeError: {
            given: year,
            max: maxYears,
            min: minYears
          }
        };
      } else {
        return null;
      }
    };
  }

  static maxHours(maxNumber): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return;
      }
      if (control.value > maxNumber) {
        return { maxHours: maxNumber };
      }
    };
  }

  static bsn(number: number): ValidatorFn {

    return (control: AbstractControl) => {
      if (!control.value) {
        return;
      }
      if (control.value.toString().length > 9 || control.value.toString().length < 8) {
        return { bsnError: true };
      }

      const number = control.value.split('');
      let BSN_counter = 0;
      let sum = 0;

      for (let i = control.value.toString().length; i >= 1; i--){
        if (i === 1) {
          sum = sum - (number[BSN_counter] * i);
        } else {
          sum = sum + (number[BSN_counter] * i);
        }
        BSN_counter++;
      }

      if (sum % 11 !== 0) {
        return { bsnError: true };
      }
    };
  }

  static max(maxNumber): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return;
      }
      if (control.value > maxNumber) {
        return { maxError: maxNumber };
      }
    };
  }

  static min(minNumber): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return;
      }
      if (control.value < minNumber) {
        return { minError: minNumber };
      }
    };
  }

  static iban(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const iban = control.value;
    const regex = /^NL[0-9]{2}[A-Z]{4}[0-9]{10}$/;
    if (regex.test(iban)) {
      return null;
    } else {
      return { invalidIban: true };
    }
  }

  static simpleDate(control: AbstractControl): ValidationErrors | null {
    const regex = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;
    if (!control.value) {
      return null;
    }
    if (!regex.test(control.value)) {
      return { invalidDate: true };
    }
  }

}
