<ng-container *ngIf="(kycData$ | async) as kycData">
  <sp-portal-page-wrapper title="Verificatie identiteit">
    <p>
      Stuur een email naar <a href="mailto: contact&#64;sharepeople.nl">contact&#64;sharepeople.nl</a> om wijzigingen door te geven
    </p>
    <p>
      {{kycData | json}}
    </p>
    <sp-portal-personal-details-section
      [title]="'Personal Info'"
      [kycSectionData]="kycData"
      link="../kyc/personal-details"
    ></sp-portal-personal-details-section>

    <sp-portal-document-details-section title="Foto ID" class="photo-container invalid" >
      <span [routerLink]="'../../camera'">Upload photo Id</span>
    </sp-portal-document-details-section>

    <sp-portal-document-details-section class="help-container invalid">
      <h4>Hulp nodig?</h4>
      <p>Kijk bij de meestgestelde vragen over identiteit vaststellen of neem contact op:</p>
      <sp-lib-avatar-card [avatarDetails]="helpAvatarDetails"></sp-lib-avatar-card>
    </sp-portal-document-details-section>
  </sp-portal-page-wrapper>
</ng-container>

