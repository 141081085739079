import {Component, isDevMode, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OnboardingService } from '../../../onboarding-module/onboarding.service';
import {catchError, filter, takeUntil} from 'rxjs/operators';
import {Observable, Subject, throwError} from 'rxjs';
import {CustomValidators} from '../../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {Address} from '../../models/models';
import {StorageService} from '../../../core-module/services/storage.service';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';

@Component({
  selector: 'sp-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {

  addressForm: UntypedFormGroup;
  name: string;
  onboardingType$: Observable<any>;
  footerConfig = {btnType: FooterButtonType.FORMSUBMIT, footerBtnText: 'Ga verder', outputBtnClickEvent: () => {return this.submit();}};
  stepper = 2;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private onboardingService: OnboardingService,
    private onboardingTypeService: OnboardingTypeService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.createForm();
    this.checkStorageForAddress();
    this.name = this.getName();
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
    this.stepper = this.onboardingTypeService.returnOnboardType$getValue()==='SHORT'?2:1;
    this.autofillForm();
  }

  ngOnDestroy(): void {
    this.onboardingService.setCurrentStep();
  }

  createForm() {
    this.addressForm = this.formBuilder.group({
      street: [null, [Validators.required]],
      number: [null, [Validators.required]],
      postalCode: [null, [Validators.required, CustomValidators.postcode]],
      city: [null, [Validators.required]],
      isBrightPensioenMember: [null],
    });
  }

  submit() {
    // let address = this.addressForm.get('street').value + ' ' + this.addressForm.get('number').value
    if (!this.addressForm.valid) {
      this.addressForm.markAllAsTouched();
    } else {

      const regCode = this.onboardingService.retrieveRegCode();
      const address: Address = this.addressForm.value;
      const onBoardingData = this.storageService.getOnboardingData();
      this.onboardingService.setAndStoreAddress(address, regCode, onBoardingData.encryptedUserId, onBoardingData.hashedEmail)
        .pipe(
          catchError(err => {
            console.error('Cannot save this registration code: ', regCode);
            return throwError(err);
          })
        )
        .subscribe(
          () => {
            this.storageService.updateOnboardingData({isBrightPensioenMember: address.isBrightPensioenMember});
            const destroy$ = new Subject();
            this.onboardingType$.pipe(
              filter(x => {return !!x;}),
              takeUntil(destroy$)
            ).subscribe((type: OnboardingType) => {
              if (type === OnboardingType.PROSPECTS) {
                this.router.navigate(['sp-prospects/company']);
              } else {
                this.router.navigate(['company']);
              }
            });
          }
        );
    }
  }

  private checkStorageForAddress() {
    const address: Address = this.onboardingService.retrieveAddress();
    if (address) {
      this.addressForm.patchValue(address);
    }
  }

  private getName() {
    return this.onboardingService.getItem('firstName');
  }

  autofillForm() {
    if (isDevMode()) {
      this.addressForm.patchValue({street: 'Street', number: '123', postalCode: '1234AB', city: 'Almere'});
    }
  }
}
