import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {CustomValidators} from '../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {UserService} from '../../core-module/user.service';
import {RegistrationRestService} from '../registration.rest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {RegistrationService} from '../registration.service';

export interface SetPassword {
  activationCode;
  password1;
  password2;
}

@Component({
  selector: 'sp-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  newPasswordForm: UntypedFormGroup;
  inputIsPassword = true;
  inputIsConfirmedPassword = true;
  userEmail;

  displayError = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    private restService: RegistrationRestService,
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
  ) { }

  ngOnInit() {
    // TODO: re-activate this when backend is complete
    this.checkTokenValidity(this.getActivationCode());
    this.createForm();
  }


  createForm() {
    this.newPasswordForm = this.formBuilder.group({
      password: [null, [Validators.required]],
      confirmedPassword: [null, [Validators.required]],
    }, {validators: [CustomValidators.MatchFields('password', 'confirmedPassword')]});
  }

  showPassword(){
    return this.inputIsPassword = !this.inputIsPassword;
  }

  showConfirmedPassword(){
    return this.inputIsConfirmedPassword = !this.inputIsConfirmedPassword;
  }

  submit() {
    if(this.displayError) {
      this.router.navigate(['../forgot-password']);
    } else {
      if (this.newPasswordForm.valid) {
        const activationCode = this.getActivationCode();
        const body: SetPassword = {
          activationCode,
          password1: this.newPasswordForm.get('password').value,
          password2: this.newPasswordForm.get('confirmedPassword').value
        };
        this.restService.newPassword(body)
          .pipe(
            catchError((origError) => {
              if (origError.status === 404) {
                this.displayError = true;
                return throwError(origError);
              }
            })
          )
          .subscribe(
            () => {
              const loginDetails = {
                email: this.userEmail,
                password: body.password1
              };
              this.registrationService.login(loginDetails)
                .pipe(
                  catchError((origError) => {
                    if (origError) {
                      this.displayError = true;
                      return throwError(origError);
                    }
                  })
                )
                .subscribe(
                  (credentials) => {
                    if (credentials && credentials.access_token) {
                      this.router.navigate(['/dashboard']);
                    }
                  });
            }
          );
      } else {
        this.newPasswordForm.markAllAsTouched();
      }
    }
  }

  getActivationCode(): string {
    if (this.route.snapshot.queryParams.code) {
      return this.route.snapshot.queryParams.code;
    } else {
      return this.userService.getUserDetails().activationCode;
    }
  }

  private checkTokenValidity(resetToken) {
    this.restService.checkResetTokenValidity(resetToken)
      .pipe(
        catchError(
          error => {
            if (error.status === 404) {
              this.showInvalidTokenModal();
            }
            return throwError(error);
          }
        )
      )
      .subscribe(
        data => {
          this.userEmail = data.email;
        }
      );
  }

  private showInvalidTokenModal() {
    // alert('Deze link is niet meer geldig. Vraag opnieuw een wachtwoord reset aan.');
    this.displayError = true;
    // this.router.navigate(['../forgot-password']);
  }
}
