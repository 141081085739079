import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class HeadersInterceptorService implements HttpInterceptor {

  constructor() { }

  addHeaders<T>(req: HttpRequest<T>): HttpRequest<T> {
    return req.clone({
      url: `${req.url}`,
      setHeaders: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      return next.handle(this.addHeaders(req));
    } else {
      return next.handle(req);
    }
  }

}
