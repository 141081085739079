import {Component, inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {RouterModule} from "@angular/router";
import {MyAreaRestService} from "../../my-area.rest.service";
import {AccordionThemes, ButtonTextRecord} from "./ui/accordion/model";
import {ISelfTest, Score} from "../dashboard.component";
import {AccordionComponent} from "./ui/accordion/accordion.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'sp-portal-self-test-results',
  templateUrl: './self-test-advice.component.html',
  styleUrls: ['./self-test-advice.component.scss'],
  standalone: true,
  imports: [AccordionComponent, RouterModule, CommonModule]
})
export class SelfTestAdviceComponent implements OnInit {
  @ViewChildren(AccordionComponent) accordions?: QueryList<AccordionComponent>;
  private restService: MyAreaRestService = inject(MyAreaRestService)
  public testData: ISelfTest[] = [];
  themes = AccordionThemes;

  ngOnInit() {
    this.restService.getSelfTestLists().subscribe((value) => {
      this.testData = value;
      this.testData[0].scores[0].expanded = true;
      this.testData[0].scores.map((score) => {
        this.setButtonTextAndHrefLink(score);
      })
    });
  }

  navigateToThemePage(pageLink: string) {
    window.open(pageLink, '_blank');
  }

  setButtonTextAndHrefLink(score: Score) {
    score.buttonText = this.buttonText[AccordionThemes[score.group]].buttonText
    score.buttonLink = this.buttonText[AccordionThemes[score.group]].hrefLink
    return score;
  }

  navigateToHealthPage() {
    window.open('https://www.sharepeople.nl/gezond-ondernemen/', '_blank');
  }

  collapseAccordions($event, index) {
    // We only want to expand one accordion at the time, so we make sure the other ones are closed.
    this.testData[0].scores.filter((score, scoreIndex) => {
      return (scoreIndex === index) ? this.testData[0].scores[index].expanded = $event : score.expanded = false;
    })
  }

  protected readonly AccordionThemes = AccordionThemes;
  protected readonly buttonText = ButtonTextRecord;
  protected readonly parseInt = parseInt;
  protected readonly parseFloat = parseFloat;
}
