import {AfterViewInit, Component, inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Router} from "@angular/router";
import {MyAreaRestService} from "../../my-area.rest.service";
import {UserProfile} from "../../profile/profile.component";
import {AccordionThemes} from "./ui/accordion/model";
import {ISelfTest} from "../dashboard.component";
import {AccordionComponent} from "./ui/accordion/accordion.component";

@Component({
  selector: 'sp-portal-self-test-results',
  templateUrl: './self-test-advice.component.html',
  styleUrls: ['./self-test-advice.component.scss']
})
export class SelfTestAdviceComponent implements  OnInit, AfterViewInit{
  @ViewChildren(AccordionComponent) accordions?: QueryList<AccordionComponent>;
  accordionStates: boolean[] = [];
  private restService: MyAreaRestService  = inject(MyAreaRestService)
  public testData:ISelfTest[];
  themes = AccordionThemes;

  ngOnInit() {
     let memberProfile: UserProfile;
    this.restService.getSelfTestLists().subscribe((value) => {
      this.testData = value;
    });

  }

  ngAfterViewInit() {
    this.accordions.forEach((accordion: AccordionComponent, index) => {
      if(index === 0) {
        this.accordionStates.push(true)
      }
      this.accordionStates.push(accordion.collapsed);
      accordion.emmitCollapseStateAccordion.subscribe((collapseState) => {
        this.collapseAccordions(!collapseState, index);
      })
    })
  }
  navigateToHealthPage() {
    window.open('https://www.sharepeople.nl/gezond-ondernemen/', '_blank');
  }

  collapseAccordions($event, index) {
    //Only allow one to be opened, if the index is correct we set that one to true , therefore the rest remains false.
    this.accordionStates = this.accordionStates.map((_, i) => i === index);
    this.accordionStates[index] =$event;
  }


  protected readonly AccordionThemes = AccordionThemes;
}
