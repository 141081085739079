import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';

@Component({
  selector: 'sp-paticipation',
  templateUrl: './paticipation.component.html',
  styleUrls: ['./paticipation.component.scss']
})
export class PaticipationComponent implements OnInit {

  footerConfig = {btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Ik begrijp dit', outputBtnClickEvent: () => {return this.submit();}};
  onboardingType$: Observable<any>;

  constructor(
    private router: Router,
    private onboardingTypeService: OnboardingTypeService,
  ) {
  }

  ngOnInit(): void {
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
  }

  submit() {
    const destroy$ = new Subject();
    this.onboardingType$.pipe(
      filter(x => {return !!x;}),
      takeUntil(destroy$)
    ).subscribe((type: OnboardingType) => {
      if (type === OnboardingType.PROSPECTS) {
        this.router.navigate(['sp-prospects/promise']);
      } else {
        this.router.navigate(['sp-long/promise']);
      }

      destroy$.next();
    });

  }
}
