import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[spSpecialCharTrimmer]'
})
export class SpecialCharTrimmerDirective {

  hostElem;

  constructor(
    private elementRef: ElementRef
  ) {
    this.hostElem = this.elementRef.nativeElement;
  }

  @HostListener('keyup') removechars() {
    if (this.hostElem.value) {
      this.hostElem.value = this.hostElem.value.replace(/[\W_]/g, '');
    }
  }

}
