<div class="popup-background" [class.active]="popupActive">
  <div class="popup-container">
    <div class="flex-container">
      <div class="info">
        <h3>Doe je mee met de SharePeople AOV in 2022?</h3>
        <div class="info-wrapper">
          <p>SharePeople is, in samenwerking met enkele partners, bezig met de ontwikkeling van een eigen AOV die aansluit op de huidige dekking van 2 jaar arbeidsongeschiktheid.</p>
          <p>Deze ‘SharePeople AOV’ wordt waarschijnlijk een combinatie van een verzekering (basisdekking) en crowdsurance en zal gelden tot AOW-leeftijd.</p>
          <p>Dit product zal in de loop van 2022 gelanceerd worden.</p>
          <p>Hoe groter de groep ondernemers is die met ons meedoet, hoe beter onze onderhandelingspositie zal zijn en (dus) hoe beter de voorwaarden kunnen zijn.</p>
          <p>Graag zouden we willen weten of je interesse hebt om met dit product mee te doen.</p>
          <p>Zo ja, geef dat hieronder aan. Je zit nog nergens aan vast en we delen deze informatie NIET met derden.</p>
        </div>
      </div>
      <form>
        <ng-container *ngFor="let option of extendedCoverageOptions">
          <label class="container">
            {{option.option}}
            <input type="radio" id="{{option.id}}" [formControl]="extendedCoverageResponse" [value]="option.id">
            <span class="checkmark"></span>
          </label>
        </ng-container>
        <div class="button-wrapper">
          <button type="button" [sp-button] (click)="handleSubmit()">OK</button>
        </div>
      </form>
    </div>
  </div>
</div>
