import { Component, OnInit } from '@angular/core';
import {OverlayConfig} from '../../models/models';
import {TcOverlayService} from '../../tc-overlay/tc-overlay.service';
import {Router} from '@angular/router';
import {IUserPromiseConfirmation, SpLongInvitationCode} from '../../../sp-long-module/models/sp-long.models';
import {SpLongService} from '../../../sp-long-module/services/sp-long-service/sp-long.service';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';

@Component({
  selector: 'sp-promise',
  templateUrl: './sp-long-promise.component.html',
  styleUrls: ['./sp-long-promise.component.scss']
})
export class SPLongPromiseComponent implements OnInit {

  promiseAccepted = false;
  TCAccepted = false;
  invitationCode: string;

  footerConfig = {btnType: FooterButtonType.CLICKEVENT, footerBtnText: 'Ga verder', outputBtnClickEvent: () => {return this.submit();}};

  constructor(
    private tcOverlayService: TcOverlayService,
    private spLongService: SpLongService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.invitationCode = localStorage.getItem(SpLongInvitationCode.SPLONGINVITATIONCODE);
  }

  openOverlay(overlay: OverlayConfig) {
    this.tcOverlayService._overlay.next(overlay);
  }

  getTermsOverlay($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.openOverlay({
      overlayType: 'terms',
      overlayContent: ''
    });
  }

  getPrivacyOverlay($event: any): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.openOverlay({
      overlayType: 'privacy',
      overlayContent: ''
    });
  }

  getPromiseOverlay($event: any): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.spLongService.getPromiseHTML( this.invitationCode)
      .subscribe(
        promiseHTML => {
          this.openOverlay({
            overlayType: 'promise',
            overlayContent: promiseHTML.promises ? promiseHTML.promises : promiseHTML.promise
          });
        }
      );
  }

  submit() {
    const promiseObject: IUserPromiseConfirmation = {
      acceptPrivacy: this.promiseAccepted,
      acceptPromise: this.TCAccepted
    };
    if (this.promiseAccepted && this.TCAccepted) {
      this.spLongService.postPromiseConfirmation( this.invitationCode, promiseObject).subscribe(() => {return this.router.navigate(['sp-long/payment-plan']);});
    }
  }
}
