import {Component, OnInit} from '@angular/core';
import {IHealthTestData} from '../../../sp-long-module/models/sp-long.models';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';

@Component({
  selector: 'sp-rejected-screen',
  templateUrl: './rejected-screen.component.html',
  styleUrls: ['./rejected-screen.component.scss']
})
export class RejectedScreenComponent implements OnInit {

  footerConfig = {
    btnType: FooterButtonType.CLICKEVENT,
    footerBtnText: 'Deel jouw mening',
    outputBtnClickEvent: () => {return this.redirectToFeedbackForm();}
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  handleExit() {
    window.close();
  }

  redirectToFeedbackForm() {
    window.open('https://8ngsd7m3vi8.typeform.com/to/JeiAOUoF', '_blank');
  }

}
