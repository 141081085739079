<div class="popup-background" [class.active]="popupActive">
  <div class="popup-container">
    <div class="close-button" (click)="close()"></div>
    <form [formGroup]="formGroup" #form='ngForm'>
      <h4>Persoonlijk</h4>
      <div class="item-container"
           [class]="form.submitted && formGroup.get('streetAndNumber').errors?'item-container hasError' : 'item-container'">
        <div class="input-wrapper">
          <sp-input-wrapper label="Adres" [error]="form.submitted ? formGroup.get('streetAndNumber').errors : null">
            <input type="text" formControlName="streetAndNumber">
          </sp-input-wrapper>
        </div>
      </div>
      <div class="item-container"
           [class]="form.submitted && (formGroup.get('postalCode').errors || formGroup.get('city').errors)?'item-container hasError' : 'item-container'">

        <div class="input-wrapper address">
          <sp-input-wrapper label="Postcode" [error]="form.submitted ? formGroup.get('postalCode').errors : null">
            <input type="text" formControlName="postalCode" placeholder="Postcode" class="toUppercase">
          </sp-input-wrapper>
          <sp-input-wrapper label="Stad" class="city" [error]="form.submitted ? formGroup.get('city').errors : null">
            <input type="text" formControlName="city" placeholder="Stad">
          </sp-input-wrapper>
        </div>
      </div>

      <div class="item-container">
        <div class="input-wrapper">
          <sp-input-wrapper
            label="Geboortedatum"
            class="sp-date-select"
            [error]="form.submitted ? formGroup.get('dob').errors : null">
            <input type="date" formControlName="dob">
          </sp-input-wrapper>
        </div>
      </div>
      <div class="btn-wrapper">
        <button [disabled]="formHasChanged" [sp-button] (click)="saveChanges()" class="save-btn">Opslaan</button>
      </div>
    </form>
  </div>
</div>
