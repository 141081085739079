import { NgModule } from '@angular/core';
import {SpCurrencyPipe} from 'sp-shared-lib';

import { MyAreaRoutingModule } from './my-area-routing.module';
import { MyAreaComponent } from './my-area.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {SharedModulePortal} from '../shared-module/shared-module.module';
import {DonationModule} from '../donation/donation.module';
import { ProfileComponent } from './profile/profile.component';
import {RouterLink, RouterLinkActive, RouterModule, RouterOutlet} from '@angular/router';
import { ChangeContributionComponent } from './change-contribution/change-contribution.component';
import { ContributionHistoryComponent } from './contribution-history/contribution-history.component';
import {OccupationPopupComponent} from './dashboard/occupation-popup/occupation-popup.component';
import { ReportComponent } from './report/report.component';
import { ExtendedCoveragePopupComponent } from './dashboard/extended-coverage-popup/extended-coverage-popup.component';
import {CookieService} from "ngx-cookie-service";
import { ContactPopupComponent } from './profile/contact-popup/contact-popup.component';
import { PersonalPopupComponent } from './profile/personal-popup/personal-popup.component';
import { CompanyPopupComponent } from './profile/company-popup/company-popup.component';
import { AOVTermsPopupComponent } from './dashboard/aov-terms-popup/aov-terms-popup.component';
import { LongWarningPopupComponent } from './dashboard/long-warning-popup/long-warning-popup.component';
import {GktFormsLibraryModule} from "gkt-forms-library";
import {CommonModule} from "@angular/common";
import {PortalModule} from "@angular/cdk/portal";
import {CdkConnectedOverlay, CdkOverlayOrigin} from "@angular/cdk/overlay";
import {NgOptimizedImage} from "@angular/common";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ReactiveFormsModule} from "@angular/forms";
import {SelfTestComponent} from "./dashboard/self-test/self-test.component";


@NgModule({
  imports: [
    RouterModule,
    SharedModulePortal,
    MyAreaRoutingModule,
    DonationModule,
    GktFormsLibraryModule,
    PortalModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    MatTooltipModule,
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    SharedModulePortal,
    MyAreaRoutingModule,
    DonationModule,
    GktFormsLibraryModule,
    NgOptimizedImage,
    MatTooltipModule,
    ReactiveFormsModule,
    SelfTestComponent,
  ],
  providers: [
    SpCurrencyPipe,
    CookieService
  ],
  declarations: [
    MyAreaComponent,
    DashboardComponent,
    ProfileComponent,
    ChangeContributionComponent,
    ContributionHistoryComponent,
    OccupationPopupComponent,
    ReportComponent,
    ExtendedCoveragePopupComponent,
    ContactPopupComponent,
    PersonalPopupComponent,
    CompanyPopupComponent,
    AOVTermsPopupComponent,
    LongWarningPopupComponent,
  ],
  exports: [
  ]
})
export class MyAreaModule { }
