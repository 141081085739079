import {Injectable} from '@angular/core';
import {OnboardingData} from '../../onboarding-module/onboarding.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clearAllCredentials() {
    console.log('clearing all now');
    window.localStorage.clear();
  }

  getOnboardingData(): OnboardingData {
    const onboardingDataString = window.localStorage.getItem('onboardingData');
    if (onboardingDataString) {
      const onboardingData: OnboardingData = JSON.parse(onboardingDataString);
      if (!onboardingData) {
        return null;
      } else {
        return onboardingData;
      }
    } else {
      return null;
    }
  }

  private setOnboardingData(onboardingData: OnboardingData) {
    if (!onboardingData) {
      throw new Error('Cannot store nothing in onboardingData');
    }
    window.localStorage.setItem('onboardingData', JSON.stringify(onboardingData));
  }

  updateOnboardingData(newData: OnboardingData) { // object that contains some things
    try {
      const currentData = this.getOnboardingData();
      const updatedData = {...currentData, ...newData};
      this.setOnboardingData(updatedData);
      return updatedData;
    } catch {
      throw new Error('Failed to save data to LocalStorage');
    }
  }

}
