import { Injectable } from '@angular/core';
import {
  format,
  parseISO,
  differenceInYears
} from 'date-fns';

@Injectable({
  providedIn: 'root'
})
/**
 * This is an Abstraction layer over a date manipulation library.
 * The reason for having this is so that ALL uses of the library come through here that
 *  way if we want to change the Library, we only need to do it here.
 */

/*
This project uses date-fns see https://date-fns.org/docs for info
 */
export class DateTimeService {

  private readableDatePattern = 'd MMMM yyyy';

  constructor() { }

  private parseDate(isoDate: string): Date {
    return parseISO(isoDate);
  }

  convertIsoToReadable(isoDate: string): string {
    if (!isoDate) {
      return;
    }
    return format(this.parseDate(isoDate), this.readableDatePattern);
  }

  getAgeFromISOBirthdate(birthdate: string): number {
    return differenceInYears(new Date(), parseISO(birthdate));
  }

}

