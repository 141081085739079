<div class="navbar-container">
  <div class="menu" (click)="sideMenuShown = true">
    <ng-container *ngTemplateOutlet="menuIcon"></ng-container>
  </div>
  <div class="logo" (click)="gotoDashboard()">
    <img [src]="(media | async).logo" [alt]="(media | async).alt" class="mobile-logo"/>
    <img [src]="(media | async).logo" [alt]="(media | async).alt" class="desktop-logo"/>
  </div>
  <ng-container *ngTemplateOutlet="menuList"></ng-container>
  <div class="profile">
    <ng-container *ngTemplateOutlet="userIcon"></ng-container>
  </div>
</div>


<ng-container>
  <div class="background-close" [class.side-menu-shown]="sideMenuShown" (click)="sideMenuShown=false"></div>
  <div class="side-menu-container" [class.side-menu-shown]="sideMenuShown">
    <div class="close-button" (click)="sideMenuShown = false">
      <ng-container *ngTemplateOutlet="crossIcon"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="menuList"></ng-container>
  </div>
</ng-container>


<ng-template #menuList>
  <ul class="menu-list">
    <li [class.active]="activePage('/dashboard')" (click)="gotoDashboard()">Overzicht</li>
    <li (click)="gotoFAQ()">FAQ voor deelnemers</li>
    <li [class.active]="activePage('/dashboard/profile')" (click)="gotoProfile()">Mijn gegevens</li>
    <li class="logout" (click)="logout()">Log uit</li>
  </ul>
</ng-template>


<!--ICONS-->
<ng-template #userIcon>
  <svg style="fill: #46b278" viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg" (click)="gotoProfile()">
    <path
      d="M240 0a240 240 0 1041.4 476.4l2.5-.5A240 240 0 00240 0zm-19.3 463.2h-.5c-6.3-.6-12.4-1.4-18.6-2.5l-1-.2c-6-1-12-2.4-17.8-4l-1.4-.3c-5.7-1.5-11.4-3.3-17-5.3l-1.7-.6c-5.5-2-10.8-4.3-16.1-6.7l-2-1c-5.2-2.3-10.2-5-15.2-7.9l-2.1-1.1c-5-3-9.7-6-14.4-9.2l-2.2-1.5a225 225 0 01-13.5-10.4l-1.2-1V344c0-48.6 39.4-88 88-88h112c48.6 0 88 39.4 88 88v67.5l-1 .8c-4.5 3.8-9.1 7.3-13.8 10.6l-2 1.3c-4.7 3.3-9.6 6.4-14.5 9.4l-2 1a216.3 216.3 0 01-33.4 15.6l-1.6.6c-5.6 2-11.3 3.8-17 5.4l-1.5.3c-5.8 1.6-11.8 3-17.7 4l-1 .1c-6.2 1.1-12.4 2-18.6 2.5h-.6a224.3 224.3 0 01-38.6 0zM400 396.6V344c0-57.4-46.6-104-104-104H184c-57.4 0-104 46.6-104 104v52.6a224 224 0 11320 0zm0 0"/>
    <path d="M240 64a80 80 0 100 160 80 80 0 000-160zm0 144a64 64 0 110-128 64 64 0 010 128zm0 0"/>
  </svg>
</ng-template>

<ng-template #menuIcon>
  <svg style="fill: #46b278" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px"
       viewBox="0 0 341.333 341.333" xml:space="preserve">
		<rect y="277.333" width="341.333" height="42.667"/>
    <rect y="149.333" width="341.333" height="42.667"/>
    <rect y="21.333" width="341.333" height="42.667"/>
  </svg>
</ng-template>

<ng-template #crossIcon>
  <svg style="fill: #46b278" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492">
    <path
      d="M300 246L484 62a27 27 0 000-38L468 8a27 27 0 00-38 0L246 192 62 8a27 27 0 00-38 0L8 24a27 27 0 000 38l184 184L8 430a27 27 0 000 38l16 16a27 27 0 0038 0l184-184 184 184a27 27 0 0038 0l16-16a27 27 0 000-38L300 246z"/>
  </svg>
</ng-template>
