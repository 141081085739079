/*
This service takes a validation error object and returns a string for the first validation error that it finds.
It prioritises the required error over others.

 */
import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationErrorMessage {
  errorsMap: Map<string, (x) => string> = new Map([
    ['required', this.required],
    ['invalidEmail', this.invalidEmail],
    ['invalidName', this.invalidName],
    ['invalidPhone', this.invalidPhone],
    ['invalidPostcode', this.invalidPostcode],
    ['minlength', this.min],
    ['matchFields', this.matchFields],
    ['invalidKvk', this.invalidKvk],
    ['invalidIban', this.invalidIban],
    ['dateLengthError', this.dateLengthError],
    ['dateRangeError', this.dateRangeError],
    ['yearRangeError', this.yearRangeError],
    ['maxHours', this.maxHours],
    ['bsnError', this.bsnError],
    ['maxError', this.maxError],
    ['minError', this.minError],
    ['isNotTrue', this.isNotTrue],
    ['invalidDate', this.dateLengthError]
  ]);

  public getError(errorsObject: ValidationErrors) {
    let errorKey;
    let errorFn;

    if (errorsObject.hasOwnProperty('required')) {
      errorKey = 'required';
    } else {
      errorKey = Object.keys(errorsObject)[0];
    }
    if (this.errorsMap.get(errorKey)) {
      errorFn = this.errorsMap.get(errorKey);
      return errorFn(errorsObject[errorKey]);
    } else {
      console.error('There is a validation error message missing for: ', errorKey);
      return null;
    }
  }

  private maxError(error): string {
    console.log(error);
    const requiredMax = error;
    return `Maximaal ${requiredMax} karakters`;
  }

  private bsnError(error): string {
    console.log(error);
    const requiredMin = error;
    return 'Ongeldig BSN';
  }

  private minError(error): string {
    console.log(error);
    const requiredMin = error;
    return `Minimaal ${requiredMin} karakters`;
  }

  private maxHours(error): string {
    console.log(error);
    const requiredMax = error;
    return `Maximaal ${requiredMax} uur`;
  }

  private required(): string {
    return 'Dit veld mag niet leeg zijn';
  }

  private invalidName(): string {
    return 'Dit is geen geldige naam';
  }

  private invalidPhone(): string {
    return 'Een telefoonnummer bestaat uit tien cijfers en begint met 06.';
  }

  private invalidEmail(): string {
    return 'Dit is geen geldig email adres';
  }

  private invalidPostcode(): string {
    return 'Dit is geen geldige postcode';
  }

  private min(error): string {
    const requiredMin = error.requiredLength;
    return `Dit moet uit minimaal ${requiredMin} tekens bestaan`;
  }

  private matchFields(): string {
    return 'De wachtwoorden zij niet gelijk';
  }

  private invalidKvk(): string {
    return 'Een kvknummer bestaat uit acht cijfers.';
  }

  private invalidIban(): string {
    return 'Dit is geen geldige IBAN';
  }

  private dateLengthError(): string {
    return 'Datumnotatie moet dd-mm-yyyy zijn';
  }

  private dateRangeError(error): string {
    const dateType = ['Day', 'Month'][error.index];
    return `Datumnotatie moet dd-mm-yyyy zijn. ${dateType} is onjuist.`;
  }

  private yearRangeError(error): string {
    return `Jaar zou tussen ${error.min} en ${error.max} moeten liggen (dd-mm-yyyy)`;
  }

  private isNotTrue(): string {
    return 'Dit veld mag niet leeg zijn';
  }


}
