import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SpLongRestService} from '../../../sp-long-module/services/rest-service/sp-long-rest.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../../../../gkt-forms-library/src/lib/validation-service/custom-validators';
import {Router} from '@angular/router';
import {SpLongInvitationCode} from '../../../sp-long-module/models/sp-long.models';
import {FooterButtonType} from '../../onboard-page-content-wrapper/onboard-page-content-wrapper.component';
import {OnboardingService} from '../../../onboarding-module/onboarding.service';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'sp-knowledge-test',
  templateUrl: './knowledge-test.component.html',
  styleUrls: ['./knowledge-test.component.scss']
})

export class KnowledgeTestComponent implements OnInit {
  hasUnderstood = true;
  isRejected = false;
  onboardingType$: Observable<any>;
  knowledgeTestForm: UntypedFormGroup = this.formBuilder.group({});
  footerConfig = {
    btnType: FooterButtonType.CLICKEVENT,
    footerBtnText: 'Ga verder',
    outputBtnClickEvent: () => {return this.submitUserAsUnderstood();},
    noteBefore: true
  };

  constructor(
    private restService: SpLongRestService,
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    private onboardingService: OnboardingService,
    private onboardingTypeService: OnboardingTypeService
  ) {

  }

  ngOnInit(): void {
    this.buildForm();
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
    this.knowledgeTestForm.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.hasUnderstood = true;
      }
    });
  }

  updateStatus(): void {
    this.hasUnderstood = !this.hasUnderstood;
    this.knowledgeTestForm.patchValue({
      first: false,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      sixth: false,
      seventh: false,
      eight: false
    });
  }

  submitUserAsUnderstood(): void {
    const spLongInvitationCode = localStorage.getItem(SpLongInvitationCode.SPLONGINVITATIONCODE);
    this.onboardingService.storeknowledgeCheck(this.hasUnderstood);

    if (this.onboardingTypeService.returnOnboardType$getValue() === OnboardingType.PROSPECTS) {
      this.router.navigate(['sp-prospects/name-and-contact']);
    } else {
      this.restService.postKnowledgeTestResult(spLongInvitationCode, this.hasUnderstood).subscribe((res) => {
        this.router.navigate(['sp-long/good-to-know']);
      });
    }
  }

  buildForm(): void {
    this.knowledgeTestForm = this.formBuilder.group({
      first: [null, [Validators.required, CustomValidators.valueIsTrue]],
      second: [null, [Validators.required, CustomValidators.valueIsTrue]],
      third: [null, [Validators.required, CustomValidators.valueIsTrue]],
      fourth: [null, [Validators.required, CustomValidators.valueIsTrue]],
      fifth: [null, [Validators.required, CustomValidators.valueIsTrue]],
      sixth: [null, [Validators.required, CustomValidators.valueIsTrue]],
      seventh: [null, [Validators.required, CustomValidators.valueIsTrue]],
      eight: [null, [Validators.required, CustomValidators.valueIsTrue]],
    });
  }
}
