<div class="image-container">
  <img src="assets/images/waving-crutch.png" alt="lady looking for something">
</div>
<section class="introduction">
  <h4>Oeps. Er is iets mis gegaan. </h4>
  <p>
    Dat is niet zo handig. Wij gaan uitzoeken wat er aan de hand is.
  </p>
  <p>
    Als dit nou vaker gebeurt moet je even met ons <a href="#">contact opnemen</a>. Dan helpen we je.
  </p>
</section>
