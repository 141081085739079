import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {KycData} from '../models/kyc-data.model';
import {KycService} from '../services/kyc.service';
import {Router} from '@angular/router';

@Component({
  selector: 'sp-portal-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  addressDetailsForm: UntypedFormGroup;
  kycData$: Observable<KycData>;

  constructor(
    private kycService: KycService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.kycData$ = this.kycService.kycData$;
    this.createForm();
    this.subscribeToUpdates();
  }

  submit() {
    if (this.addressDetailsForm.valid) {
      this.kycService.updateKycData({...this.addressDetailsForm.value});
      this.kycService.updateKycData({...this.addressDetailsForm.value})
        .subscribe(() => {return this.goToSummary();});
    } else {
      this.addressDetailsForm.markAllAsTouched();
    }
  }

  private createForm() {
    this.addressDetailsForm = this.formBuilder.group({
      street: ['', [Validators.required]],
      houseNumber: ['', [Validators.required]],
      extension: [''],
      postalCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      province: ['', [Validators.required]],
      country: ['', [Validators.required]]
    });
    // Is it better to do this, or to initialise the form with the currentState values?
    // Neither - remember you want to always update the form when the
    // kycData$ changes. therefore you must subscribe, not use the current value
  }

  private subscribeToUpdates() {
    this.kycData$.subscribe(
      (data: KycData) => {
        if (data) {
          this.addressDetailsForm.patchValue(data);
        }
      }
    );
  }

  private goToSummary() {
    this.router.navigate(['./kyc/summary']);
  }
}
