import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HeadersInterceptorService} from './core-module/interceptors/headers.interceptor.service';
import {TokenInterceptorService} from './core-module/interceptors/token-interceptor.service';
import {RegistrationModule} from './registration-module/registration.module';
import {CoreModule} from '../../../onboarding/src/app/core-module/core.module';
import {DonationModule} from './donation/donation.module';
import {SharedModulePortal} from './shared-module/shared-module.module';
import {KycModule} from './kyc/kyc.module';
import {MyAreaModule} from './my-area-module/my-area.module';
import {CookieService} from 'ngx-cookie-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SelfTestComponent } from './my-area-module/dashboard/self-test/self-test.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {SelfTestAdviceComponent} from "./my-area-module/dashboard/self-test-advice/self-test-advice.component";
import {AccordionComponent} from "./my-area-module/dashboard/self-test-advice/ui/accordion/accordion.component";

@NgModule({
  declarations: [
    AppComponent,
    SelfTestAdviceComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModulePortal,
    KycModule,
    AppRoutingModule,
    RegistrationModule,
    DonationModule,
    MyAreaModule,
    MatTooltipModule,
    SelfTestComponent,
    AccordionComponent
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
