import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {Observable, Subscription} from 'rxjs';
@Component({
  selector: 'sp-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss', '../../../../global-scss/responsive-wrapper.scss']
})
export class RegistrationComponent implements OnInit {
  media: Observable<any>;
  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.media = this.themeService.getLogo();
  }

}
