<sp-onboard-page-content-wrapper [footerConfig]="footerConfig" [btnDisabled]="disableSendButton"
                                 [showStepper]="true"
                                 [stepperConfig]="{currentStep: paginatorStep, onboardingType: (oboardingType$ | async)}">
  <br/><br/>
  <div *ngIf="spLongAvailabe">
    <section class="introduction">
        <ng-container *ngIf="(oboardingType$ | async) === 'LONG'">
          <h4>Kostenoverzicht SharePeople Lang</h4>
          <p>Jouw inkomensniveau in de eerste 2 jaar: {{formatAmount(costAndCoverage?.currentShortCoverage)}}</p><br/>
          <h5>Kies jouw gewenste inkomensniveau na 2 jaar:</h5>
        </ng-container>

    <ng-container *ngIf="(oboardingType$ | async) === 'PROSPECTS'">
      <h4>Kies je gewenste netto inkomen per maand</h4>
      <p>Hoeveel netto-inkomen wil je ontvangen als je arbeidsongeschikt raakt?</p>
    </ng-container>

        <form [formGroup]="coverageForm" #form='ngForm'>
          <label class="checkbox-group-label">Maak je keuze:</label>
          <div class="radio-group">
            <ng-container *ngFor="let option of costAndCoverage?.coverages">
              <sp-input-wrapper *ngIf="!option.hidden"
                                [label]="formatAmount(option.longCoverage)"
                                [error]="null">
                <input type="radio" formControlName="coverage" name="coverage" [value]="option.longCoverage">
              </sp-input-wrapper>
            </ng-container>
          </div>
        </form>

        <p class="sub-text" *ngIf="(oboardingType$ | async) === 'PROSPECTS'">Twijfel je? Niks aan de hand. Je kan het bedrag later nog aanpassen.</p>

        <ng-container *ngIf="(oboardingType$ | async) === 'LONG'" [ngSwitch]="costAndCoverage?.longCoverage">
          <svg id="sharePeopleLongCoverage" width="100%"  data-name="SharePeople" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 643.89 429.06">
            <g id="shapes" data-name="Shapes">
              <rect id="shortCrowd" class="fill-yellow" x="9.9" y="38.72" width="199.96" height="203"/>
              <rect id="aov" class="fill-lightgreen" x="209.86" y="162.09" width="423.41" height="79.63"/>
              <rect id="longCrowd" class="fill-yellow" x="209.86" y="109" width="423.53" height="53.09"/>
              <line class="line-y" x1="209.8" y1="1.3" x2="210.22" y2="328.87"/>
              <line class="line-x" x1="633.28" y1="242.72" x2="9.9" y2="242.72"/>
              <ellipse class="point" cx="10.85" cy="242.79" rx="10.85" ry="10.67"/>
              <ellipse class="point" cx="208.91" cy="242.59" rx="10.85" ry="10.67"/>
              <ellipse class="point" cx="633.04" cy="242.95" rx="10.85" ry="10.67"/>
            </g>
            <g id="values">
              <text id="sp-long" class="isolate font-big" transform="translate(219.78 19.3)">SharePeople Lang</text>
              <text id="longCrowdAmount" class="fill-green font-big" x="420" y="145" text-anchor="middle">?</text>
              <text id="aovAmount" class="fill-white font-big isolate" x="420" y="210" text-anchor="middle">?</text>
              <text id="shortCrowdAmount" class="fill-green font-big" x="110" y="150" text-anchor="middle">?</text>
            </g>
            <g id="have">
              <text class="isolate font-medium" x="0" y="20">Dit heb je al</text>
              <path class="arrow"
                    d="M28.78,27.72c.91,4,6.84,13.85,19.3,24a55.36,55.36,0,0,0,4.82,3.22c.1-.1.19-.21.28-.31-.26-.44-.51-.89-.78-1.32C51.6,52,50.79,50.78,50,49.5a3.35,3.35,0,0,1-.5-1,1,1,0,0,1,.26-.86.85.85,0,0,1,.83,0,3.86,3.86,0,0,1,.84,1C53,51,54.49,53.31,56,55.64a4.77,4.77,0,0,1,.42.73c.43,1,.35,1.57-.49,1.91-2.26.9-4.54,1.7-6.84,2.5a.91.91,0,0,1-1.17-.51,1.17,1.17,0,0,1,0-.2,1,1,0,0,1,.59-1.29h.05c1.41-.53,2.84-1,4.32-1.57-.12-.2-.17-.38-.27-.44C46,53.25,39.86,46.55,35.19,41.52c-5.72-7.45-3.06-3.87-8.28-13.37-1-2.67,1.45-.79,1.92-.39Z"
                    transform="translate(0 0)"
              />
            </g>
            <g id="legend">
              <path id="legendCrowd" class="fill-yellow" d="M198,338.51h25.51V364H198Z"/>
              <text class="legend-label" transform="translate(234.98 388.8)">AOV</text>
              <path id="legendAOV" class="fill-lightgreen" d="M198,368.86h25.51v25.52H198Z"/>
              <text class="legend-label" transform="translate(234.62 358.98)">Crowdsurance</text>
            </g>
            <g id="labels">
              <text id="start" class="fill-green font-medium isolate" transform="translate(8.38 271.74)">Start</text>
              <text id="after2years" class="fill-green font-medium isolate" transform="translate(221.48 272.56)">na 2 jaar</text>
              <text id="untilAOW" class="fill-green font-medium isolate" transform="translate(562.95 273.24)">tot
                <tspan class="letter-spacing" x="24.26" y="0"></tspan>
                <tspan x="28.83" y="0">AOW</tspan>
              </text>
              <text class="sub-line isolate" transform="translate(168.87 423.86)">*) {{formatAmount(150000)}} bruto is ongeveer {{formatAmount(120000)}} netto.</text>
            </g>
          </svg>
        </ng-container>
    </section>

    <!-- long-->
    <section class="conditions" *ngIf="(spLongAvailabe && (oboardingType$ | async) === 'LONG' || (oboardingType$ | async) === 'PROSPECTS')">
      <ng-container>
        <h4>Je betaalt {{cost | spCurrency}} per maand.</h4>
        <p *ngIf="selectedLongOption?.costComponents">Dit bedrag bestaat uit:</p>
        <ol *ngIf="selectedLongOption?.costComponents">
          <li>AOV-verzekeringspremie ({{selectedLongOption.costComponents.aovPremium | spCurrency}})</li>
          <li>Donatiebedrag (kort:
            {{selectedLongOption.costComponents.shortContribution | spCurrency}},
            lang: {{selectedLongOption.costComponents.longContribution | spCurrency}},
            dit kan fluctueren).</li>
          <li>Deelnamekosten ({{selectedLongOption.costComponents.monthlyFee | spCurrency}}).</li>
        </ol>
      </ng-container>
    </section>
  </div>
  <div *ngIf="(!spLongAvailabe && (oboardingType$ | async) === 'LONG')">
    <h4>Niet beschikbaar</h4>
    <p>Helaas kun je met jouw beroep en leeftijd geen SharePeople Lang afsluiten.</p>
  </div>
  <div *ngIf="(!spLongAvailabe && (oboardingType$ | async) === 'PROSPECTS')">
    <h4>Niet beschikbaar</h4>
    <p>Helaas kom je met je gekozen beroep en leeftijd niet in aanmerking voor SharePeople Lang.</p>
    <p>Start opnieuw en kies voor SharePeople Kort</p>
  </div>
  <!--    short -->
  <section *ngIf="(oboardingType$ | async) === 'SHORT'">
    <ng-container>
      <h4>Je betaalt dan {{cost | spCurrency}} per maand</h4>
      <p>Dit baseren we op de deelnamekosten en het huidige gemiddelde donatiebedrag per maand.</p>
    </ng-container>
  </section>
</sp-onboard-page-content-wrapper>
