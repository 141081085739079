import { Injectable } from '@angular/core';
import {StorageService} from '../core-module/services/storage.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StepNavigationService {

  constructor(
    private storageService: StorageService,
    private router: Router
  ) {
  }

  setCurrentStep(url?: string) {
    const location = url ? url : this.router.url;
    console.log('setting current step to: ', location);
    this.storageService.updateOnboardingData({currentStepUrl: location});
  }
}
