import {Component, Input, OnInit} from '@angular/core';
import {InfoListItem} from '../info-list.model';

@Component({
  selector: 'sp-portal-info-list-item',
  templateUrl: './info-list-item.component.html',
  styleUrls: ['./info-list-item.component.scss']
})
export class InfoListItemComponent implements OnInit {

  @Input() listItem: InfoListItem;

  constructor() { }

  ngOnInit() {
  }

}
