<div class="onboard-page-content-wrapper">
  <sp-paginator *ngIf="showStepper" currentStepIndex="{{stepperConfig.currentStep}}" currentSubStepIndex="{{stepperConfig.currentSubStep}}" [onboardingType]="stepperConfig.onboardingType"></sp-paginator>
  <ng-content></ng-content>

  <section class="footer" *ngIf="displayFooter">
    <ng-container *ngIf="footerConfig.noteBefore" [ngTemplateOutlet]="noteTemplate"></ng-container>
    <ng-container [ngSwitch]="footerConfig.btnType">
      <button *ngSwitchCase="'navigation'" [sp-button] [disabled]="btnDisabled" routerLink="{{footerConfig.navigateTo}}">{{footerConfig.footerBtnText}}</button>
      <button *ngSwitchCase="'clickEvent'" [sp-button] [disabled]="btnDisabled" (click)="footerConfig.outputBtnClickEvent()">{{footerConfig.footerBtnText}}</button>
      <button *ngSwitchCase="'formSubmit'" [sp-button] [disabled]="btnDisabled" (click)="triggerFormSubmit()" type="submit">{{footerConfig.footerBtnText}}</button>
    </ng-container>
    <ng-container *ngIf="!footerConfig.noteBefore" [ngTemplateOutlet]="noteTemplate"></ng-container>
  </section>
</div>

<ng-template #noteTemplate>
  <div *ngIf="showFooterNote" [ngTemplateOutlet]="footerNoteHtml"></div>
</ng-template>
