<sp-onboard-page-content-wrapper
  [footerConfig]="footerConfig"
  [showFooterNote]="true"
  [footerNoteHtml]="exitBtnTemplate">
  <section class="main">
    <p>Jammer genoeg kom je niet in aanmerking voor SharePeople Lang. <b>Natuurlijk kun je gewoon mee blijven doen met SharePeople voor 2 jaar.</b></p>
    <br/>
    <br/>
    <h4>
      Over de selectieprocedure
    </h4>
    <p>We beseffen ons dat we helaas mensen uitsluiten die volgens henzelf een laag of geen gezondheidsrisico vormen. Wij kunnen dit verder niet beoordelen middels bijvoorbeeld een medische keuring. Deze selectie is nodig om SharePeople Lang betaalbaar te houden en gezond in de markt te zetten.</p>
    <br/>
    <p>Kijk op <a href="https://www.sharepeople.nl/selectie">sharepeople.nl/selectie</a> voor meer informatie.</p>
    <br/>
    <br/>
    <section class="footer">
      <button [sp-button] (click)="redirectToFeedbackForm()">Deel jouw mening</button>
    </section>
  </section>
</sp-onboard-page-content-wrapper>

<ng-template #exitBtnTemplate>
  <button id="exit-button" [sp-button] (click)="handleExit()">Sluit aanvraag</button>
  <br/>
</ng-template>
