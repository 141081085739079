import { NgModule } from '@angular/core';

import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationComponent } from './registration.component';
import { SharedModule } from '../../../../onboarding/src/app/shared-module/shared.module';
import { RegistrationRestService } from './registration.rest.service';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import {ConfirmAccountComponent} from './confirm-account/confirm-account.component';
import {SharedModulePortal} from '../shared-module/shared-module.module';

@NgModule({
  imports: [
    SharedModule,
    RegistrationRoutingModule,
    SharedModulePortal
  ],
  declarations: [
    RegistrationComponent,
    LoginComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    ConfirmAccountComponent
  ],
  providers: [
    RegistrationRestService
  ]
})
export class RegistrationModule { }
