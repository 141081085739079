import {Component, OnDestroy, OnInit} from '@angular/core';
import {TcOverlayService} from './tc-overlay.service';
import {DomSanitizer} from '@angular/platform-browser';

export interface OverlayConfig {
  overlayType: string;
  overlayContent: string; // HTML
}

@Component({
  selector: 'sp-tc-overlay',
  templateUrl: './tc-overlay.component.html',
  styleUrls: ['./tc-overlay.component.scss']
})
export class TcOverlayComponent implements OnInit, OnDestroy {

  overlay: OverlayConfig;
  overlayContent;  // HTML

  constructor(
    private tcOverlayService: TcOverlayService,
    private sanitizer: DomSanitizer
  ) {
    this.tcOverlayService.overlay$.subscribe(
      overlay => {
        this.overlay = overlay;

        if (overlay) {
          this.overlayContent = sanitizer.bypassSecurityTrustHtml(overlay.overlayContent);
        }
      }
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.tcOverlayService._overlay.next(null);
  }

}
