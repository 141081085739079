import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sp-portal-long-warning-popup',
  templateUrl: './long-warning-popup.component.html',
  styleUrls: ['./long-warning-popup.component.scss']
})
export class LongWarningPopupComponent {

  @Input() longWarningPopupActive: boolean;
  @Input() spLongUrl: string;
  @Output() close  = new EventEmitter();

  skipPopUp() {
    this.close.emit('close');
  }

  continueToUrl() {
    window.open(this.spLongUrl) ;
    this.close.emit('close');
  }

}
