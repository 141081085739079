import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {DonationService} from '../donation.service';
import {catchError} from 'rxjs/operators';
import {EMPTY, throwError} from 'rxjs';

@Component({
  selector: 'sp-portal-contribution-message',
  templateUrl: './contribution-message.component.html',
  styleUrls: ['./contribution-message.component.scss']
})
export class ContributionMessageComponent implements OnInit {

  contributionMessageForm: UntypedFormGroup;
  disableSendButton = false;
  paymentData;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private donationService: DonationService
  ) { }

  ngOnInit() {
    this.createForm();
    this.paymentData = window.history.state;
  }

  createForm() {
    this.contributionMessageForm = this.formBuilder.group({
      message: [null, [Validators.required, Validators.minLength(1)]],
    });
  }

  submitMessage() {
    this.disableSendButton = true;
    if (this.contributionMessageForm.valid) {
      const message = this.contributionMessageForm.get('message').value;
      this.donationService.postContributionMessage(this.paymentData.contributionToken, message)
        .pipe(
          catchError((origErr) => {
            window.alert('Unfortunately there was a error, please retry');
            this.disableSendButton = false;
            return throwError(origErr);
          })
        ).subscribe( () => {
          this.router.navigate(['/dashboard']);
        });
    } else {
      this.disableSendButton = false;
      this.contributionMessageForm.markAllAsTouched();
    }
  }

  backToDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
