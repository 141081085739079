import {Component, OnDestroy, OnInit} from '@angular/core';
import {OnboardingService} from '../../../onboarding-module/onboarding.service';
import {delay, mergeMap, retryWhen} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {PaymentStatus} from '../../models/models';
import {ActivatedRoute, Router} from '@angular/router';
import {GoogleAnalyticsService} from '../../../core-module/services/google-analytics.service';
import {OnboardingType, OnboardingTypeService} from '../../../core-module/services/onboarding-type.service';

@Component({
  selector: 'sp-payment-processing',
  templateUrl: './processing-payment.component.html',
  styleUrls: ['./processing-payment.component.scss']
})
export class ProcessingPaymentComponent implements OnInit, OnDestroy {

  message = '';
  messageButtonLink: string;
  private pollSubscription: Subscription;
  loading = true;
  isBrightPensioenMember: boolean;
  onboardingType$: Observable<any>;

  constructor(
    private onboardingService: OnboardingService,
    private router: Router,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    private onboardingTypeService: OnboardingTypeService) {}

  ngOnInit() {
    this.isBrightPensioenMember = this.onboardingService.retrieveIsBrightPensioenMember();
    console.log('processing-payment init()');
    this.getRegCodeFromUrl();
    this.pollPaymentService();
    this.googleAnalyticsService.init({
      onlyProduction: true
    });
    this.onboardingType$ = this.onboardingTypeService.onboardingType$;
  }

  ngOnDestroy() {
    this.pollSubscription.unsubscribe();
  }

  private getRegCodeFromUrl() {
    const regCode = this.route.snapshot.queryParams;
    console.log('storing regCode from Params', regCode);
    this.onboardingService.setRegCode(regCode);
  }

  pollPaymentService() {
    let  retries = 4;
    const delayTime = 3000;
    console.log('pollPaymentService: retries:'+ retries);
    this.pollSubscription = this.onboardingService.pollPaymentStatus(this.isBrightPensioenMember)
      .pipe(
        retryWhen(
          (errors: Observable<any> ) => {return errors.pipe(
            delay(delayTime),
            mergeMap(error => {
              console.log('returned error');
              console.log(error);
              return retries -- > 0 ? of (error) : this.pollingTimeout();
            })
          );}
        ),
        delay(delayTime)
      ).subscribe(
        result => {
          console.log('response');
          this.handlePollingResponse(result);
        }
      );
  }

  handlePollingResponse(paymentResult: PaymentStatus) {
    console.log('paymentResult:', paymentResult);
    switch (paymentResult.paymentStatus) {
      case 'paid':
        this.paymentPaid(paymentResult.usePaymentAccountForIncasso);
        break;
      case 'failed':
        this.paymentFailed();
        break;
      case 'cancelled':
        this.paymentCancelled();
        break;
      case 'open':
        setTimeout(() => {
          this.pollPaymentService();
        }, 2000);
        break;
      default:
        this.paymentFailed();
        break;
    }
  }

  paymentPaid(usePaymentAccountForIncasso) {
    console.log('paymentPaid');
    if (usePaymentAccountForIncasso) {
      if (this.onboardingTypeService.returnOnboardType$getValue() === OnboardingType.PROSPECTS) {
        const spLong = this.onboardingService.retrieveChosenCoverageType();
        if (spLong === true) {
          this.router.navigate(['sp-prospects/completed']);
        } else {
          this.router.navigate(['sp-prospects/break-a-leg']);
        }
      } else {
        this.router.navigate(['break-a-leg']);
      }
    }
  }

  pollingTimeout() {
    this.message = 'De betaling is mislukt. Probeer het nog een keer';
    this.onboardingService.setCurrentStep('/confirmation');
    this.loading = false;
    this.messageButtonLink = '../confirmation';
    return null;
  }

  paymentFailed() {
    this.message = 'De betaling is mislukt. Probeer het nog een keer';
    this.onboardingService.setCurrentStep('/confirmation');
    this.loading = false;
    this.messageButtonLink = '../confirmation';
  }

  paymentCancelled() {
    this.message = 'De betaling is mislukt. Probeer het nog een keer';
    this.onboardingService.setCurrentStep('/confirmation');
    this.loading = false;
    this.messageButtonLink = '../confirmation';
  }
}
