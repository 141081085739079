
<div class="loading-container" *ngIf="!message">
  <p>Awaiting payment confirmation</p>
  <sp-loading-spinner *ngIf="loading"></sp-loading-spinner>
</div>

<div class="message-container" *ngIf="message && !showWelWishes">
  <p *ngIf="!showWelWishes">{{message}}</p>
  <button sp-button [routerLink]="messageButtonLink">Volgende</button>
</div>

<div *ngIf="message && showWelWishes"><br/><br/><br/>
  <h1 *ngIf="showWelWishes">Dank voor je donatie!</h1>
  <button sp-button [routerLink]="messageButtonLink">Terug naar Portal</button>
</div>
