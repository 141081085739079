import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ThemeService} from '../../../sp-shared-lib/src/lib/shared/services/theme.service';
import {GoogleAnalyticsService} from './core-module/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private ga: GoogleAnalyticsService,
    private themeService: ThemeService
  ) {
    themeService.init();
  }

  ngOnInit() {
    this.subscribeToNavigationEventsForGA();
    this.themeService._runningProject.next('portal');
  }

  subscribeToNavigationEventsForGA() {
    this.router.events.subscribe(
      ev => {
        if (ev instanceof NavigationEnd) {
          this.ga.sendPageView(ev.urlAfterRedirects);
        }
      }
    );
  }

  ngOnDestroy() {
    this.themeService._runningProject.next(null);
  }
}
